import React, { useState } from "react";
import "./partnersSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import Awsphoto from "../../../assets/images/Aws_P4_Partner.png";
import SapLogo from "../../../assets/images/Home_P4_Sap.png";
import AzureLogo from "../../../assets/svg/Azure_Logo.svg";
import TrendLogo from "../../../assets/images/Trend_P1_Logo.png";
import OdooLogo from "../../../assets/images/Odoo_P1_Logo.png";
import MainLogo from "../../../assets/svg/Logo-White.svg";
import { MainUrl } from "../../../config";
import LazyMedia from "../../../lazyLoad/lazyLoad";

function PartnersSubmenu() {
  // State to keep track of the current image
  const [currentImage, setCurrentImage] = useState(MainLogo);
  const [currentClassName, setCurrentClassName] = useState("SubMenu_Pho_photo");

  // Update both image source and class name
  const handleMouseEnter = (newImage, newClassName) => {
    setCurrentImage(newImage);
    setCurrentClassName(newClassName);
  };

  const handleMouseLeave = () => {
    setCurrentImage(MainLogo); // Default image
    setCurrentClassName("SubMenu_Pho_photo"); // Default class name
  };
  return (
    <div className="submenu_partnersMain">
      <div className="submenu_contain_partners">
        <div className="submenu_partners_contain">
          <a
            href={`${MainUrl}aws-partnership`}
            className="submenu_partners_link"
            onMouseEnter={() => handleMouseEnter(Awsphoto, "SubMenu_Aws_photo")}
            onMouseLeave={handleMouseLeave}
          >
            AWS
            <ArrowMenu />
          </a>
          <a
            href={`${MainUrl}sap-partnership`}
            className="submenu_partners_link"
            onMouseEnter={() => handleMouseEnter(SapLogo, "SubMenu_Sap_photo")}
            onMouseLeave={handleMouseLeave}
          >
            SAP
            <ArrowMenu />
          </a>
          <a
            href={`${MainUrl}azure-partnership`}
            className="submenu_partners_link"
            onMouseEnter={() =>
              handleMouseEnter(AzureLogo, "SubMenu_Azure_photo")
            }
            onMouseLeave={handleMouseLeave}
          >
            Microsoft Azure
            <ArrowMenu />
          </a>
          <a
            href={`${MainUrl}Trend-Micro`}
            className="submenu_partners_link"
            onMouseEnter={() =>
              handleMouseEnter(TrendLogo, "SubMenu_Trend_photo")
            }
            onMouseLeave={handleMouseLeave}
          >
            Trend Micro
            <ArrowMenu />
          </a>
          <a
            href={`${MainUrl}Odoo`}
            className="submenu_partners_link"
            onMouseEnter={() =>
              handleMouseEnter(OdooLogo, "SubMenu_Odoo_photo")
            }
            onMouseLeave={handleMouseLeave}
          >
            odoo
            <ArrowMenu />
          </a>
          {/* <a href={`${MainUrl}#`} className="submenu_partners_link">
            THE ANALYST
            <ArrowMenu />
          </a> */}
        </div>
        <div className="summenu_partners_secondPart">
          <div className="submenu_info_partners">
            <p className="submenu_partners_first">Partners</p>
            <p className="submenu_partners_second">
              We team up with the best partners to boost your business
            </p>
            <p className="submenu_partners_third">
              We also partner with top technology companies to provide the
              solutions your organization requires.
            </p>
          </div>
          <div>
            <LazyMedia
              type="image"
              src={currentImage}
              alt=""
              className={currentClassName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnersSubmenu;
