import React, { useState, useEffect, useRef } from "react";
import "intersection-observer";

const LazyBackground = ({ src, children, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const mediaRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(mediaRef.current);
        }
      });
    });

    if (mediaRef.current) {
      observer.observe(mediaRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const backgroundStyle = isLoaded ? { backgroundImage: `url(${src})` } : {};

  return (
    <div ref={mediaRef} style={backgroundStyle} {...props}>
      {children}
    </div>
  );
};

export default LazyBackground;
