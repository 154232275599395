import React, { useState, useEffect } from "react";
import "./contact.css";
import Header from "../../components/header/header-white";
import Footer from "../../components/footer/footer-contactus";
import ContactContent from "./contact";
import HeaderMobile from "../../components/headerMobile/headerMobile";
import FooterMobile from "../../components/footerMobile/footerMobile";

// Welcome to Contant-us Page Happy Coding

function ContactUs() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 475);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isMobile ? <HeaderMobile /> : <Header />}
      <ContactContent />
      {isMobile ? <FooterMobile /> : <Footer />}
    </>
  );
}

export default ContactUs;
