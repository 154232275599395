import React from "react";
import "./partnersSubmenuMobile.css";
import { MainUrl } from "../../../config";

function PartnersSubmenuMobile() {
  return (
    <div className="submenuMobile_Partners_main">
      <a
        href={`${MainUrl}aws-partnership`}
        className="submenuMobile_Partners_link"
      >
        AWS
      </a>
      <a
        href={`${MainUrl}sap-partnership`}
        className="submenuMobile_Partners_link"
      >
        SAP
      </a>
      <a
        href={`${MainUrl}azure-partnership`}
        className="submenuMobile_Partners_link"
      >
        Microsoft Azure
      </a>
      <a href={`${MainUrl}Trend-Micro`} className="submenuMobile_Partners_link">
        Trend Micro
      </a>
      <a href={`${MainUrl}Odoo`} className="submenuMobile_Partners_link">
        odoo
      </a>
      {/* <a href={`${MainUrl}#`} className="submenuMobile_Partners_link">
        THE ANALYST
      </a> */}
    </div>
  );
}

export default PartnersSubmenuMobile;
