import React from "react";
import "./solutionsSubmenuMobile.css";
import { MainUrl } from "../../../config";

function SolutionsSubmenuMobile() {
  return (
    <div className="submenuMobile_Solutions_main">
      <a href={`${MainUrl}RealEstate`} className="submenuMobile_Solutions_link">
        Real Estate
      </a>
      <a href={`${MainUrl}Tender`} className="submenuMobile_Solutions_link">
        Tender
      </a>
    </div>
  );
}

export default SolutionsSubmenuMobile;
