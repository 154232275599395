import React, { useState, useEffect, useRef } from "react";
import "intersection-observer";

const LazyMedia = ({ src, alt, type, ...props }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const mediaRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.unobserve(mediaRef.current);
        }
      });
    });

    if (mediaRef.current) {
      observer.observe(mediaRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return type === "image" ? (
    <img ref={mediaRef} src={isLoaded ? src : undefined} alt={alt} {...props} />
  ) : (
    <video ref={mediaRef} {...props} autoPlay loop muted>
      {isLoaded && <source src={src} type="video/mp4" />}
      Your browser does not support the video tag.
    </video>
  );
};

export default LazyMedia;
