import React, { useState } from "react";
import { ReactComponent as Choice } from "../../../assets/svg/choice-con.svg";
import { ReactComponent as NotChoice } from "../../../assets/svg/not-choice-con.svg";
import "./choice.css";

const ChoiceButton = ({ choice, isSelected, onClick }) => {
  const SVGComponent = isSelected ? choice.SelectedSVG : choice.UnselectedSVG;

  return (
    <button
      className={`choice-button ${isSelected ? "selected" : ""}`}
      onClick={onClick}
      type="button"
      name="user_choices"
    >
      <SVGComponent className="choice-svg" />
      {choice.label}
    </button>
  );
};

const Choices = ({ onChoicesChange }) => {
  const choices = [
    {
      id: 1,
      label: "SAP S/4HANA",
      SelectedSVG: Choice,
      UnselectedSVG: NotChoice,
    },
    {
      id: 2,
      label: "SAP SuccessFactore HCM",
      SelectedSVG: Choice,
      UnselectedSVG: NotChoice,
    },
    {
      id: 3,
      label: "SAP ARIBA",
      SelectedSVG: Choice,
      UnselectedSVG: NotChoice,
    },
    {
      id: 4,
      label: "Rise with SAP",
      SelectedSVG: Choice,
      UnselectedSVG: NotChoice,
    },
    { id: 5, label: "Others", SelectedSVG: Choice, UnselectedSVG: NotChoice },
  ];
  const [selectedChoices, setSelectedChoices] = useState([]);

  const toggleChoice = (choiceId) => {
    const choice = choices.find((c) => c.id === choiceId);

    const isCurrentlySelected = selectedChoices.some((c) => c.id === choiceId);

    const newSelectedChoices = isCurrentlySelected
      ? selectedChoices.filter((c) => c.id !== choiceId)
      : [...selectedChoices, choice];

    setSelectedChoices(newSelectedChoices);

    onChoicesChange(newSelectedChoices);
  };

  return (
    <div className="choices-container">
      {choices.map((choice) => (
        <React.Fragment key={choice.id}>
          <ChoiceButton
            choice={choice}
            isSelected={selectedChoices.some((c) => c.id === choice.id)}
            onClick={() => toggleChoice(choice.id)}
          />
          <input
            type="hidden"
            name="user_choices"
            value={
              selectedChoices.some((c) => c.id === choice.id)
                ? choice.label
                : ""
            }
            style={{ display: "none" }}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

export default Choices;
