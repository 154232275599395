// AccordionItem.js
import React, { useState } from "react";
import HomeP4GoOut from "../../../assets/images/Home_P4_GoOut.png";
import "./HomePartFourAcc.css";
import LazyMedia from "../../../lazyLoad/lazyLoad";

const HomeP4AccordionItem = ({ title, title2, content, img, url }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="Home_P4_accordion_item">
      <div className="Home_P4_accordion_item_container">
        <div
          className={`Home_P4_accordion_header ${isOpen ? "NoBorder" : ""}`}
          onMouseEnter={() => setIsOpen(true)}
          onMouseLeave={() => setIsOpen(false)}
        >
          <h3 className="Home_P4_accordion_item_container_H3">{title}</h3>
          <a href={url} target="blank" className="HomeP4_S2_E">
            <p className="HomeP4_S2_Elmement_P">{title2}</p>
            <LazyMedia type="image" src={HomeP4GoOut} alt="HomeP4GoOut" />
          </a>
        </div>
        <div className={`Home_P4_accordion_content ${isOpen ? "active" : ""}`}>
          <div>
            <div className="Home_P4_accordion_content_Main">
              <div className="Home_P4_accordion_content_Main_First">
                <p className="Home_P4_accordion_content_P">{content}</p>
              </div>
              <div className="Home_P4_accordion_content_Img">{img}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeP4AccordionItem;
