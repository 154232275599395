import React from "react";
import "./industriesSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import { ReactComponent as OilGas } from "../../../assets/svg/oil&gas.svg";
import { ReactComponent as Manufacturing } from "../../../assets/svg/Manufacturing.svg";
import { ReactComponent as Retail } from "../../../assets/svg/Retail.svg";
import { ReactComponent as Healthcare } from "../../../assets/svg/Healthcare.svg";
import { ReactComponent as Automotive } from "../../../assets/svg/Automotive.svg";
import { ReactComponent as Government } from "../../../assets/svg/Government.svg";
import { ReactComponent as Telecommunication } from "../../../assets/svg/Telecommunication.svg";
import { ReactComponent as ConstructionRealEstate } from "../../../assets/svg/ConstructionRealEstate.svg";
import { ReactComponent as InformationCommunication } from "../../../assets/svg/InformationCommunication.svg";
import { ReactComponent as FMCG } from "../../../assets/svg/FMCG.svg";
import { MainUrl } from "../../../config";

function IndustriesSubmenu() {
  return (
    <div className="submenu_industriesMain">
      <div className="submenu_contain_industries">
        <div className="submenu_info_industries">
          <p className="submenu_industries_name">Industries</p>
          <p className="submenu_discover_industries">
            Explore expert tech solutions
          </p>
          <p className="submenu_help_industries">
            We adapt with technology advancements to provide top-tier solutions
            in diverse sectors.
          </p>
          {/* <a href={`${MainUrl}#`}>
            <button className="submenu_industries_button">
              Find your industry
            </button>
          </a> */}
        </div>
        {/* buttons */}
        <div className="submenu_industries_contain">
          {/* button oil & gas */}
          <a href={`${MainUrl}Oils-gas`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <OilGas className="industries_svg" />
              Oil & Gas
            </div>
            <ArrowMenu />
          </a>
          {/* button Manufacturing */}
          <a
            href={`${MainUrl}Manufacturing`}
            className="submenu_industries_link"
          >
            <div className="submenu_industries_linkWithLogo">
              <Manufacturing className="industries_svg" />
              Manufacturing
            </div>
            <ArrowMenu />
          </a>
          <a href={`${MainUrl}Government`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Government className="industries_svg" />
              Government
            </div>
            <ArrowMenu />
          </a>
          {/* button Retail */}

          {/* <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Retail className="industries_svg" />
              Retail
            </div>
            <ArrowMenu />
          </a> */}
          {/* button Healthcare */}
          {/* <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Healthcare className="industries_svg" />
              Healthcare
            </div>
            <ArrowMenu />
          </a> */}
          {/* button Automotive */}
          {/* <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Automotive className="industries_svg" />
              Automotive
            </div>
            <ArrowMenu />
          </a> */}
        </div>
        {/* Start Right part */}
        <div className="submenu_industries_contain hidden">
          {/* button Government */}
          <a href={`${MainUrl}Government`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Government className="industries_svg" />
              Government
            </div>
            <ArrowMenu />
          </a>
          {/* button Telecommunication */}
          <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <Telecommunication className="industries_svg" />
              Telecommunication
            </div>

            <ArrowMenu />
          </a>
          {/* button Construction & Real Estate */}
          <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <ConstructionRealEstate className="industries_svg" />
              Construction & Real Estate
            </div>
            <ArrowMenu />
          </a>
          {/* button Information & communication */}
          <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <InformationCommunication className="industries_svg" />
              Information & communication
            </div>
            <ArrowMenu />
          </a>
          {/* button FMCG */}
          <a href={`${MainUrl}#`} className="submenu_industries_link">
            <div className="submenu_industries_linkWithLogo">
              <FMCG className="industries_svg" />
              FMCG
            </div>
            <ArrowMenu />
          </a>
        </div>
      </div>
    </div>
  );
}

export default IndustriesSubmenu;
