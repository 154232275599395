import React from "react";
import "./footer.css";
import { ReactComponent as Inbox } from "../../assets/svg/inbox.svg";
import { ReactComponent as DotMenu } from "../../assets/svg/dot-menu.svg";
import { ReactComponent as BackgroundSvg } from "../../assets/svg/bg-footer.svg";
import { ReactComponent as GoOut } from "../../assets/svg/go-out.svg";
import { ReactComponent as Facebook } from "../../assets/svg/facebook.svg";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { Link } from "react-router-dom";
import { MainUrl } from "../../config";

function Footer() {
  return (
    <>
      <div className="footer">
        {/* footer part 1 */}
        {/* <div className="footer_part1">
            <div className="footer_part1_first">
              <Inbox className="part1_first_i" />
              <p className="part1_first_p">
                get the latest Phoenix news in your inbox
              </p>
            </div>
            <div className="footer_part1_second">
              <input
                type="text"
                className="part1_second_input"
                placeholder="youremail@example.com"
              />
              <a href={`${MainUrl}Contact-us`}>
                <button className="part1_second_button">Subscribe</button>
              </a>
            </div>
          </div> */}
        {/* end footer part 1 */}
        <div className="footer_part_s">
          <div className="part_s1">We look forward to hearing from you</div>
          <div className="part_s2">
            <a href={`${MainUrl}Contact-us`}>
              <button className="part_sButton">
                <MailOutlineIcon className="part_sIcon" />
                Get in Touch
              </button>
            </a>
          </div>
        </div>
        {/* start footer part 2  */}
        <div className="footer_part2">
          <BackgroundSvg className="background-svg" />
          <div className="part1">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Company</span>
            </div>
            <a href={`${MainUrl}about-us`}>About Us</a>
            <a href={`${MainUrl}Careers`}>Careers</a>
          </div>
          {/*  */}
          {/*  */}
          <div className="part2">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Help</span>
            </div>
            {/* <a href={`${MainUrl}`}>Terms & Conditions</a> */}
            <a href={`${MainUrl}Contact-us`}>Contact Us</a>
            <a href={`${MainUrl}Clients`}>Clients</a>
          </div>
          {/*  */}
          {/*  */}
          <div className="part3">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Branches location</span>
            </div>
            <h4 className="footer_part3_h4_Web">Phoenix Consulting</h4>
            <p className="footer_part3_P_Web">
              Egypt: Al Jazeera 1 Tower, beside Sadat Academy Maadi - Cairo
            </p>
            <h4 className="footer_part3_h4_Web">Phoenix IT Solutions</h4>
            <p className="footer_part3_P_Web">
              Dubai: Office NO.04- 46, King Saeed Suhail Saeed bin Deliwi Al
              Ketbi - Ras Al Khor Second Industrial, Dubai
            </p>
            <div>
              {" "}
              <h4 className="footer_part3_h4_Web">Phoenix Tech Solutions</h4>
              <p className="footer_part3_P_Web">
                USA: Office 2248 BROADWAY NEWYORK, NY , USA
              </p>
            </div>
          </div>

          {/*  */}
          {/*  */}
          <div className="part4">
            <div className="part2_first_link">
              <DotMenu className="footer_icon" />
              <span className="Main-link">Contacts</span>
            </div>
            <p className="Footer_Part2_Address_Egypt">
              Egypt Office: +201016165700
            </p>
            <p className="Footer_Part2_Address_Dubai">
              Dubai Office: +971 527673263
            </p>
            <p className="Footer_Part2_Address_Dubai">
              USA Office: (+1)201-449-4229
            </p>
            <a href="mailto:info@phoenix-mea.com">info@phoenix-mea.com</a>
          </div>
          {/*  */}
          {/*  */}
        </div>
        {/* end footer part 2 */}
        {/* start footer part 3 */}
        <div className="footer_part3">
          <div className="footer_part3_first">
            @All Rights reserved by phoenix Consulting
          </div>
          <div className="footer_part3_second">
            <a
              href="https://www.linkedin.com/company/phoenix-consulting-egy/"
              target="blank"
            >
              <div className="footer_social">
                <LinkedInIcon className="social_icon" />
                Linked In
                <GoOut className="social_icon" />
              </div>
            </a>
            <a
              href="https://www.facebook.com/phoenixconsultingmea"
              target="blank"
            >
              <div className="footer_social">
                <Facebook className="social_icon" />
                Facebook
                <GoOut className="social_icon" />
              </div>
            </a>
            <a
              href="https://www.instagram.com/phoenixconsultingmea/?igshid=MzRlODBiNWFlZA%3D%3D"
              target="blank"
            >
              <div className="footer_social">
                <InstagramIcon className="social_icon" />
                Instagram
                <GoOut className="social_icon" />
              </div>
            </a>
          </div>
        </div>
        {/* end footer part 3 */}
      </div>
    </>
  );
}

export default Footer;
