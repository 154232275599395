import React, { useState } from "react";
import "./DevOpsButtonOne.css";
import DevOpsAccelerated from "../../../assets/svg/DevOps_Accelerated.svg";
import DevOpsCi from "../../../assets/svg/DevOps_CI.svg";
import DevOpsApplication from "../../../assets/svg/DevOps_Application.svg";
import DevOpsSolution from "../../../assets/svg/DevOps_Solution.svg";
import DevOpsMigration from "../../../assets/svg/DevOps_Migration.svg";
import DevOpsProcess from "../../../assets/svg/DevOps_Process.svg";
import DevOpsManagement from "../../../assets/svg/DevOps_Management.svg";
import LazyMedia from "../../../lazyLoad/lazyLoad";

function DevOpsButtons() {
  const [activeButton, setActiveButton] = useState(1);

  const contents = [
    // button 1
    {
      name: "Accelerated System and App Deployment",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsAccelerated}
          alt="DevOpsAccelerated"
          className="mySvgClass"
        />
      ),
      head: "Accelerated System and App Deployment",
      paragraph:
        "Our experienced professionals manage the administration of your public cloud infrastructure, emphasizing reliability, security, and scalability for AWS or Azure. Additionally, DevOps practices streamline software development by eliminating time-consuming tasks, while automated testing provides developers with instant feedback for quicker code adjustments",
    },
    // button 2
    {
      name: "CI/CD Solutions",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsCi}
          alt="DevOpsCi"
          className="mySvgClass"
        />
      ),
      head: "CI/CD Solutions",
      paragraph:
        "In the digital age, rapid, high-quality software delivery is paramount. Our DevOps experts specialize in Continuous Integration/Continuous Delivery (CI/CD) and containerization, accelerating development, testing, and deployment. Automation of application packaging into containers ensures consistent, reliable software delivery.",
    },
    // button 3
    {
      name: "Application Infrastructure",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsApplication}
          alt="DevOpsApplication"
          className="mySvgClass"
        />
      ),
      head: "Application Infrastructure",
      paragraph:
        "The infrastructure that supports your applications must be optimized for performance and scalability. Our DevOps engineers understand the nuances of application infrastructure. They work closely with you to design, configure, and maintain the systems that underpin your software. This ensures that your applications run efficiently, even during periods of high demand.",
    },
    // button 4
    {
      name: "Solution Design",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsSolution}
          alt="DevOpsSolution"
          className="mySvgClass"
        />
      ),
      head: "Solution Design",
      paragraph:
        "Our team specializes in designing cloud solutions tailored to your unique business requirements. We take into account your goals, the specific technologies you need, and the best practices in the industry to create cloud solutions that align with your business objectives.",
    },
    // button 5
    {
      name: "Migration Services",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsMigration}
          alt="DevOpsMigration"
          className="mySvgClass"
        />
      ),
      head: "Migration Services",
      paragraph:
        "Transitioning to the cloud is a strategic move, but it doesn't have to be disruptive. Our cloud migration services are designed to make your transition as seamless as possible. We minimize disruptions, ensure data integrity, and optimize your applications for the cloud environment, ensuring a smooth and efficient migration process.",
    },
    // button 6
    {
      name: "Process Automation",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsProcess}
          alt="DevOpsProcess"
          className="mySvgClass"
        />
      ),
      head: "Process Automation",
      paragraph:
        "Manual processes can slow you down and introduce the risk of errors. Our cloud process automation services help you streamline critical processes, reduce manual interventions, and enhance operational efficiency. By automating routine tasks, you can free up your resources to focus on more strategic activities.",
    },
    // button 7
    {
      name: "Management and Orchestration",
      icon: (
        <LazyMedia
          type="image"
          src={DevOpsManagement}
          alt="DevOpsManagement"
          className="mySvgClass"
        />
      ),
      head: "Management and Orchestration",
      paragraph:
        "Effective management and orchestration of cloud resources are vital for maintaining control and coordination. Our solutions give you a clear overview of your cloud environment, allowing you to allocate resources more efficiently and coordinate activities seamlessly. This empowers your teams to collaborate better, make informed decisions, and ensure the optimal utilization of your cloud assets.",
    },
  ];

  return (
    <div className="devOpsButtonsContainer">
      <Buttons
        contents={contents}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
      />
      <ContentDisplay content={contents[activeButton - 1]} />
    </div>
  );
}

function Buttons({ contents, activeButton, setActiveButton }) {
  return (
    <div className="DevOps_buttonsOne_container">
      {contents.map((content, index) => (
        <React.Fragment key={index}>
          <div
            className={`DevOps_buttonsOne_button DevOps_buttonOne_individual ${
              activeButton === index + 1 ? "DevOps_buttonsOne_active" : ""
            }`}
            onClick={() => setActiveButton(index + 1)}
          >
            {content.name}
          </div>
          {activeButton === index + 1 && (
            <div className="DevOps_buttonsOne_content_Mobile">
              {content.icon}
              <h2>{content.head}</h2>
              <p>{content.paragraph}</p>
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
}

function ContentDisplay({ content }) {
  return (
    <div className="DevOps_buttonsOne_content">
      <div className="content_container">
        {content.icon}
        <h2>{content.head}</h2>
        <p>{content.paragraph}</p>
      </div>
    </div>
  );
}

export default DevOpsButtons;
