import React from "react";
import "./caseStudy_Hsa.css";
import { MainUrl } from "../../config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import CaseStudies_P3_S1 from "../../assets/images/CaseStudies_P3_S1.jpg";
import SapLogo from "../../assets/svg/Sap_SapBlue.svg";
import MainLogo from "../../assets/svg/Logo.svg";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2Two from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import AmerGroup from "../../assets/images/amer.png";
import Damietta from "../../assets/images/damietta.png";
import ADH from "../../assets/images/ADH-logo.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function CaseStudyHsaContent() {
  return (
    <>
      <div className="caseStudyHsaContainer">
        {/* First Part */}
        <div className="CaseStudyHsaFirstPart">
          <div className="HSA_P1_S1">
            <a href={`${MainUrl}case-Studies`}>
              <h3 className="HSA_P1_S1_H3">Case Studies</h3>
            </a>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <a href={`${MainUrl}HSA`}>
              <h3 className="HSA_P1_S1_H3">HSA GROUP</h3>
            </a>
          </div>
          <div className="HSA_P1_S2_Container">
            <div></div>
            <div className="HSA_P1_S2">
              <h1 className="HSA_P1_H1">HSA GROUP</h1>

              <h3 className="HSA_P1_H3">
                HSA GROUP, BUILDING BUSINESS RESILIENCE IN CHALLENGING TIMES ON
                ADVANCED ERP IN THE CLOUD
              </h3>
              {/* <p className="HSA_P1_P">
                See how business conglomerate Hayel Saeed Anam & Co. (HSA Group)
                is helping promote economic growth as well as food and job
                security in Yemen by digitalizing its operations and leveraging
                new technologies in the cloud. Download the Document
              </p> */}
            </div>
          </div>
        </div>
        {/* Second Part */}
        <div className="HSA_P2_S1">
          <div>
            <LazyMedia
              type="image"
              src={casestudiesXP2One}
              alt="casestudiesXP2One"
              className="casestudiesXP2OneWeb"
            />
          </div>
          <div className="HSA_P2_S1_Container">
            <h1 className="HSA_P2_S1_H1">
              See how business conglomerate <strong> Hayel Saeed Anam </strong>&
              Co. (HSA Group) is helping promote economic growth as well as food
              and job security in Yemen by digitalizing its operations and
              leveraging new technologies in the cloud. Download the Document
            </h1>
            <LazyMedia
              type="image"
              src={casestudiesXP2One}
              alt="casestudiesXP2One"
              className="casestudiesXP2OneMobile"
            />
          </div>
        </div>
        {/* Third Part */}

        <div className="CaseStudies_ThirdPart">
          {/* <div className="CaseStudies_SecondPart">
            <div className="CaseStudies_P2_S1">
              <h1 className="CaseStudies_P2_S1_H1">
                HSA Group: Building Business Resilience in Challenging Times on
                Advanced ERP in the Cloud
              </h1>
            </div>
            <div className="CaseStudies_P2_S2">
              <p className="CaseStudies_P2_S2_P">
                See how business conglomerate Hayel Saeed Anam & Co. (HSA Group)
                is helping promote economic growth as well as food and job
                security in Yemen by digitalizing its operations and leveraging
                new technologies in the cloud. Download the Document
              </p>
            </div>
          </div> */}
          <div className="CaseStudies_P3_Content1">
            {/* first part */}
            <div className="Case_P3_C1_Container">
              <div className="Case_P3_C1_Text">
                <div>
                  <h3 className="CaseStudies_P3_C1_H3">
                    HSA Group: Building Business Resilience in Challenging Times
                    on Advanced ERP in the Cloud
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <LazyMedia
                  type="image"
                  src={CaseStudies_P3_S1}
                  alt=""
                  className="CaseStudies_P3_S1Mobile"
                />
                <div>
                  <p className="CaseStudies_P3_C1_P">
                    Hayel Saeed Anam & Co. (HSA Group) is one of the largest
                    family-owned multinational business conglomerates in the
                    Middle East. Established as a family business in Yemen in
                    1938, the company now operates a diversified portfolio of
                    market-leading businesses, supplying essential foods and
                    services to millions of families in Yemen and across the
                    region.
                  </p>
                  <p className="CaseStudies_P3_C1_P">
                    In the face of tremendous challenges in Yemen and as the
                    country’s largest private employer, HSA Group sought to
                    build business resilience and promote economic growth by
                    helping safeguard the continuous supply of essential goods.
                    The goal was to digitalize its operations using
                    industry-leading cloud solutions, helping support its
                    planned expansion and provide the{" "}
                    <strong> flexibility and scale </strong> to grow its
                    operations while enabling visibility and data insights. HSA
                    Group: Building Business Resilience in Challenging Times on
                  </p>
                </div>
                <div className="CaseStudies_P3_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              <div className="CaseStudies_P3_C1_Img">
                <LazyMedia
                  type="image"
                  src={CaseStudies_P3_S1}
                  alt=""
                  className="CaseStudies_P3_S1Web"
                />
              </div>
            </div>
            {/* second part */}
            <div className="Case_P3_C1_Container">
              <div className="Case_P3_C1_Text">
                <div>
                  <h3 className="CaseStudies_P3_C1_H3">
                    Continuing to Meet the Needs of Millions of Yemenis for
                    Essential Goods with SAP S/4HANA® Cloud, Private Edition
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P3_C1_Text_Ul">
                  {/* point list one */}
                  <div className="Case_P3_C1_Text_Ul_Main">
                    <h4 className="Case_P3_C1_Text_Ul_Main_H4">
                      Before: Challenges and Opportunities
                    </h4>
                    <ul className="Case_P3_C1_Text_Ul_Container">
                      <li>
                        Digitalize operations to build business resilience,
                        leveraging new technologies to promote economic growth
                        as well as food and job security in Yemen
                      </li>
                      <li>
                        Automate business processes to increase efficiencies and
                        lower costs
                      </li>
                      <li>
                        Gain data visibility across the organization and improve
                        reporting
                      </li>
                    </ul>
                  </div>
                  {/* point list two */}
                  <div className="Case_P3_C1_Text_Ul_Main">
                    <h4>Why Phoenix and SAP Consulting</h4>
                    <ul className="Case_P3_C1_Text_Ul_Container">
                      <li>
                        Next-generation suite of integrated digital business
                        processes offered by SAP S/4HANA® Cloud, private
                        edition, facilitating real-time information and business
                        insights to optimize innovation potential
                      </li>
                      <li>
                        Ease of integrating its midsize companies, creating a
                        single and unified solution landscape in the cloud
                      </li>
                      <li>
                        Flexible and future-ready architecture able to scale as
                        the organization grows
                      </li>
                      <li>
                        Improved business flexibility and scalability by
                        adopting the RISE with SAP offering, with SAP Enterprise
                        Support services and the SAP Enterprise Cloud Services
                        team critical to the cloud migration
                      </li>
                      <li>
                        Phoenix Consulting’s proven experience in implementing
                        advanced ERP and other solutions from SAP
                      </li>
                    </ul>
                  </div>
                  {/* point list three */}
                  <div className="Case_P3_C1_Text_Ul_Main">
                    <h4>After: Value-Driven Results</h4>
                    <ul className="Case_P3_C1_Text_Ul_Container">
                      <li>
                        Digitally transformed operations for 1,500 users in
                        three countries across 24 business entities
                      </li>
                      <li>
                        Created a 360-degree view of its business operations
                        with accurate and real-time data and reporting
                      </li>
                      <li>
                        Enhanced business efficiency, visibility, and
                        productivity while automating reporting and compliance
                      </li>
                      <li>Reduced maintenance and total cost of ownership</li>
                      <li>
                        Enabled the reinvestment of its server upgrade and
                        maintenance budget to the business
                      </li>
                      <li>
                        Readied the business for the future with scalable and
                        flexible technology that allows agile operations based
                        on data-driven decisions
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="CaseStudies_P3_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              <div className="CaseStudies_P3_C1_Right">
                <div className="CaseStudies_P3_C1_Right_Logo">
                  <LazyMedia type="image" src={MainLogo} alt="MainLogo" />
                </div>
                <div className="CaseStudies_P3_C1_Right_P1">
                  <p className="CaseStudies_P3_C1_Right_P">
                    “By adopting RISE with SAP and SAP S/4HANA Cloud, private
                    edition, we are <strong> securing future resilience</strong>
                    . We can now focus on expanding our core businesses and
                    developing new products and services to meet our customers’
                    essential needs.
                  </p>
                </div>
                <div className="CaseStudies_P3_C1_Right_P2">
                  {/* part one */}
                  <div className="Case_P3_C1_Right_P2_container">
                    <h4 className="Case_P3_C1_Right_P2_H4">100%</h4>
                    <p className="Case_P3_C1_Right_P2_P">
                      Reduction in hardware support, maintenance, and upgrade
                      costs
                    </p>
                  </div>
                  {/* part two */}
                  <div className="Case_P3_C1_Right_P2_container">
                    <h4 className="Case_P3_C1_Right_P2_H4">10%</h4>
                    <p className="Case_P3_C1_Right_P2_P">
                      Reduction in total cost of IT ownership expected
                    </p>
                  </div>
                </div>
                <div className="CaseStudies_P3_C1_Right_P3">
                  {/* part one */}
                  <div className="Case_P3_C1_Right_P3_container">
                    <h4 className="Case_P3_C1_Right_P3_H4">~20%</h4>
                    <p className="Case_P3_C1_Right_P3_P">
                      Improvement in business processes using real-time data and
                      connected systems
                    </p>
                  </div>
                  {/* part two */}
                </div>
              </div>
            </div>
            {/* third part */}
            <div className="Case_P3_C1_Container">
              <div className="Case_P3_C1_Text">
                <div>
                  <h3 className="CaseStudies_P3_C1_H3">
                    Shoring Up Essential Businesses in the Cloud with Help from
                    SAP® Enterprise Cloud Services
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P3_C1_Text">
                  <p className="CaseStudies_P3_C1_P">
                    As the largest private employer in Yemen, Hayel Saeed Anam &
                    Co. (HSA Group) recognizes the role it plays in supporting
                    the growth of the Yemeni economy. To support resilience in
                    its businesses that supply essential foods and services, HSA
                    Group embarked on a digital transformation to modernize its
                    operations and build a single source of enterprise data.
                    Partnering with Phoenix Consulting to migrate from its
                    on-premise system to SAP S/4HANA® Cloud, private edition,
                    the company wanted to improve its IT systems and enhance
                    reliability to deliver a value-based business outcome. Using
                    the RISE with SAP offering helped it achieve its vision of
                    becoming a future-ready organization. Strong collaboration
                    with the SAP® Enterprise Cloud Services team, the customer
                    success partner from the Cloud Success Services group, and
                    SAP Enterprise Support services helped smooth the migration.
                  </p>
                  <p className="CaseStudies_P3_C1_P">
                    Now operating with advanced functionality and capabilities,
                    HSA Group plans to continue its transformation in the cloud
                    by using SAP Digital Boardroom and the SAP Analytics Cloud
                    solution to provide real-time contextual information and
                    actionable insights. It also is looking to consolidate group
                    reporting with the private edition of SAP S/4HANA Cloud for
                    group reporting. Elsewhere, improving source-to-contract
                    processes and supplier collaboration is a major priority,
                    and the company is currently evaluating SAP Ariba ®
                    Strategic Sourcing Suite. HSA Group also adopted SAP
                    SuccessFactors ® Human Experience Management Suite.
                  </p>
                </div>
                <div className="CaseStudies_P3_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              <div className="CaseStudies_P3_C1_Right">
                <div className="CaseStudies_P3_C1_Right_P1">
                  <p className="CaseStudies_P3_C1_Right_P">
                    “HSA Group wants to achieve true digital transformation to
                    make our IT systems future- ready and{" "}
                    <strong>
                      deliver a value-based outcome for our business
                    </strong>
                    . Thanks to the proactive support and commitment from SAP
                    Enterprise Cloud Services and Cloud Success Services, we
                    successfully migrated our highly complex setup in just seven
                    months.”
                  </p>
                </div>
                <div className="CaseStudies_P3_C1_Right_P2">
                  {/* part one */}
                  <div className="Case_P3_C1_Right_P2_container">
                    <h4 className="Case_P3_C1_Right_P2_H4">7 months</h4>
                    <p className="Case_P3_C1_Right_P2_P">
                      From project inception to go live of advanced ERP in the
                      cloud
                    </p>
                  </div>
                  {/* part two */}
                  <div className="Case_P3_C1_Right_P2_container">
                    <h4 className="Case_P3_C1_Right_P2_H4">15%–20%</h4>
                    <p className="Case_P3_C1_Right_P2_P">
                      Improvement in system uptime and performance
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* third Part */}
        <div className="CaseStudiesMain_P2_S2">
          {/* box 1 */}
          {/* box 4 */}
          <a href={`${MainUrl}AmerGroup`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Amer Group</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Amer Group is a leading real estate developer that offers
                  mixed-use, family-oriented destinations.
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={AmerGroup}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One AmerGroup"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 2 */}
          <a href={`${MainUrl}EGIC`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">EGIC</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  EGIC was established to introduce PP-R systems for the water
                  supply in the Egyptian market
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2Two}
                  alt="casestudiesXP2Two"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 3 */}
          <a
            href={`${MainUrl}Pharmaoverseas`}
            className="CaseStudiesX_P2_Box_S3_A"
          >
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Pharmaoverseas</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Pharmaoverseas suppliers, governmental bodies, society, and
                  customers
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2Three}
                  alt="casestudiesXP2Three"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
        </div>
        <div className="CaseStudiesMain_P2_S2">
          {/* box 5 */}
          <a href={`${MainUrl}Damietta`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Damietta Alliance</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  The Port of Damietta is one of the main ports in Egypt, close
                  to the River Nile
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={Damietta}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One Damietta"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 6 */}
          <a href={`${MainUrl}ADH`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3 SLine">
                  Arab Developers Holding
                </h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Capturing the untapped potential and delivering healthy
                  returns for clients
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={ADH}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One Damietta"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
        </div>
        <div className="None"></div>
      </div>
    </>
  );
}

export default CaseStudyHsaContent;
