import React, { useState } from "react";
import "./DevOpsMoreInfo.css";

function DevOpsMoreInfo() {
  const [activeButton, setActiveButton] = useState(1);

  const contents = [
    // button 1
    {
      name: "Consistent High-Quality Results",
      paragraph:
        "Our approach to innovation involves robust, agile teams and dedicated quality assurance practices. This ensures that your projects consistently deliver high-quality outcomes. We prioritize excellence in every stage of your project, from conception to deployment.",
    },
    // button 2
    {
      name: "Cost-Effective Solutions",
      paragraph:
        "We provide highly cost-effective and best-of-breed solutions without any last-minute surprises. You can be confident that our services are not only of the highest quality but also budget-friendly. Your business's financial well-being is always a priority.",
    },
    // button 3
    {
      name: "Transparent Project Management",
      paragraph:
        "When you choose Phoenix Consulting, you benefit from transparent project management. We meticulously adhere to deadlines and provide you with full visibility into the progress of your projects. This transparency ensures that your expectations are met, and there are no unwelcome surprises along the way",
    },
  ];

  return (
    <div className="devOpsMoreInfoContainer">
      <Buttons
        contents={contents}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
      />
      <ContentDisplay content={contents[activeButton - 1]} />
    </div>
  );
}

function Buttons({ contents, activeButton, setActiveButton }) {
  return (
    <div className="DevOps_MoreInfo_container">
      {contents.map((content, index) => (
        <div
          key={index}
          className={`DevOps_MoreInfo_button DevOps_MoreInfo_individual ${
            activeButton === index + 1 ? "DevOps_MoreInfo_active" : ""
          }`}
          onClick={() => setActiveButton(index + 1)}
        >
          {content.name}
        </div>
      ))}
    </div>
  );
}

function ContentDisplay({ content }) {
  return (
    <div className="DevOps_MoreInfo_content">
      <div className="content_MoreInfo_container">
        <p>{content.paragraph}</p>
      </div>
    </div>
  );
}

export default DevOpsMoreInfo;
