import React, { useState, useEffect } from "react";
import "./home.css";
import Header from "../../components/header/header-dark";
import Footer from "../../components/footer/footer";
import FooterMobile from "../../components/footerMobile/footerMobile";
import HomeContent from "./home";
import HeaderMobile from "../../components/headerMobile/headerMobile";

function HomePage() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 475);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isMobile ? <HeaderMobile /> : <Header />}
      <HomeContent />
      {isMobile ? <FooterMobile /> : <Footer />}
    </>
  );
}

export default HomePage;
