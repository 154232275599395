import React, { useState } from "react";
import AccordionSapLeft from "./AccordionItemLeft";
import AccordionSapRight from "./AccordionItemRight";
import "./AccordionSapMain.css";

function SapACC() {
  const [openedAccordion, setOpenedAccordion] = useState(null);

  return (
    <div className="Sap_PartFive">
      <div className="acc_con">
        <AccordionSapLeft
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
        />
        <AccordionSapRight
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
        />
      </div>
    </div>
  );
}

export default SapACC;
