import React, { useState } from "react";
import SapRiseAccPartOne from "./SapRiseAccPart";

function SapRiseACCFirst() {
  const [openedAccordion, setOpenedAccordion] = useState(null);

  return (
    <div className="Sap_Rise_PartThree">
      <div className="Rise_acc_First_con">
        <SapRiseAccPartOne
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
        />
      </div>
    </div>
  );
}

export default SapRiseACCFirst;
