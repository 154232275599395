import React, { useState } from "react";
import AccordionRiseP2 from "./SapRiseAccPartTwo";

function SapRiseAccSecond() {
  const [openedAccordion, setOpenedAccordion] = useState(null);

  return (
    <div className="Sap_Rise_PartThree">
      <div className="Rise_acc_First_con">
        <AccordionRiseP2
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
        />
      </div>
    </div>
  );
}

export default SapRiseAccSecond;
