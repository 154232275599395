import React from "react";
import "./S4hana.css";
import { MainUrl } from "../../config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import S4hana_P2_S1 from "../../assets/images/S4hana_P2_S1.jpg";
import S4hana_P3_S2 from "../../assets/svg/S4hana_P3_S2.svg";
import S4hana_P5_S2 from "../../assets/svg/S4hana_P5_S2.svg";
import SapBannerVideo from "../../assets/videos/SapBannerVideo.mp4";
import LazyMedia from "../../lazyLoad/lazyLoad";

function S4HanaContent() {
  return (
    <>
      <div className="S4hanaContainer">
        {/* First Part */}
        <div className="S4hanaFirstPart">
          <LazyMedia
            type="video"
            src={SapBannerVideo}
            className="Sap_VideoBackground"
          />

          <div className="S4hana_P1_S1">
            <a href={`${MainUrl}sap-partnership`}>
              <h3 className="S4hana_P1_S1_H3">SAP</h3>
            </a>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <a href={`${MainUrl}sap-ariba`}>
              <h3 className="S4hana_P1_S1_H3">SAP S/4HANA</h3>
            </a>
          </div>
          <div className="S4hana_P1_S2">
            <h1 className="S4hana_P1_H1">SAP S/4HANA</h1>
            <h3 className="S4hana_P1_H3">
              Step into a new era of operations with our cutting-edge cloud ERP
              solution.
            </h3>
          </div>
        </div>
        {/* Second Part */}
        <div className="S4hanaSecondPart">
          <div className="S4hana_P2_S1">
            <LazyMedia
              type="image"
              src={S4hana_P2_S1}
              alt="S4hana_P2_S1"
              className="S4hana_P2_S1Web"
            />
          </div>
          <div className="S4hana_P2_S2">
            <h1 className="S4hana_P2_S2_H1">
              Amplify Your Business Potential with the Ideal Solution
            </h1>
            <LazyMedia
              type="image"
              src={S4hana_P2_S1}
              alt="S4hana_P2_S1"
              className="S4hana_P2_S1Mobile"
            />
            <p className="S4hana_P2_S2_P">
              SAP S/4HANA is the most complete and advanced ERP platform.
              Experience Remarkable Business Transformation with SAP S/4HANA,
              the Ultimate Cloud ERP Solution. Benefit from Enhanced
              Flexibility, Faster Insights, Improved Finances, Increased Profit,
              and Superior Asset Management.
            </p>
          </div>
        </div>
        {/* Third Part */}
        <div className="S4hanaThirdPart">
          <div className="S4hana_P3_S1">
            <h1 className="S4hana_P3_S1_H1">
              Amplify Your Business Potential with the Ideal Solution
            </h1>
            <LazyMedia
              type="image"
              src={S4hana_P3_S2}
              alt="S4hana_P2_S1"
              className="S4hana_P3_S1Mobile"
            />
            <p className="S4hana_P3_S1_P">
              SAP S/4HANA is the most complete and advanced ERP platform.
              Experience Remarkable Business Transformation with SAP S/4HANA,
              the Ultimate Cloud ERP Solution. Benefit from Enhanced
              Flexibility, Faster Insights, Improved Finances, Increased Profit,
              and Superior Asset Management.
            </p>
          </div>
          <div className="S4hana_P3_S2">
            <LazyMedia
              type="image"
              src={S4hana_P3_S2}
              alt="S4hana_P2_S1"
              className="S4hana_P3_S1Web"
            />
          </div>
        </div>
        {/* Fourth Part */}
        <div className="S4hanaFourthPart">
          <div className="S4hana_P4_S1">
            <h1 className="S4hana_P4_S1_H1">Why Choose SAP S/4HANA?</h1>
          </div>
          <div className="S4hana_P4_S2">
            <p className="S4hana_P4_S1_P">
              SAP S/4HANA is the perfect example of modern ERP systems that are
              designed to meet the demands of today's constantly evolving
              business landscape. Here's why choosing SAP S/4HANA is a strategic
              move
            </p>
          </div>
          <div className="S4hana_P4_S3">
            {/* line 1 */}
            <div className="S4hana_P4_S3_Box_Container">
              {/* box 1 */}
              <div className="S4hana_P4_S3_Box">
                <h3 className="S4hana_P4_S3_Box_H3">
                  Exceptional Business Transformation
                </h3>
                <p className="S4hana_P4_S3_Box_P">
                  SAP S/4HANA is the most complete and advanced ERP platform
                  available. It's engineered to enable businesses to transform
                  like never before.
                </p>
              </div>
              {/* box 2 */}
              <div className="S4hana_P4_S3_Box">
                <h3 className="S4hana_P4_S3_Box_H3">Elevate Flexibility</h3>
                <p className="S4hana_P4_S3_Box_P">
                  Adaptability is key in today's business environment. SAP
                  S/4HANA offers a level of flexibility that allows businesses
                  to quickly respond to change, whether it's market dynamics,
                  customer preferences, or global events.
                </p>
              </div>
              {/* box 3 */}
              <div className="S4hana_P4_S3_Box">
                <h3 className="S4hana_P4_S3_Box_H3">Accelerate Insights</h3>
                <p className="S4hana_P4_S3_Box_P">
                  With SAP S/4HANA, you're not just making decisions; you're
                  making data-driven decisions. The platform offers faster
                  analytics, ensuring you have the right information at the
                  right time.
                </p>
              </div>
            </div>
            {/* line 2 */}
            <div className="S4hana_P4_S3_Box_Container">
              {/* box 1 */}
              <div className="S4hana_P4_S3_Box">
                <h3 className="S4hana_P4_S3_Box_H3">Optimize Finances</h3>
                <p className="S4hana_P4_S3_Box_P">
                  Managing your finances is streamlined with SAP S/4HANA. It
                  enhances working capital, simplifies financial processes, and
                  ensures precise budgeting.
                </p>
              </div>
              {/* box 2 */}
              <div className="S4hana_P4_S3_Box">
                <h3 className="S4hana_P4_S3_Box_H3">Maximize Profit</h3>
                <p className="S4hana_P4_S3_Box_P">
                  By optimizing operations, improving decision-making, and
                  enhancing customer experiences, SAP S/4HANA can help increase
                  margins and overall profitability.
                </p>
              </div>
              {/* box 3 */}
              <div className="S4hana_P4_S3_Box">
                <h3 className="S4hana_P4_S3_Box_H3">
                  Perfect Asset Management
                </h3>
                <p className="S4hana_P4_S3_Box_P">
                  Effectively managing assets is critical for any business. SAP
                  S/4HANA offers powerful tools for tracking and optimizing
                  asset usage.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Fifth Part */}
        <div className="S4hanaFifthPart">
          <div className="S4hana_P5_S1">
            <h4 className="S4hana_P5_S1_H4">New Era of Operations</h4>
            <h2 className="S4hana_P5_S1_H2">Benefits of SAP S/4HANA</h2>
            <p className="S4hana_P5_S1_P">
              SAP S/4HANA is the most complete and advanced ERP
              platform.Experience Remarkable Business Transformation with SAP
              S/4HANA, the Ultimate Cloud ERP Solution. Benefit from Enhanced
              Flexibility, Faster Insights, Improved Finances, Increased Profit,
              and Superior Asset Management.
            </p>
          </div>
          <div className="S4hana_P5_S2">
            {/* box 1 */}
            <div className="S4hana_P5_S2_Box">
              <div className="S4hana_P5_S2_Head">
                <LazyMedia type="image" src={S4hana_P5_S2} alt="S4hana_P5_S2" />
                <h3 className="S4hana_P5_S2_Box_H3">Integrated Operations</h3>
              </div>
              <p className="S4hana_P5_S2_Box_P">
                Centralize your business operations, including finance, sales,
                procurement, and more, for a seamless and efficient workflow.
              </p>
            </div>
            {/* box 2 */}
            <div className="S4hana_P5_S2_Box">
              <div className="S4hana_P5_S2_Head">
                <LazyMedia type="image" src={S4hana_P5_S2} alt="S4hana_P5_S2" />
                <h3 className="S4hana_P5_S2_Box_H3">
                  Innovative User Experience
                </h3>
              </div>
              <p className="S4hana_P5_S2_Box_P">
                A modern, user-friendly interface makes your workforce more
                productive and enables quicker adoption.
              </p>
            </div>
            {/* box 3 */}
            <div className="S4hana_P5_S2_Box">
              <div className="S4hana_P5_S2_Head">
                <LazyMedia type="image" src={S4hana_P5_S2} alt="S4hana_P5_S2" />
                <h3 className="S4hana_P5_S2_Box_H3">
                  Industry-Specific Solutions
                </h3>
              </div>
              <p className="S4hana_P5_S2_Box_P">
                SAP S/4HANA provides industry-specific modules and solutions
                tailored to your unique needs.
              </p>
            </div>
            {/* box 4 */}
            <div className="S4hana_P5_S2_Box">
              <div className="S4hana_P5_S2_Head">
                <LazyMedia type="image" src={S4hana_P5_S2} alt="S4hana_P5_S2" />
                <h3 className="S4hana_P5_S2_Box_H3">Cloud-Enabled</h3>
              </div>
              <p className="S4hana_P5_S2_Box_P">
                Harness the benefits of the cloud, ensuring scalability and
                flexibility in the digital age.
              </p>
            </div>
          </div>
        </div>
        {/* Sixth Part */}
        <div className="S4hanaSixthPart">
          <div className="S4hana_P6_S1">
            <h1 className="S4hana_P6_S1_H1">Value Proposition</h1>
          </div>
          <div className="S4hana_P6_P">
            <p className="S4hana_P6_S1_P">
              Phoenix Consulting is your trusted partner in unlocking the
              potential of SAP S/4HANA. We offer comprehensive solutions that
              help you maximize the benefits of this transformative{" "}
            </p>
          </div>
          <div className="S4hana_P6_S2">
            {/* Box 1 */}
            <div className="S4hana_P6_S2_Box">
              <h3 className="S4hana_P6_S2_Box_H3">Implementation Services </h3>
              <p className="S4hana_P6_S2_Box_P">
                Our team of experts will guide you through the entire
                implementation process, ensuring a smooth transition to SAP
                S/4HANA.
              </p>
            </div>
            {/* Box 2 */}
            <div className="S4hana_P6_S2_Box">
              <h3 className="S4hana_P6_S2_Box_H3">Customization</h3>
              <p className="S4hana_P6_S2_Box_P">
                We understand that every business is unique. Our customization
                services tailor SAP S/4HANA to meet your specific requirements.
              </p>
            </div>
            {/* Box 3 */}
            <div className="S4hana_P6_S2_Box">
              <h3 className="S4hana_P6_S2_Box_H3">Consulting</h3>
              <p className="S4hana_P6_S2_Box_P">
                Leverage our vast experience to optimize your SAP S/4HANA
                deployment and utilize it to its fullest potential.
              </p>
            </div>
            {/* Box 4 */}
            <div className="S4hana_P6_S2_Box">
              <h3 className="S4hana_P6_S2_Box_H3">Training</h3>
              <p className="S4hana_P6_S2_Box_P">
                Equip your workforce with the skills and knowledge needed to
                harness SAP S/4HANA effectively.
              </p>
            </div>
            {/* Box 5 */}
            <div className="S4hana_P6_S2_Box">
              <h3 className="S4hana_P6_S2_Box_H3">Maintenance and Support</h3>
              <p className="S4hana_P6_S2_Box_P">
                We're here for you even after implementation, providing
                maintenance and support services to keep your operations running
                smoothly.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default S4HanaContent;
