import React, { useRef, useEffect, useState } from "react";
import "intl-tel-input/build/css/intlTelInput.css";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/js/utils";
import "./country.css";

const PhoneNumberInput = ({ onPhoneNumberChange }) => {
  const phoneInputRef = useRef(null);
  const [isValidNumber, setIsValidNumber] = useState(true);

  useEffect(() => {
    const phoneInput = phoneInputRef.current;

    const iti = intlTelInput(phoneInput, {
      separateDialCode: true,
      initialCountry: "eg",
      nationalMode: false,
      utilsScript: "/js/utils.js",
    });

    phoneInput.addEventListener("blur", () => {
      const isValid = iti.isValidNumber();
      setIsValidNumber(isValid);

      if (typeof onPhoneNumberChange === "function" && isValid) {
        onPhoneNumberChange(iti.getNumber());
      }
    });

    return () => {
      iti.destroy();
    };
  }, [onPhoneNumberChange]);

  return (
    <label className="input_country">
      <input
        name="user_phone"
        type="text"
        ref={phoneInputRef}
        placeholder="Enter phone number"
        className={`country_input ${!isValidNumber ? "invalid" : ""}`}
        required
      />
    </label>
  );
};

export default PhoneNumberInput;
