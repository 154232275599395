import React, { useState } from "react";
import "./AzurePartThreeInfo.css";

function AzureInfo() {
  const [activeButton, setActiveButton] = useState(1);

  const contents = [
    // button 1
    {
      name: "Cloud Transformation",
      paragraph:
        "Our approach to innovation involves robust, agile teams and dedicated quality assurance practices. This ensures that your projects consistently deliver high-quality outcomes. We prioritize excellence in every stage of your project, from conception to deployment.",
    },
    // button 2
    {
      name: "Migration Services",
      paragraph:
        "From data center shifts to application modernization, we employ tools like Azure Migrate to ensure a frictionless migration.",
    },
    // button 3
    {
      name: "Managed Services",
      paragraph:
        "Our managed Azure Cloud Services provide a secure, cost-optimized, and well-managed cloud environment, enhancing agility and fostering a cooperative IT culture.",
    },
  ];

  return (
    <div className="AzureInfoContainer">
      <Buttons
        contents={contents}
        activeButton={activeButton}
        setActiveButton={setActiveButton}
      />
      <ContentDisplay content={contents[activeButton - 1]} />
    </div>
  );
}

function Buttons({ contents, activeButton, setActiveButton }) {
  return (
    <div className="Azure_Info_containerFirst">
      {contents.map((content, index) => (
        <div
          key={index}
          className={`Azure_Info_button Azure_Info_individual ${
            activeButton === index + 1 ? "Azure_Info_active" : ""
          }`}
          onClick={() => setActiveButton(index + 1)}
        >
          {content.name}
        </div>
      ))}
    </div>
  );
}

function ContentDisplay({ content }) {
  return (
    <div className="Azure_Info_content">
      <div className="Azure_Info_container">
        <p>{content.paragraph}</p>
      </div>
    </div>
  );
}

export default AzureInfo;
