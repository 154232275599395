import React from "react";
import "./Odoo.css";
import { MainUrl } from "../../config";
import SapBanner from "../../assets/svg/sap_banner.svg";
import PartnersBanner from "../../assets/videos/Partners_Banner.MP4";
import SapStar from "../../assets/svg/SapStar.svg";
import OdooLogo from "../../assets/images/Odoo_P1_Logo.png";
import SapPlus from "../../assets/svg/Sap_plus.svg";
import Odoo_P2_S2 from "../../assets/images/Odoo_P2_S2.jpg";
import Odoo_P3_S2_Box1 from "../../assets/images/Odoo_P3_S2_Box1.png";
import Odoo_P3_S2_Box2 from "../../assets/images/Odoo_P3_S2_Box2.png";
import Odoo_P3_S2_Box3 from "../../assets/images/Odoo_P3_S2_Box3.png";
import Odoo_P6_S1 from "../../assets/images/Odoo_P6_S1.jpg";
import Odoo_P8_S2 from "../../assets/images/Odoo_P8_S2.jpg";
import LazyMedia from "../../lazyLoad/lazyLoad";

function OdooContent() {
  return (
    <>
      <div className="Odoo_Container">
        <div className="Odoo_FirstPart">
          <LazyMedia
            type="video"
            src={PartnersBanner}
            className="Partners_Aws_VideoBackground"
          />
          <LazyMedia
            type="image"
            src={SapStar}
            alt="SapStar"
            className="OdooStar"
          />
          <div className="Odoo_P1_P1">
            <h1 className="Odoo_P1_P1_H1">Phoenix</h1>
            <LazyMedia type="image" src={SapPlus} alt="SapPlus" />
            <LazyMedia
              type="image"
              src={OdooLogo}
              alt="OdooLogo"
              className="OdooLogo"
            />
          </div>
          <h1 className="Odoo_FirstPart_H1">
            Your Scalable Business Solutions Architect
          </h1>
          <p className="Odoo_FirstPart_P">
            Embark on a journey where we pioneer innovative paths to business
            excellence through our strategic partnership with Odoo. Our mission
            is to harness the versatility and comprehensive suite of Odoo's ERP
            solutions to transform your business operations, ensuring agility,
            efficiency, and competitive edge.
          </p>
        </div>
        <div className="Odoo_SecondPart">
          <div className="Odoo_P2_S1">
            <h1 className="Odoo_P2_S1_H1">
              The Power of Odoo, Amplified by Phoenix
            </h1>
            <p className="Odoo_P2_S1_P">
              Odoo's diverse and powerful framework is the cornerstone of our
              integrated business applications. With Phoenix, you're not just
              adopting an ERP system; you're embarking on a journey to reinvent
              your business workflows, tailor-fit to your unique specifications.
              Our technical, functional, and operational consultants are adept
              at morphing Odoo's open-source framework into a personalized
              powerhouse for your enterprise.
            </p>
          </div>
          <div className="Odoo_P2_S2">
            <LazyMedia
              type="image"
              src={Odoo_P2_S2}
              alt="Odoo_P2_S2"
              className="Odoo_P2_S2"
            />
          </div>
        </div>
        <div className="Odoo_ThirdPart">
          <div className="Odoo_P3_S1">
            <h1 className="Odoo_P3_S1_H1">Customized Odoo ERP Solutions</h1>
          </div>
          <div className="Odoo_P3_S2">
            {/* box 1 */}
            <div className="Odoo_P3_S2_Box">
              <LazyMedia
                type="image"
                src={Odoo_P3_S2_Box1}
                alt="Odoo_P3_S2_Box1"
                className="Odoo_P3_S2_Box1"
              />
              <h3 className="Odoo_P3_S2_Box_H3">Technical Consulting</h3>
              <p className="Odoo_P3_S2_Box_P">
                We'll transform your technical landscape, addressing
                code-related issues and aligning Odoo's solutions with your
                business processes.
              </p>
            </div>
            {/* box 2 */}
            <div className="Odoo_P3_S2_Box">
              <LazyMedia
                type="image"
                src={Odoo_P3_S2_Box2}
                alt="Odoo_P3_S2_Box2"
                className="Odoo_P3_S2_Box1"
              />
              <h3 className="Odoo_P3_S2_Box_H3">Functional Consulting</h3>
              <p className="Odoo_P3_S2_Box_P">
                Expect a seamless transition of your processes into
                algorithm-based business models with comprehensive training and
                support post-implementation.
              </p>
            </div>
            {/* box 3 */}
            <div className="Odoo_P3_S2_Box">
              <LazyMedia
                type="image"
                src={Odoo_P3_S2_Box3}
                alt="Odoo_P3_S2_Box3"
                className="Odoo_P3_S2_Box1"
              />
              <h3 className="Odoo_P3_S2_Box_H3">Operational Consulting</h3>
              <p className="Odoo_P3_S2_Box_P">
                We're here to remodel your business processes, ensuring the
                defined functionalities optimize your organization's resources.
              </p>
            </div>
          </div>
        </div>
        <div className="Odoo_FourthPart">
          <div className="Odoo_P4_First">
            <div className="Odoo_P4_S1">
              <h1 className="Odoo_P4_S1_H1">
                Comprehensive Services for a Unified Business Strategy
              </h1>
            </div>
            <div className="Odoo_P4_S2">
              <p className="Odoo_P4_S2_P">
                Phoenix offers a wide spectrum of services that ensure your
                business leverages Odoo's full potential
              </p>
            </div>
          </div>
          <div className="Odoo_P4_S3">
            {/* line 1 */}
            <div className="Odoo_P4_S3_Box_Container">
              {/* box 1 */}
              <div className="Odoo_P4_S3_Box">
                <h3 className="Odoo_P4_S3_Box_H3">
                  Custom Application Development
                </h3>
                <p className="Odoo_P4_S3_Box_P">
                  Crafted to address the unique challenges of your business.
                </p>
              </div>
              {/* box 2 */}
              <div className="Odoo_P4_S3_Box">
                <h3 className="Odoo_P4_S3_Box_H3">CRM & Sales Automation</h3>
                <p className="Odoo_P4_S3_Box_P">
                  Enhancing customer relations and sales operations with
                  finesse.
                </p>
              </div>
              {/* box 3 */}
              <div className="Odoo_P4_S3_Box">
                <h3 className="Odoo_P4_S3_Box_H3">Inventory & MRP Solutions</h3>
                <p className="Odoo_P4_S3_Box_P">
                  Ensuring smooth inventory and manufacturing workflows.
                </p>
              </div>
            </div>
            <div className="Odoo_P4_S3_Box_Container">
              {/* box 4 */}
              <div className="Odoo_P4_S3_Box">
                <h3 className="Odoo_P4_S3_Box_H3">
                  Integrated Accounting Software
                </h3>
                <p className="Odoo_P4_S3_Box_P">
                  Financial management simplified and streamlined.
                </p>
              </div>
              {/* box 5 */}
              <div className="Odoo_P4_S3_Box">
                <h3 className="Odoo_P4_S3_Box_H3">
                  Human Resources Management
                </h3>
                <p className="Odoo_P4_S3_Box_P">
                  From recruitment to retirement, handle all HR tasks
                  efficiently.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Odoo_FifthPart">
          <div className="Odoo_P5_S1">
            <h1 className="Odoo_P5_S1_H1">Your Guide in the Odoo Ecosystem</h1>
          </div>
          <div className="Odoo_P5_S2">
            <div className="Odoo_P5_S2_Container">
              {/* box 1 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">
                  Personalized Implementation
                </h3>
                <p className="Odoo_P5_S2_Box_P">
                  Your business's needs guide our Odoo solution deployment.
                </p>
              </div>
              {/* box 2 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">
                  Cost-Effective Subscription
                </h3>
                <p className="Odoo_P5_S2_Box_P">
                  Enjoy a subscription model that maximizes your ROI.
                </p>
              </div>
              {/* box 3 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">
                  Rapid & Efficient Rollouts
                </h3>
                <p className="Odoo_P5_S2_Box_P">
                  Accelerate your time to market with our swift integration.
                </p>
              </div>
              {/* box 4 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">Scalable Solutions</h3>
                <p className="Odoo_P5_S2_Box_P">
                  Grow without constraints, thanks to Odoo's modular design.
                </p>
              </div>
            </div>
            <div className="Odoo_P5_S2_Container">
              {/* box 5 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">End-to-End Support</h3>
                <p className="Odoo_P5_S2_Box_P">
                  From strategic planning to ongoing management, we've got you
                  covered.
                </p>
              </div>
              {/* box 6 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">
                  Access Exclusive Odoo Resources
                </h3>
                <p className="Odoo_P5_S2_Box_P">
                  Benefit from a treasure trove of apps and modules.
                </p>
              </div>
              {/* box 7 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">Robust Risk Management</h3>
                <p className="Odoo_P5_S2_Box_P">
                  Our guidance ensures a secure Odoo deployment.
                </p>
              </div>
              {/* box 8 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">
                  Strategic Business Insights
                </h3>
                <p className="Odoo_P5_S2_Box_P">
                  Expert consultations to architect your solution landscape.
                </p>
              </div>
            </div>
            <div className="Odoo_P5_S2_Container">
              {/* box 9 */}
              <div className="Odoo_P5_S2_Box">
                <h3 className="Odoo_P5_S2_Box_H3">Value-Added Services</h3>
                <p className="Odoo_P5_S2_Box_P">
                  Our services extend beyond mere implementation.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Odoo_SixthPart">
          <div className="Odoo_P6_S1">
            <LazyMedia
              type="image"
              src={Odoo_P6_S1}
              alt="Odoo_P6_S1"
              className="Odoo_P6_S1Web"
            />
          </div>
          <div className="Odoo_P6_S2">
            <h1 className="Odoo_P6_S2_H1">
              Experience the Phoenix Transformation
            </h1>
            <LazyMedia
              type="image"
              src={Odoo_P6_S1}
              alt="Odoo_P6_S1"
              className="Odoo_P6_S1Mobile"
            />
            <p className="Odoo_P6_S2_P">
              Our comprehensive approach begins with understanding your business
              core, aligning our strategies with your vision, and customizing
              Odoo's applications to fit like a glove. We walk you through every
              step, from initial discussions to rigorous quality checks,
              ensuring that our solutions not only meet but exceed performance
              and security standards.
            </p>
          </div>
        </div>
        <div className="Odoo_SeventhPart">
          <div className="Odoo_P7_S1">
            <h1 className="Odoo_P7_S1_H1">
              Key Benefits of Our Odoo Partnership
            </h1>
          </div>
          <div className="Odoo_P7_S2">
            <div className="Odoo_P7_S2_Box_Container">
              {/* box 1 */}
              <div className="Odoo_P7_S2_Box">
                <h3 className="Odoo_P7_S2_Box_H3">A Unified ERP System</h3>
                <p className="Odoo_P7_S2_Box_P">
                  Experience seamless management of all business operations.
                </p>
              </div>
              {/* box 2 */}
              <div className="Odoo_P7_S2_Box">
                <h3 className="Odoo_P7_S2_Box_H3">Open-Source Flexibility</h3>
                <p className="Odoo_P7_S2_Box_P">
                  Customizable to the core, meeting every need of your
                  enterprise.
                </p>
              </div>
              {/* box 3 */}
              <div className="Odoo_P7_S2_Box">
                <h3 className="Odoo_P7_S2_Box_H3">Efficient Workflows</h3>
                <p className="Odoo_P7_S2_Box_P">
                  A broad spectrum of modules for every business function.
                </p>
              </div>
            </div>
            <div className="Odoo_P7_S2_Box_Container">
              {/* box 4 */}
              <div className="Odoo_P7_S2_Box">
                <h3 className="Odoo_P7_S2_Box_H3">User-Friendly Interface</h3>
                <p className="Odoo_P7_S2_Box_P">
                  Intuitive tools that cater to diverse business needs.
                </p>
              </div>
              {/* box 5 */}
              <div className="Odoo_P7_S2_Box">
                <h3 className="Odoo_P7_S2_Box_H3">
                  Dynamic Community & Suppor
                </h3>
                <p className="Odoo_P7_S2_Box_P">
                  A thriving Odoo community continuously enhancing the platform.
                </p>
              </div>
              {/* box 6 */}
              <div className="Odoo_P7_S2_Box">
                <h3 className="Odoo_P7_S2_Box_H3">
                  Mobile & Cloud Compatibility
                </h3>
                <p className="Odoo_P7_S2_Box_P">
                  Manage your operations anytime, anywhere.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Odoo_EigthPart">
          <div className="Odoo_P8_S1">
            <div className="Odoo_P8_S1_Text">
              <h1 className="Odoo_P8_S1_H1">
                Transforming Your Ideas into Profits
              </h1>
              <p className="Odoo_P8_S1_P">
                Seeking to expedite and enhance your digital transformation
                voyage
              </p>
              <h3 className="Odoo_P8_S1_H3">
                Join us in tackling your business challenges head-on
              </h3>
            </div>
            <a href={`${MainUrl}contact-us`}>
              <button className="Odoo_P8_S1_Button">Contact us</button>
            </a>
          </div>
          <div className="Odoo_P8_S2">
            <LazyMedia
              type="image"
              src={Odoo_P8_S2}
              alt="Odoo_P8_S2"
              className="Odoo_P8_S2"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default OdooContent;
