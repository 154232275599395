import React, { useState, useEffect } from "react";
import "./HomePartThreeSliders.css";
import { MainUrl } from "../../../config";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { ReactComponent as HomeSlideAniMationArrowRight } from "../../../assets/svg/Home_Slide_animation_arrow_right.svg";
import LazyBackground from "../../../lazyLoad/lazyLoadBackGround";
import Slide1 from "../../../assets/svg/Home_P3_Slider1.svg";
import Slide2 from "../../../assets/svg/Home_ERP_Solutions.svg";
import Slide3 from "../../../assets/svg/HomeBusinessProcess.svg";
import Slide4 from "../../../assets/svg/Home_System_Integration.svg";
import Slide5 from "../../../assets/svg/Home_Cloud_Services.svg";
import Slide6 from "../../../assets/images/Home_IT_Consultation.jpg";

const slideImages = {
  First: Slide1,
  Second: Slide2,
  Third: Slide3,
  Fourth: Slide4,
  Fifth: Slide5,
  Sixth: Slide6,
};

function HomePartThreeSliders() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [backgroundImage, setBackgroundImage] = useState(slideImages.First);
  const [hoveredSlide, setHoveredSlide] = useState(null);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 475);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);

  console.log("Current slide:", currentSlide);
  console.log("Background image URL:", backgroundImage);
  const slides = [
    {
      id: "First",
      title: "Digital Transformation",
      description:
        "We guide you through the digital landscape, helping your organization harness the power of modern technologies to enhance efficiency and drive growth.",
      path: "contact-us",
    },
    {
      id: "Second",
      title: "ERP Solutions",
      description:
        "Our state-of-the-art Enterprise Resource Planning (ERP) systems streamline your operations, ensuring seamless coordination across all aspects of your business.",
      path: "contact-us",
    },
    {
      id: "Third",
      title: "Business Process Optimization",
      description:
        "We analyze your workflows and implement solutions to maximize productivity and reduce operational costs.",
      path: "contact-us",
    },
    {
      id: "Fourth",
      title: "System Integration",
      description:
        "Our experts integrate complex systems and technologies to ensure they work together seamlessly, providing you with a unified and efficient digital ecosystem.",
      path: "contact-us",
    },
    {
      id: "Fifth",
      title: "Cloud Services",
      description:
        "We offer cloud-based solutions that provide scalability, security, and flexibility, enabling your business to thrive in the digital age.",
      path: "contact-us",
    },
    {
      id: "Sixth",
      title: "IT Consultation",
      description:
        "Rely on our experienced consultants to provide strategic guidance and tailor-made solutions that align with your business goals.",
      path: "contact-us",
    },
  ];

  const swipeThreshold = window.innerWidth / 50;

  const getBackgroundImageForSlide = (slideId) => {
    return slideImages[slideId] || slideImages.First; // Default image if not found
  };

  const handleForward = () => {
    const nextSlideIndex = (currentSlide + 1) % slides.length;
    setCurrentSlide(nextSlideIndex);
    setBackgroundImage(getBackgroundImageForSlide(slides[nextSlideIndex].id));
  };

  const handleBackward = () => {
    const previousSlideIndex =
      (currentSlide - 1 + slides.length) % slides.length;
    setCurrentSlide(previousSlideIndex);
    setBackgroundImage(
      getBackgroundImageForSlide(slides[previousSlideIndex].id)
    );
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
  };

  useEffect(() => {
    if (touchStartX !== null && touchEndX !== null) {
      const deltaX = touchEndX - touchStartX;
      if (deltaX > swipeThreshold) {
        // Right swipe
        handleBackward();
      } else if (deltaX < -swipeThreshold) {
        // Left swipe
        handleForward();
      }
      setTouchStartX(null);
      setTouchEndX(null);
    }
  }, [touchStartX, touchEndX]);

  useEffect(() => {
    const updateMedia = () => {
      setIsSmallScreen(window.innerWidth <= 475);
    };

    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  const displaySlides = isSmallScreen
    ? [slides[currentSlide % slides.length]]
    : [
        slides[currentSlide % slides.length],
        slides[(currentSlide + 1) % slides.length],
        slides[(currentSlide + 2) % slides.length],
      ];

  return (
    <LazyBackground src={backgroundImage} className="HomeSliderAnimation">
      <div className="Home_Slider_Animation_Fixed">
        <h1 className="Home_Slider_Animation_Fixed_H1">What we do</h1>
      </div>

      {displaySlides.map((slide, index) => (
        <div
          key={slide.id}
          className={`Home_Slider_Animation_${slide.id}`}
          onMouseEnter={() =>
            setBackgroundImage(getBackgroundImageForSlide(slide.id))
          }
          onMouseLeave={() => setBackgroundImage(slideImages.First)}
        >
          <div className="Home_Slider_Animation_Main">
            <h1 className={`Home_Slider_Animation_${slide.id}_H1`}>
              {slide.title}
            </h1>
            <p className={`Home_Slider_Animation_${slide.id}_P`}>
              {slide.description}
            </p>
            <a
              href={`${MainUrl}${slide.path}`}
              target="blank"
              className="Home_P3_Button"
            >
              <button className={`Home_Slider_Animation_${slide.id}_Button`}>
                {hoveredSlide === slide.id && (
                  <span className="explore-text">Explore</span>
                )}
                <HomeSlideAniMationArrowRight />
              </button>
            </a>
          </div>
        </div>
      ))}

      <div className="HomeSlider_A_Forword" onClick={handleForward}>
        <ArrowForwardIosIcon fontSize="large" />
      </div>
      <div className="HomeSlider_A_Back" onClick={handleBackward}>
        <ArrowBackIosIcon fontSize="large" />
      </div>
    </LazyBackground>
  );
}

export default HomePartThreeSliders;
