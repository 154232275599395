import React from "react";
import "./SapRiseAccPartTwo.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const RiseAccordionItemPartTwo = ({
  title,
  children,
  isOpen,
  onAccordionClick,
  identifier,
}) => {
  const handleClick = () => {
    if (isOpen) {
      onAccordionClick(null);
    } else {
      onAccordionClick(identifier);
    }
  };

  return (
    <div className="Rise_P2_accordion_item">
      <div className="Rise_P2_accordion_header" onClick={handleClick}>
        <span className="Rise_P2_accordion_icon">
          {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </span>
        <span className="Rise_P2_accordion_title">{title}</span>
      </div>
      {isOpen && <div className="Rise_P2_accordion_content">{children}</div>}
    </div>
  );
};

function AccordionRiseP2({ openedAccordion, setOpenedAccordion }) {
  return (
    <div className="Rise_P2_accordion">
      {/* acc li 1 */}
      <RiseAccordionItemPartTwo
        title="Proven Partnership"
        identifier="Part-1"
        isOpen={openedAccordion === "Part-1"}
        onAccordionClick={setOpenedAccordion}
      >
        Since 2011, AWS has been a trusted partner for SAP customers. Our
        collaboration with SAP blends their solution expertise with AWS's cloud
        leadership, making your "Rise with SAP" journey a secure and innovative
        experience.
      </RiseAccordionItemPartTwo>
      {/* acc li 2 */}
      <RiseAccordionItemPartTwo
        title="Comprehensive Reach"
        identifier="Part-2"
        isOpen={openedAccordion === "Part-2"}
        onAccordionClick={setOpenedAccordion}
      >
        "Rise with SAP" covers the complete S/4HANA spectrum, including diverse
        line-of-business processes that cater to 25 different industries.
      </RiseAccordionItemPartTwo>
      {/* acc li 3 */}
      <RiseAccordionItemPartTwo
        title="Seamless Transition"
        identifier="Part-3"
        isOpen={openedAccordion === "Part-3"}
        onAccordionClick={setOpenedAccordion}
      >
        Our expert configuration ensures you can make the most of AWS
        infrastructure and services, ensuring a seamless move to the cloud.
      </RiseAccordionItemPartTwo>
      {/* acc li 4 */}
      <RiseAccordionItemPartTwo
        title="Flexible Financing"
        identifier="Part-4"
        isOpen={openedAccordion === "Part-4"}
        onAccordionClick={setOpenedAccordion}
      >
        "Rise with SAP" offers subscription-based pricing, allowing you to
        switch from capital expenditures to operational expenditures, aligning
        your budget with your evolving business needs.
      </RiseAccordionItemPartTwo>
      {/* acc li 5 */}
      <RiseAccordionItemPartTwo
        title="Tailored Solutions"
        identifier="Part-5"
        isOpen={openedAccordion === "Part-5"}
        onAccordionClick={setOpenedAccordion}
      >
        "Rise with SAP" can be personalized to your unique business
        requirements, including compatibility with partner add-ons and existing
        enhancements.
      </RiseAccordionItemPartTwo>
    </div>
  );
}

export default AccordionRiseP2;
