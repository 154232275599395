import React from "react";
import "./AzurePartSixBox.css";

function AzurePartSixBoxH() {
  return (
    <>
      <div className="Azure_P6_S2_Box">
        <h3 className="Azure_P6_S2_Box_H3">Experience & Partnership</h3>
        <p className="Azure_P6_S2_Box_P">
          Leverage our deep SAP-Microsoft collaboration for innovative
          solutions.
        </p>
      </div>
      <div className="Azure_P6_S2_Box">
        <h3 className="Azure_P6_S2_Box_H3">Maximize Profit</h3>
        <p className="Azure_P6_S2_Box_P">
          Your migration is secured, meeting standards like GDPR, ISO 27001, SOC
          1, and SOC 2.
        </p>
      </div>
      <div className="Azure_P6_S2_Box">
        <h3 className="Azure_P6_S2_Box_H3">Tailored Services</h3>
        <p className="Azure_P6_S2_Box_P">
          From SAP BASIS management to disaster recovery, we customize solutions
          to your needs.
        </p>
      </div>
      <div className="Azure_P6_S2_Box">
        <h3 className="Azure_P6_S2_Box_H3">Global Reach & Scalability</h3>
        <p className="Azure_P6_S2_Box_P">
          Benefit from Azure's expansive regional presence and scale your
          operations on-demand.
        </p>
      </div>
    </>
  );
}

export default AzurePartSixBoxH;
