import React from "react";
import "./AccordionItemLeft.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const AccordionItem = ({
  title,
  children,
  isOpen,
  onAccordionClick,
  identifier,
}) => {
  const handleClick = () => {
    if (isOpen) {
      onAccordionClick(null);
    } else {
      onAccordionClick(identifier);
    }
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={handleClick}>
        <span className="accordion-icon">
          {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </span>
        <span className="accordion-title">{title}</span>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

function AccordionSapLeft({ openedAccordion, setOpenedAccordion }) {
  return (
    <div className="accordion">
      <AccordionItem
        title="SAP System Heath Check"
        identifier="left-1"
        isOpen={openedAccordion === "left-1"}
        onAccordionClick={setOpenedAccordion}
      >
        Phoenix Consulting offers a comprehensive SAP System Health Check
        service, evaluating both the application and database components,
        including infrastructure, performance, workload, and setup, along with
        the HANA database and SAP application servers. We deliver a detailed
        report with findings, recommended actions, and improvement suggestions.
        Our process involves reviewing compliance status, conducting a system
        audit, analyzing data and logs, and presenting comprehensive findings
        report.
      </AccordionItem>
      <AccordionItem
        title="Material Management"
        identifier="left-2"
        isOpen={openedAccordion === "left-2"}
        onAccordionClick={setOpenedAccordion}
      >
        Elevate your inventory control with SAP Material Management. This module
        offers efficient procurement, material movement management, and supply
        chain optimization, ensuring materials are available when and where
        needed. SAP MM is designed to maintain materials in the right quantities
        without supply chain disruptions, facilitating timely and cost-effective
        goods procurement and the ability to handle daily changes in these
        processes.
      </AccordionItem>
      <AccordionItem
        title="Sales & Distribution"
        identifier="left-3"
        isOpen={openedAccordion === "left-3"}
        onAccordionClick={setOpenedAccordion}
      >
        Leverage SAP to supercharge your sales and distribution processes.
        Streamline order processing, pricing, and promotion management to
        enhance the efficiency of your order-to-cash operations. SAP enables you
        to evaluate pricing under various conditions such as rebates or
        discounts, ensures the availability of products in your warehouse, and
        simplifies billing and invoice generation, empowering you to optimize
        your sales and distribution activities.
      </AccordionItem>
      <AccordionItem
        title="Quality Management"
        identifier="left-4"
        isOpen={openedAccordion === "left-4"}
        onAccordionClick={setOpenedAccordion}
      >
        Safeguard product quality and compliance standards with SAP Quality
        Management. This essential component of SAP S/4HANA helps you
        efficiently manage inspections, maintain product quality, and ensure
        industry compliance. QM integrates seamlessly with other SAP components,
        including Materials Management, Plant Maintenance, Production Planning,
        Sales and Distribution, Finance, Controlling, and Human Resources,
        enabling you to incorporate quality management tasks into various
        integrated processes for a more cohesive approach to quality control.
      </AccordionItem>
      <AccordionItem
        title="Plant Maintenance"
        identifier="left-5"
        isOpen={openedAccordion === "left-5"}
        onAccordionClick={setOpenedAccordion}
      >
        Enhance equipment reliability and minimize downtime with SAP Plant
        Maintenance. This component of SAP ERP Central Component (ECC)
        facilitates equipment and systems support. It integrates seamlessly with
        other ECC components, such as Materials Management, Quality Management,
        and Production Planning, streamlining inspection, preventive
        maintenance, and repair activities. SAP PM simplifies maintenance
        requests, automates some repair tasks, and fosters data synchronization
        across various ECC modules, triggering related processes as needed.
      </AccordionItem>
      <AccordionItem
        title="Industry Specific Modules"
        identifier="left-6"
        isOpen={openedAccordion === "left-6"}
        onAccordionClick={setOpenedAccordion}
      >
        Customize SAP to precisely match the unique demands of your industry
        through specialized modules. These modules are designed to enhance
        operations and address the specific needs of various industries,
        ensuring optimized performance and efficiency.
      </AccordionItem>
    </div>
  );
}

export default AccordionSapLeft;
