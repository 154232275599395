import React from "react";
import "./SapRiseAccPart.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const RiseAccordionItem = ({
  title,
  children,
  isOpen,
  onAccordionClick,
  identifier,
}) => {
  const handleClick = () => {
    if (isOpen) {
      onAccordionClick(null);
    } else {
      onAccordionClick(identifier);
    }
  };

  return (
    <div className="Rise_accordion_First_item">
      <div className="Rise_accordion_First_header" onClick={handleClick}>
        <span className="Rise_accordion_First_title">{title}</span>
        <span className="Rise_accordion-icon">
          {isOpen ? <RemoveIcon /> : <AddIcon />}
        </span>
      </div>
      {isOpen && <div className="Rise_accordion_First_content">{children}</div>}
    </div>
  );
};

function SapRiseAccPartOne({ openedAccordion, setOpenedAccordion }) {
  return (
    <div className="Rise_accordion_First">
      <RiseAccordionItem
        title="Cloud Solutions"
        identifier="Part-1"
        isOpen={openedAccordion === "Part-1"}
        onAccordionClick={setOpenedAccordion}
      >
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">Cloud ERP</h3>
            <p className="Rise_Acc_First_Text_P">
              Seamlessly adapt to your business's evolving requirements with the
              latest innovations from SAP S/4HANA Cloud, private edition.
            </p>
          </div>
        </div>{" "}
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Business Process Optimization
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Meticulously map, monitor, and enhance the transition of your
              existing ERP data and business processes during your cloud ERP
              migration.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Extension, Integration, and Automation
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Expand your business capabilities and take full advantage of
              advanced financial management, invoice optimization, and cash
              collection processes.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">Supplier Network</h3>
            <p className="Rise_Acc_First_Text_P">
              Collaborate more effectively with your suppliers, streamlining
              invoice processing, improving spend transparency, and ensuring
              compliance.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">Sustainability Managemen</h3>
            <p className="Rise_Acc_First_Text_P">
              Accelerate your journey toward a sustainable, profitable business
              model with a green ledger.
            </p>
          </div>
        </div>
      </RiseAccordionItem>
      <RiseAccordionItem
        title="Cloud Infrastructure and Security"
        identifier="Part-2"
        isOpen={openedAccordion === "Part-2"}
        onAccordionClick={setOpenedAccordion}
      >
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              SAP-Managed Infrastructure and Applications
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Secure your business's future with confidence in the scalability
              of SAP-managed cloud infrastructure and applications.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Global Hyperscaler Infrastructure
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Deploy SAP's cloud solutions in your chosen hyperscaler
              environment, ensuring unparalleled flexibility and performance.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Security, Risk, and Compliance
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Protect your ERP data and processes while adhering to regulatory
              requirements.
            </p>
          </div>
        </div>
      </RiseAccordionItem>
      <RiseAccordionItem
        title="Migration and Adoption Services"
        identifier="Part-3"
        isOpen={openedAccordion === "Part-3"}
        onAccordionClick={setOpenedAccordion}
      >
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">Tools and Methodologies</h3>
            <p className="Rise_Acc_First_Text_P">
              Migrate to cloud ERP with expert guidance based on proven best
              practices.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Cloud Application Services
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Transition to cloud-based solutions with predictable outcomes and
              fixed costs.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">Partners and Community</h3>
            <p className="Rise_Acc_First_Text_P">
              Trust in our global strategic service partners to guide you
              through your migration journey.
            </p>
          </div>
        </div>
      </RiseAccordionItem>
      <RiseAccordionItem
        title="Business Process Optimization"
        identifier="Part-4"
        isOpen={openedAccordion === "Part-4"}
        onAccordionClick={setOpenedAccordion}
      >
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Co-create Your Future Business Processes
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Collaborate with your team and leverage a single source of truth
              to improve and reinvent your processes.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">
              Identify and Remove Bottlenecks
            </h3>
            <p className="Rise_Acc_First_Text_P">
              Gain insights into your current process performance and receive
              recommendations for transformation.
            </p>
          </div>
        </div>
        <div className="Rise_Acc_Text">
          <div className="Rise_Acc_First_Text">
            <h3 className="Rise_Acc_First_Text_H3">Continuous Improvement</h3>
            <p className="Rise_Acc_First_Text_P">
              Achieve transparency within your processes and determine
              stakeholder responsibilities, fostering a culture of continuous
              optimization.
            </p>
          </div>
        </div>
      </RiseAccordionItem>
    </div>
  );
}

export default SapRiseAccPartOne;
