import React from "react";
import "./Oils_Gas.css";
import OilsGasP1S2 from "../../assets/images/OilsGas_P1_S2.png";
import OilsGasP2S2Egas from "../../assets/images/OilsGas_P2_S2_Egas.png";
import OilsGasP2S2Socar from "../../assets/svg/OilsGas_P2_S2_Socar.svg";
import OilsGasP2S2Petro from "../../assets/svg/OilsGas_P2_S2_Petro.svg";
import OilsGasP2S2Adnoc from "../../assets/svg/OilsGas_P2_S2_Adnoc.svg";
import OilsGasP2S2ORYX from "../../assets/svg/OilsGas_P2_S2_ORYX.svg";
import OilsGasP2S2Jocap from "../../assets/svg/OilsGas_P2_S2_Jocap.svg";
import OilsGasP2S2Dlng from "../../assets/svg/OilsGas_P2_S2_Dlng.svg";
import OilsGasP2S2PetroJet from "../../assets/images/OilsGas_P2_S2_PetroJet.jpg";
import LazyMedia from "../../lazyLoad/lazyLoad";

function OilsGasContent() {
  return (
    <>
      <div className="OilsGas_Container">
        <div className="OlisGas_FirstPart">
          <div className="OlisGas_P1_S1">
            <h1 className="OlisGas_P1_S1_H1">Oil & GAS</h1>
            <LazyMedia
              type="image"
              src={OilsGasP1S2}
              alt="OilsGasP1S2"
              className="OilsGasP1S2Mobile "
            />
            <p className="OlisGas_P1_S1_P">
              In today's competitive oil and gas market, companies must adapt by
              transforming their business models, processes, and IT operations.
              This transformation typically focuses on cost management and
              employee engagement for improved performance. However, without
              concurrent advancements in technology and business processes, the
              results of cost reduction efforts may be limited. Phoenix
              solutions offer oil and gas companies a way to provide safe,
              reliable, and cost-effective energy. Their solutions can optimize
              capital spending, increase return on assets, boost profitability,
              and support sustainable operations while prioritizing safety,
              talent development, and digital transformation.
            </p>
          </div>
          <div className="OlisGas_P1_S2">
            <LazyMedia
              type="image"
              src={OilsGasP1S2}
              alt="OilsGasP1S2"
              className="OilsGasP1S2Web "
            />
          </div>
        </div>
        <div className="OlisGas_SecondPart">
          <div className="OlisGas_P2_S1">
            <h1 className="OlisGas_P2_S1_H1">Our Partners of success</h1>
          </div>
          <div className="OlisGas_P2_S2">
            <div className="OlisGas_P2_S2_L">
              <LazyMedia
                type="image"
                src={OilsGasP2S2Egas}
                alt="OilsGasP2S2Egas"
                className="OilsGasP2S2Egas"
              />
              <LazyMedia
                type="image"
                src={OilsGasP2S2Socar}
                alt="OilsGasP2S2Socar"
              />
              <LazyMedia
                type="image"
                src={OilsGasP2S2Petro}
                alt="OilsGasP2S2Petro"
              />
              <LazyMedia
                type="image"
                src={OilsGasP2S2Adnoc}
                alt="OilsGasP2S2Adnoc"
              />
            </div>
            <div className="OlisGas_P2_S2_L">
              <LazyMedia
                type="image"
                src={OilsGasP2S2ORYX}
                alt="OilsGasP2S2ORYX"
              />
              <LazyMedia
                type="image"
                src={OilsGasP2S2Jocap}
                alt="OilsGasP2S2Jocap"
              />
              <LazyMedia
                type="image"
                src={OilsGasP2S2Dlng}
                alt="OilsGasP2S2Dlng"
              />
              <LazyMedia
                type="image"
                src={OilsGasP2S2PetroJet}
                alt="OilsGasP2S2PetroJet"
                className="OilsGasP2S2PetroJet"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OilsGasContent;
