import React from "react";
import "./Aws_partnerShip.css";
import { MainUrl } from "../../config";
import { ReactComponent as AwsBanner } from "../../assets/svg/sap_banner.svg";
import PartnersBanner from "../../assets/videos/Partners_Banner.MP4";

import AwsStar from "../../assets/svg/SapStar.svg";
import AwsPlus from "../../assets/svg/Sap_plus.svg";
import AwsSecondPartInfo from "../../components/hooks/AwsSecondPartInfo/AwsSecondPartInfo";
import AwsP3Transformation from "../../assets/images/Aws_P3_Transformation.png";
import AwsP3Migration from "../../assets/images/Aws_P3_Migration.png";
import AwsP3Cloud from "../../assets/images/Aws_P3_Cloud.png";
import AwsP4Partner from "../../assets/svg/Aws_P4_Partner.svg";
import Awsphoto from "../../assets/images/Aws_P4_Partner.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function AwsPartnerShipContent() {
  return (
    <>
      <div className="AwsPartner_container">
        {/* Part One */}
        <div className="Aws_FirstPart">
          <LazyMedia
            type="video"
            src={PartnersBanner}
            className="Partners_Aws_VideoBackground"
          />
          <LazyMedia
            type="image"
            src={AwsStar}
            alt="AwsStar"
            className="AwsStarMobile"
          />
          <div className="Aws_P1_P1">
            <h1 className="Aws_P1_P1_H1">Phoenix</h1>
            <LazyMedia type="image" src={AwsPlus} alt="AwsPlus" />
            <LazyMedia type="image" src={Awsphoto} alt="" className="AwsLogo" />
          </div>
          <h1 className="Aws_FirstPart_H1">Race to the Cloud Future</h1>
          <p className="Aws_FirstPart_P">
            Maintaining a competitive edge in the digital era is crucial, and
            that's where the innovative collaboration between Phoenix and Amazon
            Web Services (AWS) comes into play. As an AWS Advanced Tier Services
            Consulting Partner, Phoenix boasts extensive capabilities in vital
            business domains and in-depth expertise across a wide range of cloud
            solutions.
          </p>
        </div>

        {/* end Part One */}
        <div className="Aws_FourthPartContainer">
          {/* Part four Section one */}
          <div className="Aws_P4_S1">
            <LazyMedia type="image" src={Awsphoto} alt="Partner" />
          </div>
          {/* Part four Section two */}

          <div className="Aws_P4_S2">
            {/* Part four Section two element one */}
            <div className="Aws_P4_S2_E1">
              <h2>
                Accelerate your journey to Cloud Transformation with Phoenix
              </h2>
            </div>
            {/* Part four Section two element two have 2 child */}
            <div className="Aws_P4_S2_E2_Container">
              {/* list 1 */}
              <div className="Aws_P4_S2_E2_ListContainer">
                <ul className="Aws_P4_S2_E2_List">
                  <li>Expertise and Guidance</li>
                  <li>Customized Solutions</li>
                  <li>Faster Deployment</li>
                  <li>Cost Optimization</li>
                </ul>
              </div>
              {/* list 2 */}
              <div className="Aws_P4_S2_E2_ListContainer">
                <ul className="Aws_P4_S2_E2_List">
                  <li>Ongoing Support</li>
                  <li>Access to AWS Resources</li>
                  <li>Risk Mitigation</li>
                </ul>
              </div>
              <div className="Aws_P4_S2_E2_ListContainer">
                <ul className="Aws_P4_S2_E2_List">
                  <li>Scalability</li>
                  <li>Strategic Guidance</li>
                  <li>Value-Added Services</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="Aws_ColorSpace"></div>
        {/* Part Two */}
        <div className="Aws_SecondPart_container">
          <div className="Aws_SecondPart">
            <div className="Aws_P2_S1">
              <h1 className="Aws_P2_S1_H1">AWS Services</h1>
              <AwsSecondPartInfo />
            </div>
          </div>
        </div>
        {/* end Part Two */}
        {/* Part Three */}
        <div className="Aws_ThirdPart">
          <div className="Aws_P3_S1">
            <h1 className="Aws_P3_S1_H1">Phoenix's services</h1>
          </div>
          <div className="Aws_P3_S2">
            {/* element one */}
            <div className="Aws_P3_S2_Element">
              <LazyMedia
                type="image"
                src={AwsP3Transformation}
                alt="AwsP3Transformation"
                className="Aws_P3_S2_Element_Svg"
              />
              <h3 className="Aws_P3_S2_Element_H3">
                AWS Transformation Services
              </h3>
              <p className="Aws_P3_S2_Element_P">
                Phoenix can help you assess your current SAP landscape and
                develop a migration plan that meets your specific needs and
                budget.
              </p>
            </div>
            {/* element two */}
            <div className="Aws_P3_S2_Element">
              {" "}
              <LazyMedia
                type="image"
                src={AwsP3Migration}
                alt="AwsP3Transformation"
                className="Aws_P3_S2_Element_Svg"
              />
              <h3 className="Aws_P3_S2_Element_H3">SAP migration execution</h3>
              <p className="Aws_P3_S2_Element_P">
                Phoenix can help you migrate your SAP workloads to AWS with
                minimal disruption to your business.
              </p>
            </div>
            {/* element three */}
            <div className="Aws_P3_S2_Element">
              {" "}
              <LazyMedia
                type="image"
                src={AwsP3Cloud}
                alt="AwsP3Transformation"
                className="Aws_P3_S2_Element_Svg"
              />
              <h3 className="Aws_P3_S2_Element_H3">SAP managed services</h3>
              <p className="Aws_P3_S2_Element_P">
                Phoenix can provide ongoing support and management for your SAP
                workloads on AWS.
              </p>
            </div>
          </div>
          <div className="Aws_P3_S3">
            <p className="Aws_P3_S3_P">
              By partnering with Phoenix, you can benefit from the combined
              expertise of three leading companies in the SAP and cloud
              computing industry. Phoenix can help you achieve a successful SAP
              migration to AWS, and enable you to reap the benefits of a modern,
              digital, and intelligent enterprise.
            </p>
          </div>
          <div className="Aws_P3_S4">
            <a href={`${MainUrl}Contact-us`}>
              <button className="Aws_P3_S4_button">Start Your Journey</button>
            </a>
          </div>
        </div>
        {/* end Part three */}
        {/* Empty Space with background color*/}
        {/* Part four */}

        {/* end Part Four */}
        {/* Empty Space with background color*/}
        <div className="Aws_ColorSpaceEnd"></div>
      </div>
    </>
  );
}

export default AwsPartnerShipContent;
