import React, { useState } from "react";
import "./button.css";
import { ReactComponent as OpenInput } from "../../../assets/svg/plus.svg";

function ButtonWithInput({ onTextChange }) {
  const [showInput, setShowInput] = useState(false);
  const [inquiryText, setInquiryText] = useState("");
  const [text, setText] = useState("");

  const handleTextChange = (e) => {
    setText(e.target.value);
    onTextChange(e.target.value);
  };

  const handleButtonClick = () => {
    setShowInput(!showInput);
    if (!showInput) {
      setTimeout(() => document.getElementById("inquiryTextarea").focus(), 0);
    }
  };

  return (
    <div className="container_ButtonWithInput">
      <button
        onClick={handleButtonClick}
        className={`button_input_1 ${showInput ? "no-border" : ""}`}
        type="button"
      >
        Write your Inquiry (optional)
        <OpenInput />
      </button>
      {showInput && (
        <textarea
          id="inquiryTextarea"
          placeholder="Write something..."
          value={inquiryText}
          onChange={(e) => setInquiryText(e.target.value)}
          name="user_inquiry"
        ></textarea>
      )}
    </div>
  );
}

export default ButtonWithInput;
