import { React, useState, useEffect } from "react";
import styles from "./Real_Estate.module.css";
import SlideRealState from "../../components/hooks/SlideRealState/SlideRealState";
import MobileSlide from "../../components/hooks/SlideRealState/SlideMobileRealState";
const RealState = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 475); // Assuming 768 is your mobile breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.RealStateBigContainer}>
      {/* Section One Cover contain the image as background in realstate.css */}

      <div className={styles.SectionOneCover}>
        <div className={styles.coverContent}>
          <div>
            <h4 className={styles.coverContent_H4_Text}>
              Real Estate Solution
            </h4>
          </div>
          <div>
            <h1 className={styles.coverContent_H1_Text}>
              Streamlining Real Estate Management
            </h1>
          </div>
          <div>
            <p className={styles.coverContent_P_Text}>
              At Phoenix Consulting, we've combined our technological expertise
              with in-depth knowledge of the real estate industry to create a
              tailored in-house solution. Our Real Estate Solution offers a
              comprehensive system that addresses the industry's most common
              challenges.
            </p>
          </div>
        </div>
      </div>

      {/* SectionTwo */}
      <div className={styles.SectionTwo}>
        {/* our site section */}

        {/* oursite is rightSide part */}
        <div className={styles.SectionTwoContent}>
          <div>
            <h1>What Phoenix Real Estate Solution Offers</h1>
            <p>
              Elevating Real Estate Excellence: Discover the Comprehensive
              Capabilities of Phoenix Real Estate Solutions for Optimal Building
              Structure Management, Effortless Reservation Oversight, Contract
              Control Mastery, Meticulous Payment Management, Seamless Unit
              Release Administration, and Robust Reporting Insights.
            </p>
          </div>
        </div>
        {/* oursite is rightSide part */}

        {/* is a reusable Slide in leftside as Components */}
        <div className={styles.SectionTwoBoxes}>
          {isMobile ? (
            <MobileSlide
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
            />
          ) : (
            <SlideRealState
              currentSlide={currentSlide}
              setCurrentSlide={setCurrentSlide}
            />
          )}
        </div>
      </div>
      {/* slides using useState Hooks */}
    </div>
  );
};

export default RealState;
