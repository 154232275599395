import React from "react";
import "./SapRise.css";
import SapRiseACCFirst from "../../components/hooks/Sap_Rise_ACC1/SapRiseAccMain";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Rise_P4_S2 from "../../assets/svg/Rise_P4_S2.svg";
import Rise_P5_S1 from "../../assets/svg/Rise_P5_S1.svg";
import Rise_P6_BackGround from "../../assets/svg/Rise_P6_BackGround.svg";
import { MainUrl } from "../../config";
import SapRiseAccSecond from "../../components/hooks/Sap_Rise_ACC2/SapRiseAccPartTwoMain";
import SapBannerVideo from "../../assets/videos/SapBannerVideo.mp4";
import LazyMedia from "../../lazyLoad/lazyLoad";

function SapRiseContent() {
  return (
    <>
      <div className="SapRiseContainer">
        {/* First Part */}
        <div className="RiseFirstPart">
          <LazyMedia
            type="video"
            src={SapBannerVideo}
            className="Sap_VideoBackground"
          />
          <div className="Rise_P1_S1">
            <a href={`${MainUrl}sap-partnership`}>
              <h3 className="Rise_P1_S1_H3">SAP</h3>
            </a>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <a href={`${MainUrl}sap-rise`}>
              <h3 className="Rise_P1_S1_H3">RISE with SAP</h3>
            </a>
          </div>
          <div className="Rise_P1_S2">
            <h1 className="Rise_P1_H1">RISE with SAP</h1>
            <h3 className="Rise_P1_H3">
              Start making positive changes today for your organization
            </h3>
          </div>
        </div>
        {/* Second Part */}
        <div className="RiseSecondPart">
          <div className="Rise_P2_S1">
            <h1 className="Rise_P2_S1_H1">
              Elevate Your Business with RISE with SAP
            </h1>
          </div>
          <div className="Rise_P2_S2">
            <p className="Rise_P2_S2_P">
              Prepare to embark on the next phase of your digital evolution with
              RISE with SAP. This all-encompassing solution is your key to
              unlocking a world of opportunities and optimizing your operations.
            </p>
          </div>
          <div className="Rise_P2_S3">
            {/* box 1 */}
            <div className="Rise_P2_S3_Box">
              <h3 className="Rise_P2_S3_Box_H3">Streamlined Transformation</h3>
              <p className="Rise_P2_S3_Box_P">
                This solution simplifies your journey to becoming an intelligent
                enterprise. It combines business process intelligence, a
                resilient technology platform, and a versatile modular approach.
              </p>
            </div>
            {/* box 2 */}
            <div className="Rise_P2_S3_Box">
              <h3 className="Rise_P2_S3_Box_H3">Focus on Business Outcomes</h3>
              <p className="Rise_P2_S3_Box_P">
                Concentrate on achieving measurable business outcomes and make
                data-driven decisions, gaining insights into how your
                organization can benefit.
              </p>
            </div>
            {/* box 3 */}
            <div className="Rise_P2_S3_Box">
              <h3 className="Rise_P2_S3_Box_H3">End-to-End Capabilities</h3>
              <p className="Rise_P2_S3_Box_P">
                RISE with SAP offers extensive capabilities that cover all your
                business requirements, from business process intelligence to the
                SAP Business Technology Platform and SAP Business Network.
              </p>
            </div>
            {/* box 4 */}
            <div className="Rise_P2_S3_Box">
              <h3 className="Rise_P2_S3_Box_H3">Sustainable Growth</h3>
              <p className="Rise_P2_S3_Box_P">
                Set the stage for long-term, sustainable business expansion by
                harnessing the flexibility and scalability provided by the SAP
                Business Technology Platform.
              </p>
            </div>
          </div>
        </div>
        {/* Third Part */}
        <div className="RiseThirdPart">
          <div className="Rise_P3_S1">
            <h1 className="Rise_P3_S1_H1">
              RISE with SAP at Its Core (Is it Important point?)
            </h1>
          </div>
          <div className="Rise_P3_S2">
            <p className="Rise_P3_S2_P">
              Unlock the potential of RISE with SAP with its key components
            </p>
          </div>
          <div className="Rise_P3_S3">
            <SapRiseACCFirst />
          </div>
        </div>
        {/* Fourth Part */}
        <div className="RiseFourthPart">
          <div className="Rise_P4_S1">
            <h1 className="Rise_P4_S1_H1">Our Solutions for "Rise with SAP"</h1>

            <div>
              <LazyMedia
                type="image"
                src={Rise_P4_S2}
                alt="Rise_P4_S2 "
                className="Rise_P4_S2Mobile"
              />
              <p className="Rise_P4_S1_P">
                At Phoenix Consulting, We get how "Rise with SAP" works and can
                assist your organization to make the most of it.
              </p>
              <p className="Rise_P4_S1_P">
                Our team of experts specializes in providing seamless
                implementation and support services for businesses embarking on
                this transformative journey. We tailor "Rise with SAP" to your
                unique needs, ensuring a smooth transition and long-term
                success.
              </p>
              <p className="Rise_P4_S1_P">
                Your organization's digital transformation journey begins with
                "Rise with SAP." Let Phoenix Consulting guide you through this
                exciting process.
              </p>
            </div>
          </div>
          <div className="Rise_P4_S2">
            <LazyMedia
              type="image"
              src={Rise_P4_S2}
              alt="Rise_P4_S2 "
              className="Rise_P4_S2Web"
            />
          </div>
        </div>
        {/* Fifth Part */}
        <div className="RiseFifthPart">
          <div className="Rise_P5_S1">
            <LazyMedia
              type="image"
              src={Rise_P5_S1}
              alt="Rise_P5_S1"
              className="Rise_P5_S1Web"
            />
          </div>
          <div className="Rise_P5_S2">
            <h1 className="Rise_P5_S2_H1">
              Ignite Your Success with "Rise with SAP" on AWS
            </h1>
            <LazyMedia
              type="image"
              src={Rise_P5_S1}
              alt="Rise_P5_S1"
              className="Rise_P5_S1Mobile"
            />
            <div>
              <p className="Rise_P5_S2_P">
                At Phoenix Consulting, we understand that choice and flexibility
                are vital when it comes to your business's digital
                transformation.
              </p>
              <p className="Rise_P5_S2_P">
                This is why we've partnered with SAP and AWS to present you with
                "Rise with SAP" on AWS, offering a fully managed path to SAP
                S/4HANA that's tailored to your unique needs.
              </p>
            </div>
          </div>
        </div>
        {/* Sixth Part */}
        <div className="RiseSixthPart">
          <div className="Rise_P6_S1">
            <h1 className="Rise_P6_S1_H1">Why "Rise with SAP" on AWS</h1>
            <p className="Rise_P6_S1_P">
              "Rise with SAP" on AWS is the ultimate choice for businesses
              seeking a comprehensive and adaptable solutio
            </p>
            <h3 className="Rise_P6_S1_H3">
              Discover the freedom to choose how you want to implement "Rise
              with SAP"
            </h3>
          </div>
          <div className="Rise_P6_S2">
            <SapRiseAccSecond className="SapRiseAccSecond" />
          </div>
        </div>
        {/* Seventh Part */}
        <div className="RiseSeventhPart">
          <div className="Rise_P7_S1">
            <h1 className="Rise_P7_S1_H1">Discover Other SAP Solutions</h1>
          </div>
          <div className="Rise_P7_S2">
            {/* button 1 */}
            <a href={`${MainUrl}S/4HANA`}>
              <button className="Rise_P7_S2_button">
                <h3>SAP S/4HANA</h3>
                <KeyboardArrowRightIcon />
              </button>
            </a>
            {/* button 2 */}
            <a href={`${MainUrl}sap-ariba`}>
              <button className="Rise_P7_S2_button">
                <h3>SAP Ariba</h3>
                <KeyboardArrowRightIcon />
              </button>
            </a>
            {/* button 3 */}
            <a href={`${MainUrl}sap-SuccessFactors`}>
              <button className="Rise_P7_S2_button">
                <h3>SAP SuccessFactors</h3>
                <KeyboardArrowRightIcon />
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SapRiseContent;
