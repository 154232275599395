import React from "react";
import "./Sap_Ariba.css";
import { MainUrl } from "../../config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AribaP2S2 from "../../assets/images/Ariba_P2_S2.jpg";
import AribaP4S1 from "../../assets/images/Ariba_P4_S1.jpg";
import AribaP5S2 from "../../assets/images/Ariba_P5_S2.jpg";
import SapBannerVideo from "../../assets/videos/SapBannerVideo.mp4";
import LazyMedia from "../../lazyLoad/lazyLoad";

function SapAribaContent() {
  return (
    <>
      <div className="SapAribaContainer">
        {/* First Part */}
        <div className="AribaFirstPart">
          <LazyMedia
            type="video"
            src={SapBannerVideo}
            className="Sap_VideoBackground"
          />
          <div className="Ariba_P1_S1">
            <a href={`${MainUrl}sap-partnership`}>
              <h3 className="Ariba_P1_S1_H3">SAP</h3>
            </a>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <a href={`${MainUrl}sap-ariba`}>
              <h3 className="Ariba_P1_S1_H3">SAP Ariba</h3>
            </a>
          </div>
          <div className="Ariba_P1_S2">
            <h1 className="Ariba_P1_H1">SAP Ariba</h1>
            <h3 className="Ariba_P1_H3">
              Stay ahead of evolving market dynamics with our smart
              source-to-pay solutions
            </h3>
          </div>
        </div>
        {/* Second Part */}
        <div className="AribaSecondPart">
          <div className="Ariba_P2_S1">
            <h1 className="Ariba_P2_S1_H1">
              Streamlining Procurement Excellence
            </h1>
            <LazyMedia
              type="image"
              src={AribaP2S2}
              alt="AribaP2S2"
              className="AribaP2S2_Mobile"
            />
            <p className="Ariba_P2_S1_P">
              Phoenix Consulting knows that efficient procurement processes are
              essential for your business's success, We use advanced procurement
              technology like SAP Ariba to make your procurement processes
              smoother and more efficient.
            </p>
          </div>
          <div className="Ariba_P2_S2">
            <LazyMedia
              type="image"
              src={AribaP2S2}
              alt="AribaP2S2"
              className="AribaP2S2_Web"
            />
          </div>
        </div>
        {/* Third Part */}
        <div className="AribaThirdPart">
          <div className="Ariba_P3_S1">
            <h1 className="Ariba_P3_S1_H1">Why Choose SAP Ariba?</h1>
          </div>
          <div className="Ariba_P3_S2">
            <p className="Ariba_P3_S2_P">
              SAP Ariba offers a comprehensive solution to enhance and simplify
              your procurement operations. Here's why you should opt for this
              powerful platform:
            </p>
          </div>
          <div className="Ariba_P3_S3">
            {/* box 1 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">Efficient Procurement</h3>
              <p className="Ariba_P3_S3_Box_P">
                SAP Ariba streamlines and automates the procurement process,
                enabling you to effectively manage your suppliers and bolster
                collaboration.
              </p>
            </div>
            {/* box 2 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">
                Accelerated Procurement Cycles
              </h3>
              <p className="Ariba_P3_S3_Box_P">
                By enhancing the efficiency of your procurement operations, SAP
                Ariba contributes to cost reduction and improved supplier
                relationships.
              </p>
            </div>
            {/* box 3 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">Informed Decision-Making</h3>
              <p className="Ariba_P3_S3_Box_P">
                With its user-friendly interface and robust capabilities, SAP
                Ariba empowers your business to make data-driven decisions,
                thereby reducing risks and optimizing your complete
                source-to-pay process.
              </p>
            </div>
            {/* box 4 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">Transparency in Spending</h3>
              <p className="Ariba_P3_S3_Box_P">
                Gain insights into your spending patterns and ensure compliance
                with procurement policies through enhanced supplier
                collaboration.
              </p>
            </div>
            {/* box 5 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">Seamless Integration</h3>
              <p className="Ariba_P3_S3_Box_P">
                SAP Ariba's flexibility allows it to effortlessly integrate with
                your existing SAP or non-SAP information systems, extending your
                procurement management capabilities.
              </p>
            </div>
            {/* box 6 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">Extensive Business Network</h3>
              <p className="Ariba_P3_S3_Box_P">
                Modernize your business interactions by engaging in
                collaborative commerce not only with current partners but also
                with millions of potential suppliers across the globe.
              </p>
            </div>
            {/* box 7 */}
            <div className="Ariba_P3_S3_Box">
              <h3 className="Ariba_P3_S3_Box_H3">Future-Ready Scalability</h3>
              <p className="Ariba_P3_S3_Box_P">
                With a forward-focused platform, SAP Ariba ensures your business
                remains adaptable and ready to scale as it grows.
              </p>
            </div>
          </div>
        </div>
        {/* Fourth Part */}
        <div className="AribaFourthPart">
          <div className="Ariba_P4_S1">
            <LazyMedia
              type="image"
              src={AribaP4S1}
              alt="AribaP4S1"
              className="AribaP4S1_Web"
            />
          </div>
          <div className="Ariba_P4_S2">
            <h1 className="Ariba_P4_S2_H1">
              How Phoenix Consulting Supports Your SAP Ariba Journey
            </h1>
            <LazyMedia
              type="image"
              src={AribaP4S1}
              alt="AribaP4S1"
              className="AribaP4S1_Mobile"
            />

            <p className="Ariba_P4_S2_P">
              As your trusted partner, Phoenix Consulting offers expert guidance
              and implementation services to ensure the seamless and efficient
              integration of SAP Ariba into your business operations.
            </p>
          </div>
        </div>
        {/* Fifth Part */}
        <div className="AribaFifthPart">
          <div className="Ariba_P5_S1">
            <h1 className="Ariba_P5_S1_H1">
              Are You Ready to Elevate Your Procurement Performance?
            </h1>
            <LazyMedia
              type="image"
              src={AribaP5S2}
              alt="AribaP5S2"
              className="AribaP5S2Mobile"
            />
            <p className="Ariba_P5_S1_P">
              With the combined expertise of Phoenix Consulting and the advanced
              capabilities of SAP Ariba, you can take your procurement to new
              heights, Contact us today to unlock the full potential of your
              procurement operations.
            </p>
            <a href={`${MainUrl}contact-us`}>
              <button className="Ariba_P5_S1_Button">Contact us</button>
            </a>
          </div>
          <div className="Ariba_P5_S2">
            <LazyMedia
              type="image"
              src={AribaP5S2}
              alt="AribaP5S2"
              className="AribaP5S2Web"
            />
          </div>
        </div>
        {/* Sixth Part */}
        <div className="AribaSixthPart">
          <div className="Ariba_P6_S1">
            <h1 className="Ariba_P6_S1_H1">Discover Other SAP Solutions</h1>
          </div>
          <div className="Ariba_P6_S2">
            {/* button 1 */}
            <a href={`${MainUrl}S/4hana`}>
              <button className="Ariba_P6_S2_button">
                <h3>SAP S/4HANA</h3>
                <KeyboardArrowRightIcon />
              </button>
            </a>
            {/* button 2 */}
            <a href={`${MainUrl}sap-rise`}>
              <button className="Ariba_P6_S2_button">
                <h3>Rise With SAP</h3>
                <KeyboardArrowRightIcon />
              </button>
            </a>
            {/* button 3 */}
            <a href={`${MainUrl}sap-SuccessFactors`}>
              <button className="Ariba_P6_S2_button">
                <h3>SAP SuccessFactors</h3>
                <KeyboardArrowRightIcon />
              </button>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default SapAribaContent;
