import React from "react";
import "./caseStudyArabDevelopers.css";
import { MainUrl } from "../../config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SapLogo from "../../assets/svg/Sap_SapBlue.svg";
import AwsLogo from "../../assets/images/Aws_Logo_For_Case.jpg";
import MainLogo from "../../assets/svg/Logo.svg";
import caseStudiesADH from "../../assets/images/caseStudiesADH.png";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2Two from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import ArabDevelopers from "../../assets/images/ADH-logo.png";
import AmerGroup from "../../assets/images/amer.png";
import LazyMedia from "../../lazyLoad/lazyLoad";
import Damietta from "../../assets/images/damietta.png";

function CaseStudyArabDevelopersContent() {
  return (
    <>
      <div className="caseStudyEGICContainer">
        {/* First Part */}
        <div className="CaseStudyEGICFirstPart">
          <div className="EGIC_P1_S1">
            <a href={`${MainUrl}case-Studies`}>
              <h3 className="EGIC_P1_S1_H3">Case Studies</h3>
            </a>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <a href={`${MainUrl}ADH`}>
              <h3 className="EGIC_P1_S1_H3">Arab Developers Holding</h3>
            </a>
          </div>
          <div className="EGIC_P1_S2_Container">
            <div className="EGIC_P1_S2">
              <h1 className="EGIC_P1_H1">Arab Developers Holding</h1>

              <h3 className="EGIC_P1_H3">
                Holding’s diverse portfolio of projects provides innovative
                lifestyle solutions and high-end services to offer unmatched
                real estate investment options in booming markets worldwide from
                mansions, villas, malls, Golf courses, hotels, serviced
                apartments, and much more for modern living.
              </h3>
            </div>
          </div>
        </div>
        {/* Second Part */}
        {/* Second Part */}
        <div className="EGIC_P2_S1">
          <div>
            <LazyMedia
              type="image"
              src={ArabDevelopers}
              alt="casestudiesXP2Two"
              className="casestudiesXP2OneWebDamietta Damietta"
            />
          </div>
          <div className="EGIC_P2_S1_Container">
            <h1 className="EGIC_P2_S1_H1">
              Capturing the untapped potential and delivering healthy returns
              for clients and investors is what drives “Arab Developers Holding”
              to reform and expand the real estate industry in Egypt, and the
              MENA region, all while adopting modern approaches with an
              unwavering commitment to design excellence.
            </h1>
            <LazyMedia
              type="image"
              src={ArabDevelopers}
              alt="casestudiesXP2Two"
              className="casestudiesXP2OneMobileDamietta Damietta"
            />
          </div>
        </div>
        {/* third part */}
        <div className="CaseStudies_FifthPart">
          <div className="CaseStudies_P5_Content1">
            {/* first part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text_Damietta">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">
                    About Arab Developers Holding :
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">
                    Capturing the untapped potential and delivering healthy
                    returns for clients and investors is what drives “Arab
                    Developers Holding” to reform and expand the real estate
                    industry in Egypt, and the MENA region, all while adopting
                    modern approaches with an unwavering commitment to design
                    excellence.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    With a fast-growing track record of fully integrated
                    destinations, Arab Developers Holding has become one of the
                    region’s leading real estate development companies. For 16
                    years, Arab Developers Holding has played an essential role
                    in reshaping the real estate landscape in unexpected ways by
                    introducing a collection of upscale properties catering to
                    high-end buyers with cutting-edge features that enhance
                    buyers living experience and day-to-day lives.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Arab Developers Holding’s diverse portfolio of projects
                    provides innovative lifestyle solutions and high-end
                    services to offer unmatched real estate investment options
                    in booming markets worldwide from mansions, villas, malls,
                    Golf courses, hotels, serviced apartments, and much more for
                    modern living.
                  </p>
                </div>
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">Executive Summary:</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">
                    Customer requires professional hyperscaler supporting
                    Digital Transformation journey and refactoring their SAP
                    workloads, Phoenix worked to design the Transformation
                    Roadmap utilizing AWS and SAP Technologies to manage the
                    workload servers from on-premises landscape to AWS, using
                    the Generative AI to extend AD Holding market share in Egypt
                    and across Middle East region and ensuring the systems
                    seamless integrity.
                  </p>
                </div>
                {/* logo at the end of paper */}
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={AwsLogo}
                    alt="SapLogo"
                    className="CaseAwsLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              {/* image right */}
              {/* <div className="CaseStudies_P5_C1_Img">
                <LazyMedia
                  type="image"
                  src={CaseStudies_P3_S1}
                  alt=""
                  className="CaseStudies_P5_C1_ImgWeb DamiettaHide"
                />
              </div> */}
            </div>
            {/* second part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">
                    Before: Challenges and Opportunities
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P5_C1_Text_Ul">
                  {/* point list one */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    {/* <h4>Before: Challenges and Opportunities</h4> */}
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>
                        Implemented 3rd party Real Estate Addon on their SAP
                        system, with no available guides or information about
                        its technology.
                      </li>
                      <li>
                        Migrate from Oracle database on Cloud to SAP HANA on
                        AWS.
                      </li>
                      <li>
                        High-available and durable backup storage solution.
                      </li>
                      <li>Stable servers’ backups plans and policies.</li>
                      <li>Lower latency and servers’ stability.</li>
                      <li>
                        Automate business processes to increase efficiency and
                        lower costs.
                      </li>
                      <li>
                        Optimize the current system Database and enhance
                        performance.
                      </li>
                      <li>Enhancing the Security of the cloud environment.</li>
                      <li>Seamless integrity with other systems</li>
                      <li>Automate Marketing Analysis for business growth.</li>
                    </ul>
                  </div>
                  {/* point list two */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Target Architectural Diagram </h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>
                        Provisioning AWS customer VPC focusing on cost
                        optimization, stable connections from on-premises sites
                        and securing the network.
                      </li>
                      <li>
                        Refactoring Oracle databases landscape to SAP HANA
                        database on AWS with the right sizing architecture.
                      </li>
                      <li>
                        Re-engineering for Real Estate addon to migrate and
                        update into the migrated SAP landscape on AWS.
                      </li>
                      <li>
                        Migrating SAP Cloud Native Environment from cloud
                        environment to AWS using AWS MGN and S3 CLI.
                      </li>
                      <li>
                        Launching and Configuring FortiGate Firewall to Secure
                        the environment and to connect the AWS VPC to Customer
                        on-premises sites using EC2 and AWS Marketplace.
                      </li>
                      <li>
                        Hosting SAP Router server as a backup solution for the
                        VPN connections.
                      </li>
                      <li>
                        Creating Amazon S3 bucket for storing backups and using
                        AWS Backint agent to manage all SAP HANA backups.
                      </li>
                      <li>
                        Building DMS server from scratch in AWS Cloud and
                        migrating data using AWS stable VPN connection.
                      </li>
                      <li>
                        Managing solid backup plans and CloudWatch alarms for
                        specific workload thresholds.
                      </li>
                    </ul>
                  </div>
                  {/* point list three */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>After: Value-Driven Results</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>
                        Enhanced business efficiency, visibility, and
                        productivity while automating reporting and compliance.
                      </li>
                      <li>
                        Significant performance improvement with refactoring to
                        SAP HANA.
                      </li>
                      <li>
                        Reducing SAP backup time with a reduction percentage of
                        90%.
                      </li>
                      <li>
                        Enhanced data durability for SAP backups to 11 9's
                        Durability by using AWS S3.
                      </li>
                      <li>Reduced maintenance and total cost of ownership.</li>
                      <li>
                        Enabled the reinvestment of its server upgrade and
                        maintenance budget to the business.
                      </li>
                      <li>
                        Readied the business for the future with scalable and
                        flexible technology that allows agile operations based
                        on data-driven decisions.
                      </li>
                      <li>
                        Improved backup plans and policies to save costs and
                        minimize RPO.
                      </li>
                      <li>
                        Seamless experience with alarms and notification for
                        specific metrics.
                      </li>
                      <li>
                        Utilizing AWS & SAP Generative AI tools for Marketing
                        Analysis Automation.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={AwsLogo}
                    alt="SapLogo"
                    className="CaseAwsLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              <div className="CaseStudies_P5_C1_Right">
                <LazyMedia
                  type="image"
                  src={caseStudiesADH}
                  alt="caseStudiesP5"
                  className="CaseStudies_P5_C1_Right_ImgWeb"
                />
              </div>
            </div>
          </div>
        </div>
        {/* third Part */}
        <div className="CaseStudiesMain_P2_S2">
          {/* box 1 */}
          <a href={`${MainUrl}HSA`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">HSA GROUP</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Building business resilience in challenging times on advanced
                  ERA in the cloud.
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2One}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 2 */}
          <a href={`${MainUrl}EGIC`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">EGIC</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  EGIC was established to introduce PP-R systems for the water
                  supply in the Egyptian market
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2Two}
                  alt="casestudiesXP2Two"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 3 */}
          <a
            href={`${MainUrl}Pharmaoverseas`}
            className="CaseStudiesX_P2_Box_S3_A"
          >
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Pharmaoverseas</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Pharmaoverseas suppliers, governmental bodies, society, and
                  customers
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2Three}
                  alt="casestudiesXP2Three"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
        </div>
        {/* box 4 */}
        <div className="CaseStudiesMain_P2_S2">
          <a href={`${MainUrl}AmerGroup`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Amer Group</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Amer Group is a leading real estate developer that offers
                  mixed-use, family-oriented destinations.
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={AmerGroup}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One AmerGroup"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 5 */}
          <a href={`${MainUrl}Damietta`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Damietta Alliance</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  The Port of Damietta is one of the main ports in Egypt, close
                  to the River Nile
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={Damietta}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One AmerGroup"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
        </div>
        <div className="None"></div>
      </div>
    </>
  );
}

export default CaseStudyArabDevelopersContent;
