import React, { useState, useEffect, useRef } from "react";
import "./HomePartOneSlider.css";
import { MainUrl } from "../../../config";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ReactComponent as SapPointSliderActive } from "../../../assets/svg/Sap_PointSliderActive.svg";
import { ReactComponent as SapPointSliderDeActive } from "../../../assets/svg/Home_P1_Slide_button.svg";
import videohome from "../../../assets/videos/HomeP1Slider1.mp4";
import LazyMedia from "../../../lazyLoad/lazyLoad";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

const HomeSlideOne = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 475);
  const [shouldUseImage, setShouldUseImage] = useState(false);
  const intervalRef = useRef(null);
  const videoParentRef = useRef();

  const updateMedia = () => {
    setIsSmallScreen(window.innerWidth <= 475);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  }, []);

  useEffect(() => {
    if (isSafari() && videoParentRef.current) {
      const player = videoParentRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsInline = true;
        player.muted = true;
        player.setAttribute("muted", "");
        player.autoplay = true;

        setTimeout(() => {
          const promise = player.play();
          if (promise && promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoParentRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, [isSmallScreen, currentSlide]);

  const slides = [
    {
      name: "INNOVATE",
      quote:
        "Your way to success with our forward-thinking technologies begins with innovative solutions tailored to your needs.",
      button: "our industries",
      path: "oils-gas",
      mobileBackground: { type: "video", src: videohome },
      desktopBackground: { type: "video", src: videohome },
    },
    {
      name: "Transform",
      quote:
        "Unleash the power of transformation and embrace the digital age with confidence as you redefine your business strategies.",
      button: "our Products",
      path: "Tender",
      mobileBackground: { type: "video", src: videohome },
      desktopBackground: { type: "video", src: videohome },
    },
    {
      name: "IMPLEMENT",
      quote:
        "Unlock the potential of our cutting-edge software solution and gain a competitive edge in your industry.",
      button: "our services",
      path: "devops",
      mobileBackground: { type: "video", src: videohome },
      desktopBackground: { type: "video", src: videohome },
    },
  ];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
    resetInterval();
  };

  const handlePreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
    resetInterval();
  };

  let initialTouchX = null;

  const handleTouchStart = (e) => {
    initialTouchX = e.touches[0].clientX;
  };

  const handleTouchMove = (e) => {
    if (initialTouchX === null) {
      return;
    }
    const currentTouchX = e.touches[0].clientX;
    const deltaX = initialTouchX - currentTouchX;
    const swipeThreshold = window.innerWidth / 50;

    if (deltaX > swipeThreshold) {
      handleNextSlide();
    } else if (deltaX < -swipeThreshold) {
      handlePreviousSlide();
    }

    initialTouchX = null;
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      handleNextSlide();
    }, 5900);
  };

  useEffect(() => {
    resetInterval();
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const { mobileBackground, desktopBackground } = slides[currentSlide];
  const background = isSmallScreen ? mobileBackground : desktopBackground;

  let BackgroundElement;
  if (background.type === "image") {
    BackgroundElement = (
      <LazyMedia
        type="video"
        src={background.src}
        className="slider-background"
      />
    );
  } else if (background.type === "video") {
    BackgroundElement = (
      <LazyMedia
        type="video"
        src={background.src}
        className="slider-background"
      />
    );
  }

  return (
    <>
      <div className="slider-background-container" ref={videoParentRef}>
        {BackgroundElement}
      </div>
      <div
        className="Home_PartOne_slider"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
      >
        <div className="Home_P1_S1">
          <div className="Home_P1_S1_Container">
            <div className="Home_P1_S1_Card_First">
              <h1 className="Home_P1_S1_H1">{slides[currentSlide].name}</h1>
              <p className="Home_P1_S1_Card_P">{slides[currentSlide].quote}</p>
            </div>
            <div className="Home_P1_S1_Card_Third">
              {slides.map((_, index) =>
                index === currentSlide ? (
                  <SapPointSliderDeActive key={index} />
                ) : (
                  <SapPointSliderActive key={index} />
                )
              )}
            </div>
          </div>
        </div>
        <div className="ArrowForwardIosIconCont">
          <ArrowForwardIosIcon
            fontSize="large"
            onClick={handleNextSlide}
            className="ArrowForwardIosIcon"
          />
        </div>
      </div>
    </>
  );
};

export default HomeSlideOne;
