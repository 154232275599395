import React from "react";
import "./Clients.css";
import { MainUrl } from "../../config";
import Clients_P1_S2 from "../../assets/images/Clients_P1_S2.jpg";
import ClientsPartEightSlider from "../../components/hooks/ClientsSlides/ClientsSlider";
import LazyMedia from "../../lazyLoad/lazyLoad";

function ClientsContent() {
  return (
    <>
      <div className="Clients_Container">
        <div className="Clients_FirstPart">
          <div className="Clients_P1_S1">
            <div className="Clients_P1_S1_Container">
              <h1 className="Clients_P1_S1_H1">CLIENTS</h1>
              <LazyMedia
                type="image"
                src={Clients_P1_S2}
                alt="Clients_P1_S2"
                className="Clients_P1_S2_Hide"
              />
              <p className="Clients_P1_S1_P">
                Partner with someone who has a deep understanding of your
                challenges and needs to empower your business. With our growing
                clientele, we continuously evolve, propelled by their feedback,
                to surpass today's excellence
              </p>
            </div>
            <a href={`${MainUrl}Contact-us`}>
              <button className="Clients_P1_S1_Button">
                Start Your Journey
              </button>
            </a>
          </div>
          <div className="Clients_P1_S2">
            <LazyMedia
              type="image"
              src={Clients_P1_S2}
              alt="Clients_P1_S2"
              className="Clients_P1_S2_Img"
            />
          </div>
        </div>
        <div>
          <div className="Clients_SecondPart">
            <div className="Clients_P2_S1">
              <h1 className="Clients_P2_S1_H1">We build solutions for</h1>
            </div>
            <ClientsPartEightSlider />
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientsContent;
