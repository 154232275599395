import React from "react";
import "./caseStudy_Damietta.css";
import { MainUrl } from "../../config";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import SapLogo from "../../assets/svg/Sap_SapBlue.svg";
import AwsLogo from "../../assets/images/Aws_Logo_For_Case.jpg";
import MainLogo from "../../assets/svg/Logo.svg";
import caseStudiesP5Damietta from "../../assets/images/Damietta_PDF.png";
import casestudiesXP2One from "../../assets/svg/CaseStudiesX_P2_ImgOne.svg";
import casestudiesXP2Two from "../../assets/svg/CaseStudiesX_P2_ImgTwo.svg";
import casestudiesXP2Three from "../../assets/svg/casestudiesXP2Three.svg";
import casestudiesXP2Arrow from "../../assets/svg/CaseStudiesX_P2_Arrow.svg";
import Damietta from "../../assets/images/damietta.png";
import AmerGroup from "../../assets/images/amer.png";
import ADH from "../../assets/images/ADH-logo.png";
import LazyMedia from "../../lazyLoad/lazyLoad";

function CaseStudyDamiettaContent() {
  return (
    <>
      <div className="caseStudyEGICContainer">
        {/* First Part */}
        <div className="CaseStudyEGICFirstPart">
          <div className="EGIC_P1_S1">
            <a href={`${MainUrl}case-Studies`}>
              <h3 className="EGIC_P1_S1_H3">Case Studies</h3>
            </a>
            <KeyboardArrowLeftIcon className="KeyboardArrowLeftIcon" />
            <a href={`${MainUrl}EGIC`}>
              <h3 className="EGIC_P1_S1_H3">Damietta</h3>
            </a>
          </div>
          <div className="EGIC_P1_S2_Container">
            <div className="EGIC_P1_S2">
              <h1 className="EGIC_P1_H1">DAMIETTA ALLIANCE</h1>

              <h3 className="EGIC_P1_H3">
                The Port of Damietta is one of the main ports in Egypt, close to
                the River Nile and with minimal deviation from major shipping
                routes and the Suez Canal.
              </h3>
            </div>
          </div>
        </div>
        {/* Second Part */}
        {/* Second Part */}
        <div className="EGIC_P2_S1">
          <div>
            <LazyMedia
              type="image"
              src={Damietta}
              alt="casestudiesXP2Two"
              className="casestudiesXP2OneWebDamietta Damietta"
            />
          </div>
          <div className="EGIC_P2_S1_Container">
            <h1 className="EGIC_P2_S1_H1">
              The consortium, Damietta Alliance Container Terminals, comprising
              of Hapag-Lloyd AG, Germany, as anchor customer, and EUROGATE
              Group, Germany and Contship Italia, Italy as operators will start
              operations of this state-of-the-art container terminal in early
              2025.
            </h1>
            <LazyMedia
              type="image"
              src={Damietta}
              alt="casestudiesXP2Two"
              className="casestudiesXP2OneMobileDamietta Damietta"
            />
          </div>
        </div>
        {/* third part */}
        <div className="CaseStudies_FifthPart">
          <div className="CaseStudies_P5_Content1">
            {/* first part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text_Damietta">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">
                    About Damietta Alliance
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">
                    The Port of Damietta is one of the main ports in Egypt,
                    close to the River Nile and with minimal deviation from
                    major shipping routes and the Suez Canal.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    The consortium, Damietta Alliance Container Terminals,
                    comprising of Hapag-Lloyd AG, Germany, as anchor customer,
                    and EUROGATE Group, Germany and Contship Italia, Italy as
                    operators will start operations of this state-of-the-art
                    container terminal in early 2025.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Damietta Alliance Container Terminals will be able to handle
                    the latest generation of container vessels. Ship-to-shore
                    gantry cranes with 25 rows across deck, deep water berths
                    with -18m CD and high-stacking yard equipment combined with
                    highly efficient IT systems will make this container
                    terminal a new hub in the Eastern Mediterranean Sea.
                  </p>
                </div>
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">Executive Summary:</h3>
                </div>
                <div className="spaceLine"></div>
                <div>
                  <p className="CaseStudies_P5_C1_P">
                    Customer will implement SAP RISE on AWS and require to
                    connect all their sites (Site to Site) VPN and this require
                    to have AWS VPC Connectivity between customer sites and VPC
                    then connect AWS VPC to RISE tenant Already did the presales
                    activity and aligned with AWS sales and Architect team.
                    Current stage; waiting to approve opportunity and sign the
                    order with the customer.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    1- SAP S4HANA RISE private green field implementation
                    opportunity
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    2- The customer is just established entity at Egypt and
                    requires to implement SAP ERP to manage his daily
                    operations.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    3- As SAP partner and AWS partner, Phoenix worked for last 3
                    months convenience the customer to implement SAP S4HANA
                    system and deploy over AWS cloud.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    4- Customer decided to go with SAP RISE private cloud and
                    convince them to deploy the SAP rise on AWS cloud showing
                    the feature of SAP deployments on AWS hyperscale
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    5- Phoenix team worked to implement SAP S4HANA solution on
                    SAP RISE on AWS.
                  </p>
                  <p className="CaseStudies_P5_C1_P">
                    Customer wants to host their WordPress website on Amazon
                    Lightsail. Phoenix will work on provisioning the instance,
                    including networking and security needed from customer
                    perspective and meet best practices. Phoenix have done
                    presales activities with the customer to identify the
                    architectural sizing (Amazon Lightsail plan and application
                    version), also TCO of the project.
                  </p>
                </div>
                {/* logo at the end of paper */}
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={AwsLogo}
                    alt="SapLogo"
                    className="CaseAwsLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              {/* image right */}
              {/* <div className="CaseStudies_P5_C1_Img">
                <LazyMedia
                  type="image"
                  src={CaseStudies_P3_S1}
                  alt=""
                  className="CaseStudies_P5_C1_ImgWeb DamiettaHide"
                />
              </div> */}
            </div>
            {/* second part */}
            <div className="Case_P5_C1_Container">
              <div className="Case_P5_C1_Text">
                <div>
                  <h3 className="CaseStudies_P5_C1_H3">
                    Before: Challenges and Opportunities
                  </h3>
                </div>
                <div className="spaceLine"></div>
                <div className="Case_P5_C1_Text_Ul">
                  {/* point list one */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Before: Challenges and Opportunities</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li> Hosting Scalable and highly available website.</li>
                      <li> Backup solution to connect to SAP-RISE</li>
                      <li>
                        Automate business processes to increase efficiencies and
                        lower costs
                      </li>
                      <li>
                        Optimize the current system Database and enhance
                        performance.
                      </li>
                      <li> Enhancing the Security in the environment</li>
                    </ul>
                  </div>
                  {/* point list two */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>Target Architectural Diagram </h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>
                        Provisioning AWS customer VPC focusing on cost
                        optimization, optimal sizing, and securing the
                        infrastructure.
                      </li>
                      <li>
                        Selecting m6i EC2 instance family to host SAP Router
                        server as being a certified SAP application instance
                        type for general purpose.
                      </li>
                      <li>
                        Provision Network Load Balancer “NLB” to be the internet
                        facing for the SAP Router server.
                      </li>
                      <li>
                        Connecting to SAP RISE AWS VPC through Peering
                        Connection that ensures availability and scalability and
                        route traffic to it using Routing Table.
                      </li>
                      <li>
                        Domain transfer to AWS in customer Account using
                        Route53.
                      </li>
                      <li>
                        Hosting Customer website using AWS LightSail and secure
                        the website by generating SSL Certificate.
                      </li>
                    </ul>
                  </div>
                  {/* point list three */}
                  <div className="Case_P5_C1_Text_Ul_Main">
                    <h4>After: Value-Driven Results</h4>
                    <ul className="Case_P5_C1_Text_Ul_Container">
                      <li>99.99 % Highly Available Website.</li>
                      <li>
                        Enhanced business efficiency, visibility, and
                        productivity while automating reporting and compliance
                      </li>
                      <li>Reduced maintenance and total cost of ownership</li>
                      <li>
                        Enabled the reinvestment of its server upgrade and
                        maintenance budget to the business
                      </li>
                      <li>
                        Readied the business for the future with scalable and
                        flexible technology that allows agile operations based
                        on data-driven decisions
                      </li>
                      <li>
                        Enhanced DNS performance taking the advantage of Route53
                        Features.
                      </li>
                      <li>
                        Improvement in SAPRISE Connection using SAP Router.
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="CaseStudies_P5_C1_Logo">
                  <LazyMedia
                    type="image"
                    src={MainLogo}
                    alt="MainLogo"
                    className="CaseMainLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={AwsLogo}
                    alt="SapLogo"
                    className="CaseAwsLogo"
                  />
                  <LazyMedia
                    type="image"
                    src={SapLogo}
                    alt="SapLogo"
                    className="CaseSapLogo"
                  />
                </div>
              </div>
              <div className="CaseStudies_P5_C1_Right">
                <LazyMedia
                  type="image"
                  src={caseStudiesP5Damietta}
                  alt="caseStudiesP5"
                  className="CaseStudies_P5_C1_Right_ImgWeb"
                />
              </div>
            </div>
          </div>
        </div>
        {/* third Part */}
        <div className="CaseStudiesMain_P2_S2">
          {/* box 1 */}
          <a href={`${MainUrl}HSA`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">HSA GROUP</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Building business resilience in challenging times on advanced
                  ERA in the cloud.
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2One}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 2 */}
          <a href={`${MainUrl}EGIC`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">EGIC</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  EGIC was established to introduce PP-R systems for the water
                  supply in the Egyptian market
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2Two}
                  alt="casestudiesXP2Two"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 3 */}
          <a
            href={`${MainUrl}Pharmaoverseas`}
            className="CaseStudiesX_P2_Box_S3_A"
          >
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Pharmaoverseas</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Pharmaoverseas suppliers, governmental bodies, society, and
                  customers
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={casestudiesXP2Three}
                  alt="casestudiesXP2Three"
                  className="casestudiesXP2One"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
        </div>
        <div className="CaseStudiesMain_P2_S2">
          {/* box 4 */}
          <a href={`${MainUrl}AmerGroup`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3">Amer Group</h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Amer Group is a leading real estate developer that offers
                  mixed-use, family-oriented destinations.
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={AmerGroup}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One AmerGroup"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
          {/* box 5 */}
          <a href={`${MainUrl}ADH`} className="CaseStudiesX_P2_Box_S3_A">
            <div className="CaseStudiesX_P2_Box">
              <div className="CaseStudiesX_P2_Box_S1">
                <h3 className="CaseStudiesX_P2_Box_S1_H3 SLine">
                  Arab Developers Holding
                </h3>
                <p className="CaseStudiesX_P2_Box_S1_P">
                  Capturing the untapped potential and delivering healthy
                  returns for clients
                </p>
              </div>
              <div className="CaseStudiesX_P2_Box_S2">
                <LazyMedia
                  type="image"
                  src={ADH}
                  alt="casestudiesXP2One"
                  className="casestudiesXP2One Damietta"
                />
              </div>
              <div className="CaseStudiesX_P2_Box_S3">
                <button className="CaseStudiesX_P2_Box_S3_Button">
                  <p className="CaseStudiesX_P2_Box_S3_Button_P">
                    Read full story
                  </p>
                  <LazyMedia
                    type="image"
                    src={casestudiesXP2Arrow}
                    alt="casestudiesXP2Arrow"
                    className="casestudiesXP2Arrow"
                  />
                </button>
              </div>
            </div>
          </a>
        </div>
        <div className="None"></div>
      </div>
    </>
  );
}

export default CaseStudyDamiettaContent;
