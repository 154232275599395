import React from "react";
import "./industriesSubmenuMobile.css";
import { ReactComponent as OilGas } from "../../../assets/svg/oil&gas.svg";
import { ReactComponent as Manufacturing } from "../../../assets/svg/Manufacturing.svg";
import { ReactComponent as Government } from "../../../assets/svg/Government.svg";

import { MainUrl } from "../../../config";

function IndustriesSubmenuMobile() {
  return (
    <div className="submenuMobile_industries_main">
      <a href={`${MainUrl}Oils-gas`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          <OilGas className="OilMobile" />
          Oil & Gas
        </div>
      </a>

      <a
        href={`${MainUrl}Manufacturing`}
        className="submenuMobile_industries_link"
      >
        <div className="submenuMobile_industries_linkWithLogo">
          <Manufacturing className="ManufacturingMobile" />
          Manufacturing
        </div>
      </a>

      {/* <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">Retail</div>
      </a>

      <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">Healthcare</div>
      </a>

      <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">Automotive</div>
      </a> */}

      <a
        href={`${MainUrl}Government`}
        className="submenuMobile_industries_link"
      >
        <div className="submenuMobile_industries_linkWithLogo">
          <Government className="GovernmentMobile" />
          Government
        </div>
      </a>

      {/* <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          Telecommunication
        </div>
      </a>

      <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          Construction & Real Estate
        </div>
      </a>

      <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">
          Information & communication
        </div>
      </a>

      <a href={`${MainUrl}#`} className="submenuMobile_industries_link">
        <div className="submenuMobile_industries_linkWithLogo">FMCG</div>
      </a> */}
    </div>
  );
}

export default IndustriesSubmenuMobile;
