import React, { useState, useEffect } from "react";
import HeaderMobile from "../../components/headerMobile/headerMobile";
import SapAribaContent from "./Sap_Ariba";
import Header from "../../components/header/header-dark";
import Footer from "../../components/footer/footer";
import FooterMobile from "../../components/footerMobile/footerMobile";

function SapAriba() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 475);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {isMobile ? <HeaderMobile /> : <Header />}
      <SapAribaContent />
      {isMobile ? <FooterMobile /> : <Footer />}
    </>
  );
}
export default SapAriba;
