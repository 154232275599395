import React, { useEffect, useRef } from "react";
import "./servicesSubmenuMobile.css";
import { MainUrl } from "../../../config";

function ServicesSubmenuMobile() {
  return (
    <div className="submenuMobile_Services_main">
      <a href={`${MainUrl}devops`} className="submenuMobile_Services_link">
        DevOps
      </a>
      <a
        href={`${MainUrl}DigitalMarketing`}
        className="submenuMobile_Services_link"
      >
        Digital Marketing
      </a>
      <a href={`${MainUrl}S/4hana`} className="submenuMobile_Services_link">
        SAP 4/HANA
      </a>
      <a
        href={`${MainUrl}sap-SuccessFactors`}
        className="submenuMobile_Services_link"
      >
        SAP SuccessFactors
      </a>
      <a href={`${MainUrl}sap-ariba`} className="submenuMobile_Services_link">
        SAP Ariba
      </a>
      <a href={`${MainUrl}Sap-Rise`} className="submenuMobile_Services_link">
        RISE with SAP
      </a>
    </div>
  );
}

export default ServicesSubmenuMobile;
