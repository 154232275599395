import React from "react";
import "./Manufacturing.css";
import ManufacturingP1S2 from "../../assets/svg/Manufacturing_P1_S2.svg";
// new
import Manufacturing_P2_Alfa from "../../assets/images/Clients_P2_Alfa.png";
import ManufacturingP2L1EGFOOD from "../../assets/svg/Home_P8_L1_EGFOOD.svg";
import ClientsP2L1Elaraby from "../../assets/svg/Home_P8_L1_Elaraby.svg";
import Clients_P2_Makan from "../../assets/images/Clients_P2_Makan.jpg";
import Clients_P2_EcoBat from "../../assets/images/Clients_P2_EcoBat.png";
import Clients_P2_Ittihad from "../../assets/images/Clients_P2_Ittihad.jpg";
import Clients_P2_zenia from "../../assets/images/Clients_P2_zenia.png";
import Clients_P2_mobica from "../../assets/images/Clients_P2_mobica.jpg";
import Clients_P2_EGIC from "../../assets/images/Clients_P2_EGic.png";
import Clients_P2_HSA from "../../assets/images/Clients_P2_HSA.jpg";
import LazyMedia from "../../lazyLoad/lazyLoad";

function ManufacturingContent() {
  return (
    <>
      <div className="Manufacturing_Container">
        <div className="Manufacturing_FirstPart">
          <div className="Manufacturing_P1_S1">
            <h1 className="Manufacturing_P1_S1_H1">Manufacturing industry</h1>
            <LazyMedia
              type="image"
              src={ManufacturingP1S2}
              alt="ManufacturingP1S2"
              className="ManufacturingP1S2Mobile"
            />
            <p className="Manufacturing_P1_S1_P">
              Begin a journey of transformation with us, redefining
              manufacturing resilience in today's dynamic business landscape. In
              an environment where speed, adaptability, and agility are
              essential, discrete manufacturers face challenges such as
              compressed development timelines and intricate supply chain
              issues. Our digital manufacturing transformation ensures
              operational excellence by seamlessly integrating processes, supply
              chains, and customer demands. With advanced automation and
              real-time insights, we empower you to navigate disruptions,
              enhancing performance and profitability in the manufacturing
              realm.
            </p>
          </div>
          <div className="Manufacturing_P1_S2">
            <LazyMedia
              type="image"
              src={ManufacturingP1S2}
              alt="ManufacturingP1S2"
              className="ManufacturingP1S2Web"
            />
          </div>
        </div>
        <div className="Manufacturing_SecondPart">
          <div className="Manufacturing_P2_S1">
            <h1 className="Manufacturing_P2_S1_H1">Our Partners of success</h1>
          </div>
          <div className="Manufacturing_P2_S2">
            <div className="Manufacturing_P2_S2_L">
              <LazyMedia
                type="image"
                src={Manufacturing_P2_Alfa}
                alt="Manufacturing_P2_Alfa"
                className="Manufacturing_P2_Alfa2"
              />
              <LazyMedia
                type="image"
                src={ManufacturingP2L1EGFOOD}
                alt="ManufacturingP2L1EGFOOD"
                className="ManufacturingP2L1EGFOOD"
              />
              <LazyMedia
                type="image"
                src={ClientsP2L1Elaraby}
                alt="ClientsP2L1Elaraby"
                className="ClientsP2L1Elaraby2"
              />
            </div>
            <div className="Manufacturing_P2_S2_L">
              <LazyMedia
                type="image"
                src={Clients_P2_EcoBat}
                alt="Clients_P2_EcoBat"
                className="Clients_P2_EcoBat"
              />
              <LazyMedia
                type="image"
                src={Clients_P2_Makan}
                alt="Clients_P2_Makan"
                className="Clients_P2_Makan2"
              />

              <LazyMedia
                type="image"
                src={Clients_P2_Ittihad}
                alt="Clients_P2_Ittihad"
                className="Clients_P2_Ittihad2"
              />
            </div>
            <div className="Manufacturing_P2_S2_L">
              <LazyMedia
                type="image"
                src={Clients_P2_mobica}
                alt="Clients_P2_mobica"
                className="Clients_P2_mobica2"
              />
              <LazyMedia
                type="image"
                src={Clients_P2_zenia}
                alt="Clients_P2_zenia"
                className="Clients_P2_zenia2"
              />

              <LazyMedia
                type="image"
                src={Clients_P2_EGIC}
                alt="Clients_P2_EGIC"
                className="Clients_P2_EGIC"
              />
            </div>
            <div className="Manufacturing_P2_S2_L">
              <LazyMedia
                type="image"
                src={Clients_P2_HSA}
                alt="Clients_P2_HSA"
                className="Clients_P2_HSA"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManufacturingContent;
