import React from "react";
import "./TrendMicro.css";
import { MainUrl } from "../../config";
import TrendBanner from "../../assets/svg/sap_banner.svg";
import PartnersBanner from "../../assets/videos/Partners_Banner.MP4";

import SapStar from "../../assets/svg/SapStar.svg";
import TrendLogo from "../../assets/images/Trend_P1_Logo.png";
import SapPlus from "../../assets/svg/Sap_plus.svg";
import Trend_P2_S2 from "../../assets/images/Trend_P2_S2.jpg";
import Trend_P3_S1 from "../../assets/images/Trend_P3_S1.jpg";
import Trend_P4_S3_Box1 from "../../assets/svg/Trend_P4_S3_Box1.svg";
import Trend_P4_S3_Box2 from "../../assets/svg/Trend_P4_S3_Box2.svg";
import Trend_P4_S3_Box3 from "../../assets/svg/Trend_P4_S3_Box3.svg";
import Trend_P4_S3_Box4 from "../../assets/svg/Trend_P4_S3_Box4.svg";
import Trend_P5_S2 from "../../assets/images/Trend_P5_S2.jpg";
import Trend_P7_S3 from "../../assets/svg/Trend_P7_S3.svg";
import Trend_P10_S3 from "../../assets/images/Trend_P10_S3.jpg";
import Trend_P13_S1 from "../../assets/images/Trend_P13_S1.jpg";
import LazyMedia from "../../lazyLoad/lazyLoad";

function TrendMicroContent() {
  return (
    <>
      <div className="TrendMicro_Container">
        <div className="Trend_FirstPart">
          <LazyMedia
            type="video"
            src={PartnersBanner}
            className="Partners_VideoBackground"
          />
          <LazyMedia
            type="image"
            src={SapStar}
            alt="SapStar"
            className="SapStar"
          />
          <div className="Trend_P1_P1">
            <h1 className="Trend_P1_P1_H1">Phoenix</h1>
            <LazyMedia
              type="image"
              src={SapPlus}
              alt="SapPlus"
              className="SapPlus"
            />
            <LazyMedia
              type="image"
              src={TrendLogo}
              alt="TrendLogo"
              className="TrendLogo"
            />
          </div>
          <h1 className="Trend_FirstPart_H1">
            Navigating Next-Gen Cybersecurity Solutions
          </h1>
          <p className="Trend_FirstPart_P">
            Maximize the robust abilities of TrendMicro's Advanced Security
            Solutions. In the complex tapestry of today's cyber threat
            environment, our alliance with TrendMicro is your assurance of a
            fortified, advanced protective stance for your business
            infrastructure.
          </p>
        </div>
        <div className="Trend_SecondPart">
          <div className="Trend_P2_S1">
            <h1 className="Trend_P2_S1_H1">
              Strategic Partnerships for Enhanced Protection
            </h1>
            <p className="Trend_P2_S1_P">
              TrendMicro distinguishes itself through strategic alliances with
              giants like AWS, Microsoft, Google, IBM, and VMware, driving
              forward a new era of trust and efficiency in cybersecurity. These
              partnerships ensure seamless deployment, operational
              cost-efficiency, and proactive defense mechanisms against cyber
              threats.
            </p>
          </div>
          <div className="Trend_P2_S2">
            <LazyMedia
              type="image"
              src={Trend_P2_S2}
              alt="Trend_P2_S2"
              className="Trend_P2_S2"
            />
          </div>
        </div>
        <div className="Trend_ThirdPart">
          <div className="Trend_P3_S1">
            <LazyMedia
              type="image"
              src={Trend_P3_S1}
              alt="Trend_P3_S1"
              className="Trend_P3_S1"
            />
          </div>
          <div className="Trend_P3_S2">
            <h1 className="Trend_P3_S2_H1">
              Security Services Tailored for Your Needs
            </h1>
            <p className="Trend_P3_S2_P">
              From hybrid cloud security, network and user protection, to threat
              detection and data center security, our services are
              all-encompassing. For the remote workforce, we provide vigilant
              monitoring with light-footed agents and cloud-based management
              consoles for comprehensive visibility and control over your
              organization's security posture.
            </p>
          </div>
        </div>
        <div className="Trend_FourthPart">
          <div className="Trend_P4_Container">
            <div className="Trend_P4_S1">
              <h1 className="Trend_P4_S1_H1">
                Integrated Cybersecurity Approach
              </h1>
            </div>
            <div className="Trend_P4_S2">
              <p className="Trend_P4_S2_P">
                Our services encapsulate the essence of TrendMicro's offerings,
                tailored to meet your business demands
              </p>
            </div>
          </div>
          <div className="Trend_P4_S3">
            {/* box 1 */}
            <div className="Trend_P4_S3_Box">
              <LazyMedia
                type="image"
                src={Trend_P4_S3_Box1}
                alt="Trend_P4_S3_Box1"
              />
              <h1 className="Trend_P4_S3_Box_H1">Design</h1>
              <p className="Trend_P4_S3_Box_P">
                Our experts excel at crafting security solutions that not only
                meet but exceed your needs, meticulously designed to offer
                top-tier protection
              </p>
            </div>
            {/* box 2 */}
            <div className="Trend_P4_S3_Box">
              <LazyMedia
                type="image"
                src={Trend_P4_S3_Box2}
                alt="Trend_P4_S3_Box2"
              />
              <h1 className="Trend_P4_S3_Box_H1">Deployment</h1>
              <p className="Trend_P4_S3_Box_P">
                After designing the solutions, we ensure their smooth
                deployment, guided by seasoned professionals to guarantee peak
                performance and reliability.
              </p>
            </div>
            {/* box 3 */}
            <div className="Trend_P4_S3_Box">
              <LazyMedia
                type="image"
                src={Trend_P4_S3_Box3}
                alt="Trend_P4_S3_Box3"
              />
              <h1 className="Trend_P4_S3_Box_H1">Adoption</h1>
              <p className="Trend_P4_S3_Box_P">
                We help you adopt the most fitting functionalities that grant
                you control over your network. This ensures your organization is
                equipped to meet its security goals.
              </p>
            </div>
            {/* box 4 */}
            <div className="Trend_P4_S3_Box">
              <LazyMedia
                type="image"
                src={Trend_P4_S3_Box4}
                alt="Trend_P4_S3_Box4"
              />
              <h1 className="Trend_P4_S3_Box_H1">Optimization</h1>
              <p className="Trend_P4_S3_Box_P">
                Our experts work with you to fine-tune your operational
                processes for ongoing improvement and advancement, ensuring your
                security infrastructure is always at its best.
              </p>
            </div>
          </div>
        </div>
        <div className="Trend_FifthPart">
          <div className="Trend_P5_S1">
            <h1 className="Trend_P5_S1_H1">
              Turnkey Solutions for Enabling Security
            </h1>
            <p className="Trend_P5_S1_P">
              We empower you with next-gen security solutions, from network and
              workloads to end-user devices. Our experts champion the
              implementation of best practices to instill confidence in your
              security strategy and operational outcomes.
            </p>
          </div>
          <div className="Trend_P5_S2">
            <LazyMedia
              type="image"
              src={Trend_P5_S2}
              alt="Trend_P5_S2"
              className="Trend_P5_S2_img"
            />
          </div>
        </div>
        <div className="Trend_SixthPart">
          <div className="Trend_P6_S1">
            <h1 className="Trend_P6_S1_H1">
              Harness the Key Benefits of Our Alliance
            </h1>
          </div>
          <div className="Trend_P6_S2">
            <div className="Trend_P6_S2_Container">
              {/* box 1 */}
              <div className="Trend_P6_S2_Box">
                <h3 className="Trend_P6_S2_Box_H3">
                  Unified Security Platform
                </h3>
                <p className="Trend_P6_S2_Box_P">
                  Control all aspects of your digital security through a
                  consolidated framework.
                </p>
              </div>
              {/* box 2 */}
              <div className="Trend_P6_S2_Box">
                <h3 className="Trend_P6_S2_Box_H3">Adaptive Solutions</h3>
                <p className="Trend_P6_S2_Box_P">
                  Evolve your security strategies as your business needs change.
                </p>
              </div>
              {/* box 3 */}
              <div className="Trend_P6_S2_Box">
                <h3 className="Trend_P6_S2_Box_H3">
                  Cutting-Edge Threat Protection
                </h3>
                <p className="Trend_P6_S2_Box_P">
                  Access the latest in threat intelligence and advanced defense
                  technologies.
                </p>
              </div>
            </div>
            <div className="Trend_P6_S2_Container">
              {/* box 1 */}
              <div className="Trend_P6_S2_Box">
                <h3 className="Trend_P6_S2_Box_H3">User-Centric Security</h3>
                <p className="Trend_P6_S2_Box_P">
                  Benefit from intuitive tools designed with the user in mind.
                </p>
              </div>
              {/* box 2 */}
              <div className="Trend_P6_S2_Box">
                <h3 className="Trend_P6_S2_Box_H3">Community and Support</h3>
                <p className="Trend_P6_S2_Box_P">
                  Engage with TrendMicro's support community for ongoing
                  enhancements.
                </p>
              </div>
              {/* box 3 */}
              <div className="Trend_P6_S2_Box">
                <h3 className="Trend_P6_S2_Box_H3">
                  Ready for Mobility and Cloud
                </h3>
                <p className="Trend_P6_S2_Box_P">
                  Ensure secure operations across devices and locations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Trend_SeventhPart">
          <div className="Trend_P7_S1">
            <h1 className="Trend_P7_S1_H1">
              Secure Your Future with Phoenix Consulting and TrendMicro
            </h1>
          </div>
          <div className="Trend_P7_S2">
            <p className="Trend_P7_S1_P">
              Opt for Phoenix Consulting and TrendMicro, a partnership that
              redefines cybersecurity. Connect with us to amplify your security
              protocols and step into an era of unmatched digital safety, guided
              by innovation and expert insight.
            </p>
          </div>
          <div className="Trend_P7_S3">
            <LazyMedia
              type="image"
              src={Trend_P7_S3}
              alt="Trend_P7_S3"
              className="Trend_P7_S3_Img"
            />
          </div>
        </div>
        <div className="Trend_EightPart">
          <div className="Trend_P8_S1">
            <h1 className="Trend_P8_S1_H1">
              Empowerment Through Free Security Assessment
            </h1>
          </div>
          <div className="Trend_P8_S1_Text">
            <p className="Trend_P8_S1_P">
              Optimize your cybersecurity while maintaining peak productivity.
              Our Free Security Assessment includes
            </p>
          </div>
          <div className="Trend_P8_S2">
            <div className="Trend_P8_S2_Container">
              {/* box 1 */}
              <div className="Trend_P8_S2_Box">
                <h3 className="Trend_P8_S2_Box_H3">
                  Vulnerability Identification
                </h3>
                <p className="Trend_P8_S2_Box_P">
                  Establishing robust security through precise settings.
                </p>
              </div>
              {/* box 2 */}
              <div className="Trend_P8_S2_Box">
                <h3 className="Trend_P8_S2_Box_H3">System Configuration</h3>
                <p className="Trend_P8_S2_Box_P">
                  Tuning antivirus, anti-spyware, firewalls, and network
                  parameters for peak defense.
                </p>
              </div>
              {/* box 3 */}
              <div className="Trend_P8_S2_Box">
                <h3 className="Trend_P8_S2_Box_H3">Enhanced System Settings</h3>
                <p className="Trend_P8_S2_Box_P">
                  Strengthening your security and identity protection.
                </p>
              </div>
            </div>
            <div className="Trend_P8_S2_Container">
              {/* box 4 */}
              <div className="Trend_P8_S2_Box">
                <h3 className="Trend_P8_S2_Box_H3">
                  Critical Updates Installation
                </h3>
                <p className="Trend_P8_S2_Box_P">
                  Ensuring the latest updates for key software are in place.
                </p>
              </div>
              {/* box 5 */}
              <div className="Trend_P8_S2_Box">
                <h3 className="Trend_P8_S2_Box_H3">
                  Parental Control Guidance
                </h3>
                <p className="Trend_P8_S2_Box_P">
                  Expert advice on product features for comprehensive security
                  management.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="Trend_NinthPart">
          <div className="Trend_P9_S1">
            <h1 className="Trend_P9_S1_H1">Bolstering Your Security Posture</h1>
          </div>
          <div className="Trend_P9_S2">
            {/* box 1 */}
            <div className="Trend_P9_S2_Box">
              <h3 className="Trend_P9_S2_Box_H3">
                Continuous Improvement Roadmap
              </h3>
              <p className="Trend_P9_S2_Box_P">
                Utilize best practices to guide ongoing security enhancement.
              </p>
            </div>
            {/* box 2 */}
            <div className="Trend_P9_S2_Box">
              <h3 className="Trend_P9_S2_Box_H3_B2">
                Optimized Configurations
              </h3>
              <p className="Trend_P9_S2_Box_P">
                Stay ahead with the most progressive security configurations.
              </p>
            </div>
            {/* box 3 */}
            <div className="Trend_P9_S2_Box">
              <h3 className="Trend_P9_S2_Box_H3">
                Benchmarking for Excellence
              </h3>
              <p className="Trend_P9_S2_Box_P">
                Assess your environment to identify and seize opportunities for
                security improvements.
              </p>
            </div>
          </div>
        </div>
        <div className="Trend_TenthPart">
          <div className="Trend_P10_Container">
            <div className="Trend_P10_S1">
              <h1 className="Trend_P10_S1_H1">Operational Transformation</h1>
            </div>
            <div className="Trend_P10_S2">
              <div className="Trend_P10_S2_Box">
                <h3 className="Trend_P10_S2_Box_H3">
                  Minimized Vulnerabilities
                </h3>
                <p className="Trend_P10_S2_Box_P">
                  Adopt strategies that reduce exposure to threats.
                </p>
              </div>
              <div className="Trend_P10_S2_Box">
                <h3 className="Trend_P10_S2_Box_H3">Security Strengthening</h3>
                <p className="Trend_P10_S2_Box_P">
                  Leverage our enablement workshops to diminish manual
                  workloads.
                </p>
              </div>
              <div className="Trend_P10_S2_Box">
                <h3 className="Trend_P10_S2_Box_H3">
                  Efficient Product Integration
                </h3>
                <p className="Trend_P10_S2_Box_P">
                  Integrate solutions in a multi-vendor setup to boost
                  efficiency.
                </p>
              </div>
            </div>
          </div>
          <div className="Trend_P10_S3">
            <LazyMedia
              type="image"
              src={Trend_P10_S3}
              alt="Trend_P10_S3"
              className="Trend_P10_S3_Img"
            />
          </div>
        </div>
        <div className="Trend_EleventhPart">
          <div className="Trend_P11_S1">
            <h1 className="Trend_P11_S1_H1">
              Experience the Phoenix Consulting Security Advantage
            </h1>
          </div>
          <div className="Trend_P11_S2">
            <p className="Trend_P11_S2_P">
              Our integrated cybersecurity methodology starts by comprehensively
              assessing your unique vulnerabilities, customizing TrendMicro's
              powerful arsenal to safeguard your enterprise assets. We accompany
              you every step of the way, from deployment to exceeding your
              security expectations.
            </p>
          </div>
        </div>
        <div className="Trend_TwelvethPart">
          <div className="Trend_P12_S1">
            <h1 className="Trend_P12_S1_H1">
              Accelerating Your Security's Time to Value
            </h1>
          </div>
          <div className="Trend_P12_S2">
            {/* box 1 */}
            <div className="Trend_P12_S2_Box">
              <h3 className="Trend_P12_S2_Box_H3">Best Practices Alignment</h3>
              <p className="Trend_P12_S2_Box_P">
                Sync your architecture and operations with industry-leading
                protocols.
              </p>
            </div>
            {/* box 2 */}
            <div className="Trend_P12_S2_Box">
              <h3 className="Trend_P12_S2_Box_H3">Maximized Uptime</h3>
              <p className="Trend_P12_S2_Box_P">
                Our exceptional support ensures your business operates without
                interruption.
              </p>
            </div>
            {/* box 3 */}
            <div className="Trend_P12_S2_Box">
              <h3 className="Trend_P12_S2_Box_H3">Expert-Guided Utilization</h3>
              <p className="Trend_P12_S2_Box_P">
                Tap into the full potential of the TrendMicro suite with our
                expertise.
              </p>
            </div>
          </div>
        </div>
        <div className="Trend_ThirteenthPart">
          <div className="Trend_P13_S1">
            <LazyMedia
              type="image"
              src={Trend_P13_S1}
              alt="Trend_P13_S1"
              className="Trend_P13_S1_Img"
            />
          </div>
          <div className="Trend_P13_S2">
            <h1 className="Trend_P13_S2_H1">
              The Winning Combination of TrendMicro + Phoenix
            </h1>
            <p className="Trend_P13_S2_P">
              With TrendMicro's Authorized Training Centers and Phoenix
              Consulting's expertise, you're guaranteed to stay at the forefront
              of the evolving threat landscape, mastering the challenges of
              targeted attacks and complex security requirements of BYOD and
              virtualization scenarios.
            </p>
          </div>
        </div>
        <div className="Trend_FourteenthPart">
          <div className="Trend_P14_S1">
            <h1 className="Trend_P14_S1_H1">
              Access Your Full Security Potential
            </h1>
          </div>
          <div className="Trend_P14_S2">
            <p className="Trend_P14_S2_P">
              Phoenix Consulting delivers TrendMicro's Professional Services,
              expertly tailoring them to act as a catalyst for your
              organization's digital transformation. Our expert team, utilizing
              advanced technologies, transforms security into a driving force
              for business enablement rather than an obstacle.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrendMicroContent;
