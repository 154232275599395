import React from "react";
import "./ClientsSlider.css";
// Layer 1 Images
import ClientsP2L1Elaraby from "../../../assets/svg/Home_P8_L1_Elaraby.svg";
import ClientsP2L1EGFOOD from "../../../assets/svg/Home_P8_L1_EGFOOD.svg";
import ClientsP2L1JUHAYNA from "../../../assets/images/Home_P8_L1_JUHAYNA.png";
import ClientsP2L1Elqana from "../../../assets/svg/Home_P8_L1_Elqana.svg";
import ClientsP2L1OmanTel from "../../../assets/images/Home_P8_L1_OmanTel.png";
import ClientsP2L1Zain from "../../../assets/svg/Home_P8_L1_Zain.svg";
import ClientsP2L1Stc from "../../../assets/svg/Home_P8_L1_Stc.svg";
// import ClientsP2L1Sap from "../../../assets/svg/Home_P8_L1_Sap.svg";
import ClientsP2L1Oscar from "../../../assets/svg/Home_P8_L1_Oscar.svg";
// Layer 2Images
import ClientsP2L2SBM from "../../../assets/svg/Home_P8_L2_SBM.svg";
import ClientsP2L2Affairs from "../../../assets/svg/Home_P8_L2_Ministry_foreign_Affairs.svg";
import ClientsP2L2Communications from "../../../assets/svg/Home_P8_L2_Ministry_communications.svg";
import ClientsP2L2Planning from "../../../assets/svg/Home_P8_L2_Planning_and_Economic_deveopment.svg";
import ClientsP2L2Etihad from "../../../assets/svg/Home_P8_L2_Etihad.svg";
import ClientsP2L2Adlok from "../../../assets/svg/Home_P8_L2_Adlok.svg";
import ClientsP2L2Haras from "../../../assets/svg/Home_P8_L2_Haras.svg";
import Clients_P2_Amer from "../../../assets/images/Clients_P2_Amer.png";
import Clients_P2_Alfa from "../../../assets/images/Clients_P2_Alfa.png";
import Clients_P2_Edita from "../../../assets/images/Clients_P2_Edita.png";
import Clients_P2_HarasQatar from "../../../assets/images/Clients_P2_HarasQatar.jpg";
import Clients_P2_Hay2a2 from "../../../assets/images/Clients_P2_Hay2a2.png";
import Clients_P2_Ict from "../../../assets/images/Clients_P2_Ict.jpg";
import Clients_P2_Ittihad from "../../../assets/images/Clients_P2_Ittihad.jpg";
import Clients_P2_metra from "../../../assets/images/Clients_P2_metra.jpg";
import Clients_P2_Mido from "../../../assets/images/Clients_P2_Mido.jpg";
import Clients_P2_mobica from "../../../assets/images/Clients_P2_mobica.jpg";
import Clients_P2_naufar from "../../../assets/images/Clients_P2_naufar.jpg";
import Clients_P2_porto from "../../../assets/images/Clients_P2_porto.jpg";
import Clients_P2_zenia from "../../../assets/images/Clients_P2_zenia.png";
import Clients_P2_Makan from "../../../assets/images/Clients_P2_Makan.jpg";
import LazyMedia from "../../../lazyLoad/lazyLoad";

function ClientsPartEightSlider() {
  return (
    <>
      <div className="MainOneCLients">
        <div className="Clients_P2_S2_Container">
          <div className="Clients_P2_S2_Layer1_Wrapper_Container">
            {/* wrap one */}
            <div className="Clients_P2_S2_Layer1_Wrapper">
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={ClientsP2L1Elaraby}
                  alt="Brands"
                  className="ClientsP2L1Elaraby"
                />
                <img
                  src={ClientsP2L1EGFOOD}
                  alt="Brands"
                  className="ClientsP2L1EGFOOD"
                />
                <img
                  src={ClientsP2L1JUHAYNA}
                  alt="Brands"
                  className="ClientsP2L1JUHAYNA"
                />
                <img
                  src={ClientsP2L1Elqana}
                  alt="Brands"
                  className="ClientsP2L1Elqana"
                />
                <img
                  src={ClientsP2L1OmanTel}
                  alt="Brands"
                  className="ClientsP2L1OmanTel"
                />
                <img
                  src={ClientsP2L2SBM}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2ImgSbm"
                />
                <img
                  src={ClientsP2L2Affairs}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Affairs"
                />
                <img
                  src={ClientsP2L2Communications}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Communications"
                />
                {/* <img
                  src={ClientsP2L2Planning}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Planning"
                /> */}
                <img
                  src={ClientsP2L2Etihad}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Etihad"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                {/* <img
                  src={ClientsP2L1Sap}
                  alt="Brands"
                  className="ClientsP2L1Sap"
                /> */}
                <img
                  src={ClientsP2L1Oscar}
                  alt="Brands"
                  className="ClientsP2L1Oscar"
                />
                <img
                  src={Clients_P2_Amer}
                  alt="Brands"
                  className="Clients_P2_Amer"
                />
                <img
                  src={Clients_P2_Alfa}
                  alt="Brands"
                  className="Clients_P2_Alfa"
                />
                <img
                  src={Clients_P2_Edita}
                  alt="Brands"
                  className="Clients_P2_Edita"
                />
                <img
                  src={ClientsP2L1Zain}
                  alt="Brands"
                  className="ClientsP2ImgZain"
                />
                <img
                  src={ClientsP2L1Stc}
                  alt="Brands"
                  className="ClientsP2ImgStc"
                />
                <img
                  src={Clients_P2_HarasQatar}
                  alt="Brands"
                  className="Clients_P2_HarasQatar"
                />
                <img
                  src={Clients_P2_Hay2a2}
                  alt="Brands"
                  className="Clients_P2_Hay2a2"
                />
                <img
                  src={Clients_P2_zenia}
                  alt=""
                  className="Clients_P2_zenia"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={Clients_P2_Ict}
                  alt="Brands"
                  className="Clients_P2_Ict"
                />
                <img
                  src={Clients_P2_Ittihad}
                  alt="Brands"
                  className="Clients_P2_Ittihad"
                />
                <img
                  src={Clients_P2_metra}
                  alt="Brands"
                  className="Clients_P2_metra"
                />
                <img
                  src={Clients_P2_Mido}
                  alt="Brands"
                  className="Clients_P2_Mido"
                />
                <img
                  src={Clients_P2_mobica}
                  alt="Brands"
                  className="Clients_P2_mobica"
                />
                <img
                  src={Clients_P2_naufar}
                  alt="Brands"
                  className="Clients_P2_naufar"
                />
                <img
                  src={Clients_P2_porto}
                  alt="Brands"
                  className="ClientsP2ImgPorto"
                />
                <img
                  src={ClientsP2L2Adlok}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Adlok"
                />
                {/* <img
                  src={ClientsP2L2Haras}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Haras"
                /> */}
                <img
                  src={Clients_P2_Makan}
                  alt="Clients_P2_Makan"
                  className="Clients_P2_Makan"
                />
              </div>
              {/* rebeat */}
            </div>
            <div className="Clients_P2_S2_Layer1_Wrapper">
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={ClientsP2L1Elaraby}
                  alt="Brands"
                  className="ClientsP2L1Elaraby"
                />
                <img
                  src={ClientsP2L1EGFOOD}
                  alt="Brands"
                  className="ClientsP2L1EGFOOD"
                />
                <img
                  src={ClientsP2L1JUHAYNA}
                  alt="Brands"
                  className="ClientsP2L1JUHAYNA"
                />
                <img
                  src={ClientsP2L1Elqana}
                  alt="Brands"
                  className="ClientsP2L1Elqana"
                />
                <img
                  src={ClientsP2L1OmanTel}
                  alt="Brands"
                  className="ClientsP2L1OmanTel"
                />
                <img
                  src={ClientsP2L2SBM}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2ImgSbm"
                />
                <img
                  src={ClientsP2L2Affairs}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Affairs"
                />
                <img
                  src={ClientsP2L2Communications}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Communications"
                />
                {/* <img
                  src={ClientsP2L2Planning}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Planning"
                /> */}
                <img
                  src={ClientsP2L2Etihad}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Etihad"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                {/* <img
                  src={ClientsP2L1Sap}
                  alt="Brands"
                  className="ClientsP2L1Sap"
                /> */}
                <img
                  src={ClientsP2L1Oscar}
                  alt="Brands"
                  className="ClientsP2L1Oscar"
                />
                <img
                  src={Clients_P2_Amer}
                  alt="Brands"
                  className="Clients_P2_Amer"
                />
                <img
                  src={Clients_P2_Alfa}
                  alt="Brands"
                  className="Clients_P2_Alfa"
                />
                <img
                  src={Clients_P2_Edita}
                  alt="Brands"
                  className="Clients_P2_Edita"
                />
                <img
                  src={ClientsP2L1Zain}
                  alt="Brands"
                  className="ClientsP2ImgZain"
                />
                <img
                  src={ClientsP2L1Stc}
                  alt="Brands"
                  className="ClientsP2ImgStc"
                />
                <img
                  src={Clients_P2_HarasQatar}
                  alt="Brands"
                  className="Clients_P2_HarasQatar"
                />
                <img
                  src={Clients_P2_Hay2a2}
                  alt="Brands"
                  className="Clients_P2_Hay2a2"
                />
                <img
                  src={Clients_P2_zenia}
                  alt=""
                  className="Clients_P2_zenia"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={Clients_P2_Ict}
                  alt="Brands"
                  className="Clients_P2_Ict"
                />
                <img
                  src={Clients_P2_Ittihad}
                  alt="Brands"
                  className="Clients_P2_Ittihad"
                />
                <img
                  src={Clients_P2_metra}
                  alt="Brands"
                  className="Clients_P2_metra"
                />
                <img
                  src={Clients_P2_Mido}
                  alt="Brands"
                  className="Clients_P2_Mido"
                />
                <img
                  src={Clients_P2_mobica}
                  alt="Brands"
                  className="Clients_P2_mobica"
                />
                <img
                  src={Clients_P2_naufar}
                  alt="Brands"
                  className="Clients_P2_naufar"
                />
                <img
                  src={Clients_P2_porto}
                  alt="Brands"
                  className="ClientsP2ImgPorto"
                />
                <img
                  src={ClientsP2L2Adlok}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Adlok"
                />
                {/* <img
                  src={ClientsP2L2Haras}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Haras"
                /> */}
                <img
                  src={Clients_P2_Makan}
                  alt="Clients_P2_Makan"
                  className="Clients_P2_Makan"
                />
              </div>
              {/* rebeat */}
            </div>
            <div className="Clients_P2_S2_Layer1_Wrapper">
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={ClientsP2L1Elaraby}
                  alt="Brands"
                  className="ClientsP2L1Elaraby"
                />
                <img
                  src={ClientsP2L1EGFOOD}
                  alt="Brands"
                  className="ClientsP2L1EGFOOD"
                />
                <img
                  src={ClientsP2L1JUHAYNA}
                  alt="Brands"
                  className="ClientsP2L1JUHAYNA"
                />
                <img
                  src={ClientsP2L1Elqana}
                  alt="Brands"
                  className="ClientsP2L1Elqana"
                />
                <img
                  src={ClientsP2L1OmanTel}
                  alt="Brands"
                  className="ClientsP2L1OmanTel"
                />
                <img
                  src={ClientsP2L2SBM}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2ImgSbm"
                />
                <img
                  src={ClientsP2L2Affairs}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Affairs"
                />
                <img
                  src={ClientsP2L2Communications}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Communications"
                />
                {/* <img
                  src={ClientsP2L2Planning}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Planning"
                /> */}
                <img
                  src={ClientsP2L2Etihad}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Etihad"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                {/* <img
                  src={ClientsP2L1Sap}
                  alt="Brands"
                  className="ClientsP2L1Sap"
                /> */}
                <img
                  src={ClientsP2L1Oscar}
                  alt="Brands"
                  className="ClientsP2L1Oscar"
                />
                <img
                  src={Clients_P2_Amer}
                  alt="Brands"
                  className="Clients_P2_Amer"
                />
                <img
                  src={Clients_P2_Alfa}
                  alt="Brands"
                  className="Clients_P2_Alfa"
                />
                <img
                  src={Clients_P2_Edita}
                  alt="Brands"
                  className="Clients_P2_Edita"
                />
                <img
                  src={ClientsP2L1Zain}
                  alt="Brands"
                  className="ClientsP2ImgZain"
                />
                <img
                  src={ClientsP2L1Stc}
                  alt="Brands"
                  className="ClientsP2ImgStc"
                />
                <img
                  src={Clients_P2_HarasQatar}
                  alt="Brands"
                  className="Clients_P2_HarasQatar"
                />
                <img
                  src={Clients_P2_Hay2a2}
                  alt="Brands"
                  className="Clients_P2_Hay2a2"
                />
                <img
                  src={Clients_P2_zenia}
                  alt=""
                  className="Clients_P2_zenia"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={Clients_P2_Ict}
                  alt="Brands"
                  className="Clients_P2_Ict"
                />
                <img
                  src={Clients_P2_Ittihad}
                  alt="Brands"
                  className="Clients_P2_Ittihad"
                />
                <img
                  src={Clients_P2_metra}
                  alt="Brands"
                  className="Clients_P2_metra"
                />
                <img
                  src={Clients_P2_Mido}
                  alt="Brands"
                  className="Clients_P2_Mido"
                />
                <img
                  src={Clients_P2_mobica}
                  alt="Brands"
                  className="Clients_P2_mobica"
                />
                <img
                  src={Clients_P2_naufar}
                  alt="Brands"
                  className="Clients_P2_naufar"
                />
                <img
                  src={Clients_P2_porto}
                  alt="Brands"
                  className="ClientsP2ImgPorto"
                />
                <img
                  src={ClientsP2L2Adlok}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Adlok"
                />
                {/* <img
                  src={ClientsP2L2Haras}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Haras"
                /> */}
                <img
                  src={Clients_P2_Makan}
                  alt="Clients_P2_Makan"
                  className="Clients_P2_Makan"
                />
              </div>
              {/* rebeat */}
            </div>
            <div className="Clients_P2_S2_Layer1_Wrapper">
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={ClientsP2L1Elaraby}
                  alt="Brands"
                  className="ClientsP2L1Elaraby"
                />
                <img
                  src={ClientsP2L1EGFOOD}
                  alt="Brands"
                  className="ClientsP2L1EGFOOD"
                />
                <img
                  src={ClientsP2L1JUHAYNA}
                  alt="Brands"
                  className="ClientsP2L1JUHAYNA"
                />
                <img
                  src={ClientsP2L1Elqana}
                  alt="Brands"
                  className="ClientsP2L1Elqana"
                />
                <img
                  src={ClientsP2L1OmanTel}
                  alt="Brands"
                  className="ClientsP2L1OmanTel"
                />
                <img
                  src={ClientsP2L2SBM}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2ImgSbm"
                />
                <img
                  src={ClientsP2L2Affairs}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Affairs"
                />
                <img
                  src={ClientsP2L2Communications}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Communications"
                />
                {/* <img
                  src={ClientsP2L2Planning}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Planning"
                /> */}
                <img
                  src={ClientsP2L2Etihad}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Etihad"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                {/* <img
                  src={ClientsP2L1Sap}
                  alt="Brands"
                  className="ClientsP2L1Sap"
                /> */}
                <img
                  src={ClientsP2L1Oscar}
                  alt="Brands"
                  className="ClientsP2L1Oscar"
                />
                <img
                  src={Clients_P2_Amer}
                  alt="Brands"
                  className="Clients_P2_Amer"
                />
                <img
                  src={Clients_P2_Alfa}
                  alt="Brands"
                  className="Clients_P2_Alfa"
                />
                <img
                  src={Clients_P2_Edita}
                  alt="Brands"
                  className="Clients_P2_Edita"
                />
                <img
                  src={ClientsP2L1Zain}
                  alt="Brands"
                  className="ClientsP2ImgZain"
                />
                <img
                  src={ClientsP2L1Stc}
                  alt="Brands"
                  className="ClientsP2ImgStc"
                />
                <img
                  src={Clients_P2_HarasQatar}
                  alt="Brands"
                  className="Clients_P2_HarasQatar"
                />
                <img
                  src={Clients_P2_Hay2a2}
                  alt="Brands"
                  className="Clients_P2_Hay2a2"
                />
                <img
                  src={Clients_P2_zenia}
                  alt=""
                  className="Clients_P2_zenia"
                />
              </div>
              <div className="Clients_P2_S2_Layer1">
                <img
                  src={Clients_P2_Ict}
                  alt="Brands"
                  className="Clients_P2_Ict"
                />
                <img
                  src={Clients_P2_Ittihad}
                  alt="Brands"
                  className="Clients_P2_Ittihad"
                />
                <img
                  src={Clients_P2_metra}
                  alt="Brands"
                  className="Clients_P2_metra"
                />
                <img
                  src={Clients_P2_Mido}
                  alt="Brands"
                  className="Clients_P2_Mido"
                />
                <img
                  src={Clients_P2_mobica}
                  alt="Brands"
                  className="Clients_P2_mobica"
                />
                <img
                  src={Clients_P2_naufar}
                  alt="Brands"
                  className="Clients_P2_naufar"
                />
                <img
                  src={Clients_P2_porto}
                  alt="Brands"
                  className="ClientsP2ImgPorto"
                />
                <img
                  src={ClientsP2L2Adlok}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Adlok"
                />
                {/* <img
                  src={ClientsP2L2Haras}
                  alt="ClientsP2LayerImg"
                  className="ClientsP2L2Haras"
                /> */}
                <img
                  src={Clients_P2_Makan}
                  alt="Clients_P2_Makan"
                  className="Clients_P2_Makan"
                />
              </div>
              {/* rebeat */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ClientsPartEightSlider;
