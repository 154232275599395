import React, { useState, useEffect } from "react";
import styles from "./tender.module.css";
import AccordionSapLeft from "../../components/hooks/accordionTenderPage/AccordionTender";
import Slide from "../../components/hooks/SlideTender/Slide";
import SlideMobile from "../../components/hooks/SlideTender/SlideMobleTender";
//import { ReactComponent as TenderCover } from "./../../assets/images/TenderCover.png";
//import ahned from "../../../public/svg/arrowbuttonR.svg"

const Tender = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [openedAccordion, setOpenedAccordion] = useState(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 475); // Assuming 768 is your mobile breakpoint

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 475);
    };

    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.tenderBigContainer}>
      {/* Tender Cover Image */}

      <div className={styles.tendercover}>
        <div className={styles.coverContent}>
          <h4>Tender solution</h4>
          <h1>Discover the Key to Procurement Excellence</h1>
          <p>
            Phoenix Tender Solutions are tailored to streamline your procurement
            processes with cutting-edge efficiency and transparency
          </p>
        </div>
      </div>

      {/* End CoverOf tender have image as background */}

      {/* SectionOne */}
      <div className={styles.sectionone}>
        {/* our site section     <p>At Phoenix Tender Solutions, we understand that procurement excellence is the cornerstone of business success. Our suite of services is meticulously designed to revolutionize your procurement processes, offering cutting-edge efficiency and unwavering transparency.</p>*/}

        {/* oursite is rightSide part */}
        <div className={styles.oursite}>
          <div className={styles.oursiteContent}>
            <h1>Our suite of services provides</h1>
            <p>
              We prioritize procurement excellence, aiming to transform your
              processes with top-notch efficiency and transparent solutions,
              crucial for business success.
            </p>
          </div>
        </div>
        {/* oursite is rightSide part */}

        {/* is a reusable Slide in leftside as Components */}

        {isMobile ? (
          <SlideMobile
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
        ) : (
          <Slide
            currentSlide={currentSlide}
            setCurrentSlide={setCurrentSlide}
          />
        )}
      </div>
      {/* slides using useState Hooks */}

      {/* Section TWo */}

      <div className={styles.sectiontwo}>
        <h1>
          Critical Processes Covered by Our Comprehensive Phoenix Tender
          Solution
        </h1>

        <AccordionSapLeft
          openedAccordion={openedAccordion}
          setOpenedAccordion={setOpenedAccordion}
          className={styles.sectiontwoAcc}
        />
      </div>

      <div className={styles.sectionthree}>
        <div className={styles.sectionthree_content}>
          <h1>Ready to transform your procurement processes</h1>
          <p>
            Contact us to discover how Phoenix Tender Solutions can elevate your
            operations to new heights.
          </p>
        </div>

        <button>contact us</button>
      </div>
    </div>
  );
};

export default Tender;
