import React from "react";
import "./MoreSubMenuMobile.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import { MainUrl } from "../../../config";

function MoreSubmenuMobile() {
  return (
    <div className="submenuMobile_More_main">
      {/* Clients Page */}
      <a href={`${MainUrl}Clients`} className="submenuMobile_More_link">
        <div className="submenuMobile_More_linkWithLogo">Clients</div>
      </a>

      {/* Careers Page */}
      <a href={`${MainUrl}Careers`} className="submenuMobile_More_link">
        <div className="submenuMobile_More_linkWithLogo">Careers</div>
      </a>
      <a href={`${MainUrl}case-Studies`} className="submenuMobile_More_link">
        <div className="submenuMobile_More_linkWithLogo">Case Studies</div>
      </a>
    </div>
  );
}

export default MoreSubmenuMobile;
