import React from "react";
import "./AccordionItemLeft.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const AccordionItem = ({
  title,
  children,
  isOpen,
  onAccordionClick,
  identifier,
}) => {
  const handleClick = () => {
    if (isOpen) {
      onAccordionClick(null);
    } else {
      onAccordionClick(identifier);
    }
  };

  return (
    <div className="accordion-item">
      <div className="accordion-header" onClick={handleClick}>
        <span className="accordion-icon">
          {isOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </span>
        <span className="accordion-title">{title}</span>
      </div>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

function AccordionSapRight({ openedAccordion, setOpenedAccordion }) {
  return (
    <div className="accordion">
      <AccordionItem
        title="Financial Accounting & Controlling"
        identifier="right-1"
        isOpen={openedAccordion === "right-1"}
        onAccordionClick={setOpenedAccordion}
      >
        SAP's Financial Accounting & Controlling (FICO) provides essential tools
        for precise financial operations. It allows effective management of
        budgeting, cost accounting, asset accounting, and more, supporting
        informed financial decision-making. SAP FICO is integral to the ERP
        solution, enabling businesses to manage financial data, create balance
        sheets and drive corporate planning.
      </AccordionItem>
      <AccordionItem
        title="Customer Service"
        identifier="right-2"
        isOpen={openedAccordion === "right-2"}
        onAccordionClick={setOpenedAccordion}
      >
        With SAP's Customer Service module, you can take your customer service
        operations to the next level. It enables efficient handling of service
        requests and fosters improved customer engagement, leading to enhanced
        customer satisfaction. This tool also facilitates issue management, such
        as creating cases, managing updates, and escalations, ensuring that your
        customer service operations run smoothly.
      </AccordionItem>
      <AccordionItem
        title="Logistics Execution"
        identifier="right-3"
        isOpen={openedAccordion === "right-3"}
        onAccordionClick={setOpenedAccordion}
      >
        Boost your supply chain efficiency with SAP Logistics Execution. This
        module provides comprehensive logistics process execution capabilities,
        irrespective of industry-specific requirements. It focuses on
        streamlining complex goods receipt and goods issue processes and is
        integrated with Production Planning and Control, Materials Management,
        and Sales and Distribution. SAP Logistics Execution encompasses
        Warehouse Management, Shipping, and Transportation, helping you manage
        these vital aspects to maintain a highly efficient supply chain.
      </AccordionItem>
      <AccordionItem
        title="Project Systems"
        identifier="right-4"
        isOpen={openedAccordion === "right-4"}
        onAccordionClick={setOpenedAccordion}
      >
        SAP Project Systems streamlines project management, aiding in efficient
        planning, execution, and monitoring across different project sizes. It
        ensures cost control and timely deliveries, providing a well-structured
        environment shared by all departments, setting clear project goals and
        activities for effective planning and control. This system covers
        technical and commercial aspects, enabling you to manage it from both a
        structural and process-oriented perspective, facilitating work breakdown
        structures and individual activities.
      </AccordionItem>
      <AccordionItem
        title="Customer Relationship Management (CRM)"
        identifier="right-5"
        isOpen={openedAccordion === "right-5"}
        onAccordionClick={setOpenedAccordion}
      >
        Improve your customer relationships with SAP CRM, providing a
        comprehensive view of your customers and optimizing interactions for
        improved sales and service processes. This tool enhances customer
        experiences, boosts customer retention, and increases sales revenue by
        offering integrated tools for sales force automation, lead management,
        and more. SAP CRM also promotes greater process efficiency through
        automation and collaboration, ensuring your teams accomplish more with
        less effort.
      </AccordionItem>
      <AccordionItem
        title="Production Planning"
        identifier="right-6"
        isOpen={openedAccordion === "right-6"}
        onAccordionClick={setOpenedAccordion}
      >
        Streamline production planning and scheduling with SAP, efficiently
        managing orders, production capacity, and on-time product delivery to
        meet customer demands effectively. This SAP module aligns manufacturing
        capacity with demand, creating schedules for finished products and
        components, and tracks manufacturing processes and costs for better
        control and efficiency.
      </AccordionItem>
    </div>
  );
}

export default AccordionSapRight;
