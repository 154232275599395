import React, { useEffect, useRef } from "react";
import "./servicesSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import { MainUrl } from "../../../config";

function ServicesSubmenu() {
  return (
    <div className="submenu_servicesMain">
      <div className="submenu_contain_services">
        <div className="submenu_info">
          <p className="submenu_services">OUR Services</p>
          <p className="submenu_discover">
            Discover the Power of Data and Technology for Your Long-term Success
          </p>
          <p className="submenu_help">
            Phoenix uses data and technology to help clients build trust and
            achieve long-term success by transforming, growing, and operating
            effectively.
          </p>
        </div>

        <div className="submenu_services_contain">
          <a href={`${MainUrl}devops`} className="submenu_services_link">
            DevOps
            <ArrowMenu />
          </a>
          <a href={`${MainUrl}S/4hana`} className="submenu_services_link">
            SAP 4/HANA
            <ArrowMenu />
          </a>
          <a
            href={`${MainUrl}sap-SuccessFactors`}
            className="submenu_services_link"
          >
            SAP SuccessFactors
            <ArrowMenu />
          </a>
        </div>
        {/* container 2 is hidden */}
        <div className="submenu_services_contain">
          {" "}
          <a
            href={`${MainUrl}DigitalMarketing`}
            className="submenu_services_link"
          >
            Digital Marketing
            <ArrowMenu />
          </a>
          <a href={`${MainUrl}sap-ariba`} className="submenu_services_link">
            SAP Ariba
            <ArrowMenu />
          </a>
          <a href={`${MainUrl}Sap-Rise`} className="submenu_services_link">
            RISE with SAP
            <ArrowMenu />
          </a>
        </div>
        {/* container 2 is hidden */}
      </div>
    </div>
  );
}

export default ServicesSubmenu;
