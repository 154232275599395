import React, { useState } from "react";
import "./devops.css";
import DevOpsButtons from "../../components/hooks/DevOpsInfo/DevOpsButtonOne";
import DevOpsMoreInfo from "../../components/hooks/DevOpsMoreInfo/DevOpsMoreInfo";
import { ReactComponent as CareersSpace } from "../../assets/svg/careers-space.svg";
import DevOpsResearch from "../../assets/svg/DevOps_Research.svg";
import DevOpsValidating from "../../assets/svg/DevOps_Validating.svg";
import DevOpsDesign from "../../assets/svg/DevOps_Design.svg";
import DevOpsMaintenance from "../../assets/svg/DevOps_Maintenance.svg";
import DevOpsTesting from "../../assets/svg/DevOps_Testing.svg";
import DevOpsDevelopment from "../../assets/svg/DevOps_Development.svg";
import DevOpsDeliver from "../../assets/images/DevOps_Deliver.jpg";
import DevOpsDeliverMobile from "../../assets/images/DevOps_Deliver.jpg";
import DevOpsBackNumber from "../../assets/svg/DevOpsBackNumber.svg";
import LazyMedia from "../../lazyLoad/lazyLoad";

function ContentDevOps() {
  return (
    <>
      <div className="devOpsContainer">
        {/* First Part Container */}
        <div className="devOps_FirstPart">
          <div className="devOps_FirstPartCon">
            <div className="devOps_FirstPartConH1">
              <h1>DevOps Services</h1>
            </div>
            <h3>Your Path to Streamlined Operations</h3>

            <p>
              In today's fast-paced digital landscape, DevOps is the driving
              force behind achieving operational excellence and maintaining a
              competitive edge. At Phoenix Consulting, we understand the
              importance of this agile approach to software development and IT
              operations.
            </p>
          </div>
        </div>
        {/* Second Part Container */}
        <div className="devOps_SecondPart">
          <div className="devOps_secondPart_container">
            <div className="devOps_P2_Head">Our Must-Have DevOps Services</div>
            <div className="devOps_P2_Content">
              <DevOpsButtons />
            </div>
          </div>
        </div>
        <div className="CareersSpace">
          <CareersSpace />
        </div>
        {/* Third Part Container */}
        <div className="devOps_ThirdPart">
          <div className="devOps_thirdPart_container">
            <div className="devOps_P3_first">
              <h1>How Phoenix helps</h1>
              <p>
                Innovating your business with Phoenix Consulting comes with
                several compelling reasons to make us your trusted partner:
              </p>
            </div>
            <div className="devOps_P3_second">
              <DevOpsMoreInfo />
            </div>
          </div>
        </div>
        <div className="CareersSpace">
          <CareersSpace />
        </div>
        {/* Part Four Container */}
        <div className="devOps_PartFour">
          <div className="devOps_P4_first">
            <h1>Our Unique Approach and Process</h1>
            <p>
              Our unique approach and process are designed to drive your
              success, offering a competitive edge while addressing IT
              resourcing challenges and reducing the cost of business
              operations. When you partner with Phoenix Consulting, here's the
              journey you can expect:
            </p>
          </div>
          <div className="devOps_P4_second">
            <div className="devOps_P4_second_Main">
              {/* MainPart 1  */}
              <div className="devOps_P4_second_MainPart">
                <LazyMedia
                  type="image"
                  src={DevOpsResearch}
                  alt="DevOpsResearch"
                />
                <p>Research and Discovery</p>
              </div>
              {/* MainPart 2  */}
              <div className="devOps_P4_second_MainPart">
                <LazyMedia
                  type="image"
                  src={DevOpsValidating}
                  alt="DevOpsValidating"
                />
                <p>Validating and Shaping Ideas</p>
              </div>
              {/* MainPart 3  */}
              <div className="devOps_P4_second_MainPart">
                <LazyMedia type="image" src={DevOpsDesign} alt="DevOpsDesign" />
                <p>Design and Prototyping</p>
              </div>
            </div>
            <div className="devOps_P4_second_Main_Back">
              {/* MainPart 4  */}
              <div className="devOps_P4_second_MainPart">
                <LazyMedia
                  type="image"
                  src={DevOpsMaintenance}
                  alt="DevOpsMaintenance"
                />
                <p>Maintenance and Support</p>
              </div>
              {/* MainPart 5  */}
              <div className="devOps_P4_second_MainPart">
                <LazyMedia
                  type="image"
                  src={DevOpsTesting}
                  alt="DevOpsTesting"
                />
                <p>Testing and Quality Assurance</p>
              </div>
              {/* MainPart 6  */}
              <div className="devOps_P4_second_MainPart">
                <LazyMedia
                  type="image"
                  src={DevOpsDevelopment}
                  alt="DevOpsDevelopment"
                />
                <p>Development</p>
              </div>
              <LazyMedia
                type="image"
                src={DevOpsBackNumber}
                alt="DevOpsBackNumber"
                className="DevOpsBackNumber"
              />
            </div>
          </div>
        </div>
        <div className="CareersSpace">
          <CareersSpace />
        </div>
        {/* Part Five Container */}
        <div className="devOps_PartFive">
          <div className="devOps_P5_first">
            <h1>Deliver business value, fast.</h1>
            <LazyMedia
              type="image"
              src={DevOpsDeliverMobile}
              alt="DevOpsDeliverMobile"
              className="DevOpsDeliverMobile"
            />

            <p>
              At Phoenix Consulting, we empower your business with the DevOps
              advantage, enhancing collaboration between your development and IT
              operations teams while automating and streamlining your processes.
              With our DevOps services, you can achieve rapid development,
              reliable deployments, and a competitive edge in the digital age.
              <br />
              Contact us today to learn how we can transform your operations for
              the better.
            </p>
          </div>
          <div className="devOps_P5_second">
            <LazyMedia
              type="image"
              src={DevOpsDeliver}
              alt="DevOpsDeliver"
              className="DevOpsDeliver"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ContentDevOps;
