import React, { useState } from "react";
import styles from "./SlideTender.module.css";
import { ReactComponent as SlideActive } from "../../../assets/svg/SlideActive.svg";
import { ReactComponent as SlideNonActive } from "../../../assets/svg/SlideNonActive.svg";
import { ReactComponent as Rbutton } from "../../../assets/svg/Rbutton.svg";
import { ReactComponent as Lbutton } from "../../../assets/svg/Lbutton.svg";
//mport { ReactComponent as LbuttonH } from "./../../assets/svg/leftarrowhover.svg";
//import { ReactComponent as RbuttonH } from "./../../assets/svg/rightarrowhover.svg";

const Slide = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "Slide 1",
      boxInfo: [
        {
          heading: "Transparency",
          description:
            "Gain complete visibility into your procurement processes",
        },
        {
          heading: "Zero Paperwork",
          description:
            "Bid farewell to mountains of paperwork. Our digital solutions are designed to minimize your environmental footprint and maximize efficiency.",
        },
      ],
    },
    {
      title: "Slide 2",
      boxInfo: [
        {
          heading: "Spend Analysis",
          description:
            "Make informed decisions based on deep insights into your organization's spending patterns.",
        },
        {
          heading: "Supply Chain Management",
          description:
            "Our platform simplifies the complexities of supply chain management, optimizing your operations.",
        },
      ],
    },
    {
      title: "Slide 3",
      boxInfo: [
        {
          heading: "Time-Saving",
          description:
            "Reduce the time spent on manual procurement processes and allocate it where it matters most.",
        },
        {
          heading: "Audit Compliance & Risk Analysis",
          description:
            "Stay compliant and assess risks effectively through our comprehensive auditing and analysis features.",
        },
      ],
    },
    {
      title: "Slide 4",
      boxInfo: [
        {
          heading: "Auto Alerts & Notifications",
          description:
            "Receive timely alerts and notifications through various channels such as email, SMS, and social networks.",
        },
      ],
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNextClick = () => {
    nextSlide(); // Call the nextSlide function
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className={styles.Slides_Container}>
      <div className={styles.slide}>
        {slides[currentSlide].boxInfo.map((box, index) => (
          <div className={styles.boxinfo} key={index}>
            <h1>{box.heading}</h1>
            <p>{box.description}</p>
          </div>
        ))}
      </div>

      {/* buttom for slides */}

      <div className={styles.controls}>
        <div className={styles.arrowslide}>
          <Lbutton onClick={prevSlide} />
          <div className={styles.activation}>
            {slides.map((_, index) => (
              <div
                key={index}
                className={styles.controlButton}
                onClick={() => goToSlide(index)}
              >
                {index === currentSlide ? <SlideActive /> : <SlideNonActive />}
              </div>
            ))}
          </div>
          <Rbutton onClick={handleNextClick} />
        </div>
      </div>
    </div>
  );
};
export default Slide;
