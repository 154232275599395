import React, { useState } from "react";
import "./solutionsSubmenu.css";
import { ReactComponent as ArrowMenu } from "../../../assets/svg/arrow-right-submenu.svg";
import RealState from "../../../assets/svg/real-state.svg";
import TenderMenu from "../../../assets/images/tender-menu.jpg";
import { MainUrl } from "../../../config";
import LazyMedia from "../../../lazyLoad/lazyLoad";

function SolutionsSubmenu() {
  const [currentImage, setCurrentImage] = useState(TenderMenu);
  const [currentTextTitle, setCurrentTextTitle] = useState("Tender"); // Default title
  const [currentTextContent, setCurrentTextContent] = useState(
    "Phoenix Tender Solutions are tailored to streamline your procurement processes with cutting-edge efficiency and transparency"
  ); // Default content
  const [currentClassName, setCurrentClassName] = useState("");

  // Update image source, class name, and text content
  const handleMouseEnter = (
    newImage,
    newClassName,
    newTextTitle,
    newTextContent
  ) => {
    setCurrentImage(newImage);
    setCurrentClassName(newClassName);
    setCurrentTextTitle(newTextTitle);
    setCurrentTextContent(newTextContent);
  };

  const handleMouseLeave = () => {
    setCurrentImage(TenderMenu); // Default image
    setCurrentTextTitle("Tender"); // Default title
    setCurrentTextContent(
      "Phoenix Tender Solutions are tailored to streamline your procurement processes with cutting-edge efficiency and transparency"
    ); // Default content
    setCurrentClassName(""); // Default class name
  };

  return (
    <div className="submenu_solutionsMain">
      <div className="submenu_contain_solutions">
        <div className="submenu_solutions_contain">
          <a
            href={`${MainUrl}Tender`}
            className="submenu_solutions_link"
            onMouseEnter={() =>
              handleMouseEnter(
                TenderMenu,
                "SubMenu_Aws_photos",
                "Tender", // New title
                "Phoenix Tender Solutions are tailored to streamline your procurement processes with cutting-edge efficiency and transparency" // New content
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            Tender
            <ArrowMenu />
          </a>
          <a
            href={`${MainUrl}RealEstate`}
            className="submenu_solutions_link"
            onMouseEnter={() =>
              handleMouseEnter(
                RealState,
                "SubMenu_Aws_photo",
                "Real Estate", // New title
                "At Phoenix, we believe in nurturing talent and promoting from within Joining us means access to a wealth of growth opportunities and career advancement." // New content
              )
            }
            onMouseLeave={handleMouseLeave}
          >
            Real Estate
            <ArrowMenu />
          </a>
        </div>
        <div className="summenu_solutions_secondPart">
          <div className="submenu_info_solutions">
            <p className="submenu_solutions_first">{currentTextTitle}</p>
            <p className="submenu_solutions_third">{currentTextContent}</p>
          </div>
          <div>
            <LazyMedia
              type="image"
              src={currentImage}
              alt=""
              className={currentClassName}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionsSubmenu;
