import React, { useState } from "react";
import styles from "./slide.module.css";
//import Slider from 'react-slick'; // Import the Slider component
import { ReactComponent as SlideActive } from "./../../../assets/svg/SlideActive.svg";
import { ReactComponent as SlideNonActive } from "./../../../assets/svg/SlideNonActive.svg";
import { ReactComponent as Rbutton } from "./../../../assets/svg/Rbutton.svg";
import { ReactComponent as Lbutton } from "./../../../assets/svg/Lbutton.svg";
//mport { ReactComponent as LbuttonH } from "./../../assets/svg/leftarrowhover.svg";
//import { ReactComponent as RbuttonH } from "./../../assets/svg/rightarrowhover.svg";

const SlideRealState = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const slides = [
    {
      title: "Slide 1",
      boxInfo: [
        {
          heading: "Unit Release",
          description: "Effortlessly manage unit releases.",
        },

        {
          heading: "Reservation Management",
          description: "Effectively oversee property reservations with ease.",
        },
      ],
    },

    {
      title: "Slide 2",
      boxInfo: [
        {
          heading: "Robust Reporting",
          description:
            "Generate detailed reports including partners, units, occupancy rates, representative sales, ownership and rental contracts analysis.",
        },
        {
          heading: "Building Structures",
          description:
            "Manage the complete hierarchy, from countries to cities, regions, buildings, and individual building units.",
        },
      ],
    },
    {
      title: "Slide 3",
      boxInfo: [
        {
          heading: "Contract Control",
          description: "Stay in charge of ownership and rental contracts.",
        },

        {
          heading: "Payment Management",
          description:
            "Keep track of ownership contracts, rental contracts, and unit refund payments.",
        },
      ],
    },
  ];

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const handleNextClick = () => {
    nextSlide(); // Call the nextSlide function
  };

  const goToSlide = (index) => {
    setCurrentSlide(index);
  };

  return (
    <div className={styles.slide_Container}>
      <div className={styles.slide}>
        {/* Use the Slider component and pass the settings */}

        {slides[currentSlide].boxInfo.map((box, index) => (
          <div className={styles.boxinfo} key={index}>
            <h1>{box.heading}</h1>
            <p>{box.description}</p>
          </div>
        ))}
      </div>

      {/* buttom for slides */}

      <div className={styles.controls}>
        <Lbutton onClick={prevSlide} />

        <div className={styles.arrowslide}>
          <div className={styles.activation}>
            {slides.map((_, index) => (
              <div
                key={index}
                className={styles.controlButton}
                onClick={() => goToSlide(index)}
              >
                {index === currentSlide ? <SlideActive /> : <SlideNonActive />}
              </div>
            ))}
          </div>
        </div>
        <Rbutton onClick={handleNextClick} />
      </div>
    </div>
  );
};
export default SlideRealState;
